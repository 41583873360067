<template>
  <el-card style="width: 100%;min-width: 1200px">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
      <div style="height: 47px;">
        <el-form-item label="创建时间">
          <el-date-picker
                  v-model="queryForm.createTime"
                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%;height: 32px"
          >
          </el-date-picker>
        </el-form-item>
        <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
        <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
        <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
      </div>
      <div  style="height: 47px">
        <el-form-item label="账单名称" class="search-form-item">
          <el-input v-model="queryForm.packName" placeholder="账单名称" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="账单状态">
          <el-select v-model="queryForm.packStatus" clearable placeholder="账单状态" class="search-input">
            <el-option label="未完成" value="0"></el-option>
            <el-option label="已完成" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input v-model="queryForm.createUserName" placeholder="创建人" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="所属商户"  v-show="mchVisible">
          <el-select size="small" class="search-input" v-model="queryForm.mchIdList" multiple filterable remote reserve-keyword placeholder="请输入商户名称或编号" :remote-method="queryMchSelect">
            <el-option v-for="item in mchIdSelctList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div  style="height: 47px" v-show="mchVisible">
        <el-form-item label="所属渠道"  v-show="mchVisible">
          <el-select size="small" class="search-input" v-model="queryForm.orgIdList" multiple filterable remote reserve-keyword placeholder="请输入渠道名称或编号" :remote-method="queryOrgSelect">
            <el-option v-for="item in orgIdSelctList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="search-button">
      <div style="margin: 0 auto;height: 48px;width: 200px">
        <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
        >查询
        </el-button
        >
        <el-button size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
    <div class="op-botton" >
      <el-button type="primary"  v-show="!mchVisible" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick()">新增</el-button>
      <el-button size="small"  v-show="!mchVisible" style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" @click="createImpport">批量导入</el-button>
      <el-button size="small"   v-show="!mchVisible" style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"  @click="createBatchDelete">批量删除</el-button>
    </div>
    <div style="width: 100%;margin-top: 0px;">
      <el-table
              :data="records"
              border
              :stripe="true">
        <el-table-column  type="selection" fixed="left" width="55">
        </el-table-column>
        <el-table-column
                prop="packNo"
                label="账单批次号"
                width="120"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="packName"
                label="账单名称"
                width="250"
                >
          <template slot-scope="scope">
            <el-input
                    v-model.trim="scope.row.packName"
                    @blur="handchangePackName(scope.row.packNo,scope.row.packName)"
                    v-show="!userTypeOrg"
            ></el-input>
            <span v-show="userTypeOrg">{{scope.row.packName}}</span>
          </template>
        </el-table-column>
        <el-table-column
                prop="billNumTotal"
                label="账单条数(已缴/总条数)"
                width="190"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="billAmountTotal"
                label="账单金额(已缴/总金额)元"
                width="190"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="billCanPayStr"
                label="账单是否有效"
                show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.billCanPayStr == '是'">{{scope.row.billCanPayStr}}</span>
            <span v-else style="color: red">{{scope.row.billCanPayStr}}</span>
          </template>
        </el-table-column>
        <el-table-column
                prop="createUserName"
                label="创建人"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="createTime"
                label="创建时间"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="startStatus"
                label="启用"
                show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.startStatus" active-value="on" inactive-value="off" active-color="#4A7DFA" inactive-color="#E4E7ED" @change="changeSwitch($event,scope.row,scope.$index, 'startStatus')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column
                prop="partStatus"
                label="选缴"
                show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch v-model="scope.row.partStatus" active-value="on" inactive-value="off" active-color="#4A7DFA" inactive-color="#E4E7ED" @change="changeSwitch($event,scope.row,scope.$index, 'partStatus')"></el-switch>
          </template>
        </el-table-column>

        <el-table-column
                prop="address"
                label="操作"
                align="right"
                width="360"
                fixed="right">
          <template slot-scope="scope">
            <el-button
                    plain
                    size="small"
                    @click="detailClick(scope.row)"
                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
            >查看明细
            </el-button
            >
            <el-button
                    plain
                    size="small"
                    @click="setStartEndTime(scope.row)"
                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
                    v-show="!userTypeOrg"
            >{{(scope.row.startTime == null && scope.row.endTime == null) ? '设置有效时间':'查看有效时间'}}
            </el-button
            >
            <el-button
                    plain
                    size="small"
                    @click="cuijiaoClick(scope.row)"
                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
                    v-show="!userTypeOrg"
            >催缴
            </el-button
            >
            <el-button
                    type="danger"
                    plain
                    size="small"
                    @click="deleteClick(scope.row)"
                    style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"
                    v-show="!userTypeOrg"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block" style="margin-top: 15px;text-align: right">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.currentPage"
              :page-sizes="queryInfo.pageSizes"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="queryInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 删除内容-->
    <el-dialog class="del" title="删除" :visible.sync="dialogDeleteVisible" width="30%" center>
      <div class="tip">
        <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt="" />
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="dialogDeleteVisible = false">取 消</el-button>
      <el-button type="primary" @click="deleteSure">确 定</el-button>
    </span>
    </el-dialog>
    <!-- 催缴内容-->
    <el-dialog class="del" title="催缴" :visible.sync="dialogCuijiaoVisible" width="30%" center>
      <div class="tip">
        <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt="" />
        <span class="txt">您确认要催缴吗</span>
      </div>
      <span slot="footer" class="dialog-footer">
      <el-button @click="dialogCuijiaoVisible = false">取 消</el-button>
      <el-button type="primary" @click="cuijiaoSure">确 定</el-button>
    </span>
    </el-dialog>
    <el-dialog title="导入账单" :close-on-click-modal="false" @close="closeHandle" :visible.sync="importBillVisible">
      <el-upload drag action="/charge/admin/file/upload/excel" :before-upload="beforeUploadHandle" :on-success="successHandle" :on-remove="handleRemove" multiple :file-list="fileList" style="text-align: center;">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">支持多个文件上传，仅支持拓展名：xls，xlsx，大小不超过24mb</div>
      </el-upload>
      <p class="txt">点击此处<span class="downTxt" @click="downImportBillTemp" style="color:#4A7DFA; cursor: pointer;">下载模板</span></p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importBillVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitImportBill">确 定</el-button>
      </div>
    </el-dialog>
    <!--  创建账单弹框-->
    <el-dialog title="新增" :visible.sync="dialogFormVisible" class="m_body">
      <div class="add-button-div">
        <el-button class="tsBtn" style="background: #4A7DFA" type="primary" size="small" @click="addinput">添加项目</el-button>
        <el-button type="danger" plain size="small" @click="deleteinput">删除项目</el-button>
      </div>
      <el-form :model="createBillForm">
        <el-form-item label="账单名称" :label-width="formLabelWidth" required>
          <el-input v-model.trim="createBillForm.packName" autocomplete="off"></el-input>
        </el-form-item>
        <div v-for="(ddd,index) in createBillForm.itemList" :key="index" style="background: #F7FAFE ;width: 100%;border-radius: 4px;border: 2px dashed #CCE5FF ; padding-right: 5px;box-sizing: border-box; margin-top: 10px;">
          <el-form-item label="缴费项目" :label-width="formLabelWidth" required>
            <el-select class="wSlelct" v-model="ddd.itemId" placeholder="请选择缴费项目" style="width:100%">
              <el-option v-for="item in optionList" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="缴费金额" :label-width="formLabelWidth" required>
            <el-input v-model.trim="ddd.itemAmountStr" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model.trim="ddd.remark" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="部门" :label-width="formLabelWidth" required>
          <el-tree :data="elTreeData" show-checkbox :default-checked-keys="createBillForm.deptIdList" :check-strictly="true" node-key="id" :props="defaultProps" ref="tree" @check-change="handleCheckChange">
          </el-tree>
        </el-form-item>
        <el-form-item class="status" label="部分缴费" :label-width="formLabelWidth" required>
          <el-radio v-model="createBillForm.partStatus" label="on">启用</el-radio>
          <el-radio v-model="createBillForm.partStatus" label="off">禁用</el-radio>
        </el-form-item>
        <el-form-item class="status" label="账号状态" :label-width="formLabelWidth" required>
          <el-radio v-model="createBillForm.startStatus" label="on">激活</el-radio>
          <el-radio v-model="createBillForm.startStatus" label="off">禁用</el-radio>
        </el-form-item>
        <el-form-item label="账单有效期" :label-width="formLabelWidth">
          <el-date-picker v-model="createBillForm.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
          至
          <el-date-picker v-model="createBillForm.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
          <!--<el-date-picker
                    v-model="createBillForm.startTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCreateForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="设置有效时间" :visible.sync="dialogEditFormVisible">
      <el-form :model="editBillForm">
        <el-form-item label="账单有效期" :label-width="formLabelWidth">
          <el-date-picker v-model="editBillForm.startTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
          至
          <el-date-picker v-model="editBillForm.endTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditForm">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    export default {
        //components:[myDrawer],
        name: "org", // 渠道管理
        data() {
            return {
                limit: "2",
                page: "1",
                startTime: "",
                endTime: "",
                records: [],
                choose7: false,
                choose30: false,
                choose180: false,
                createBillForm: {
                    packName: '',
                    itemList: [],
                    deptIdList: [],
                    partStatus: 'on',
                    startStatus: 'on'
                },
                editBillForm: {
                    startTime: "",
                    endTime: ""
                },
                queryForm: {
                    mchIdList: [],
                    orgIdList: []
                },
                dialogFormVisible: false,
                importBillVisible: false,
                mchVisible: false,
                userTypeOrg:false,
                fileList: [],
                formLabelWidth: "120px",
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                // 创建账单弹框开始
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, //
                dialogCuijiaoVisible: false, //
                dialogEditFormVisible: false,
                cuijiaoItem: {},
                formLabelWidth: "120px",
                optionList: [],
                elTreeData: [],
                elTreeData2: [],
                selectedRows: [],
                orgIdSelctList: [],
                mchIdSelctList: [],
                deleteItem: {
                    packNoList: []
                },
                defaultProps: {
                    children: 'children',
                    label: 'label'
                }
                // 创建账单弹框结束
            };
        },
        created() {
            this.loadData();
            let userType = localStorage.getItem('curUserType');
            if (userType != null) {
                if (userType == 'MCH') {
                    this.mchVisible = false;
                } else {
                    this.mchVisible = true;
                }
                if(userType == 'ORG'){
                    this.userTypeOrg = true;
                }
            }
        },
        computed: {},
        methods: {
            rowClass({
                         row,
                         rowIndex
                     }) {
                return "background:rgba(242, 242, 242, 1);font-size: 13px;";
            },
            loadData(page) {
                this.$request
                    .mchbillpackList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            createImpport() {
                this.fileList = [];
                this.importBillVisible = true;
            },
            handchangePackName(packNo, packName){
                let updatePackNameData = {packNo:packNo, packName: packName};
                this.$request
                    .mchbillpackUpdatePackName(updatePackNameData)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.loadData();
                        }
                    });
            },
            // 弹窗关闭时
            closeHandle() {
                this.fileList = []
                this.$emit('refreshDataList')
            },
            // 文件上传弹框的数据开始
            beforeUploadHandle(file) {
                if (!file.name.endsWith("xls") && !file.name.endsWith("xlsx")) {
                    this.$message.error('只支持xls、xlsx格式的文件！')
                    return false
                }
                this.num++
            },
            downImportBillTemp() {
                this.$request.mchbillpackDownTemplate({})
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            submitImportBill() {
                if (this.fileList.length == 0) {
                    this.$message.error('请上传文件');
                    return false;
                }
                var req = {};
                req.fileIdList = this.fileList;
                this.$request.mchbillpackImportBill(req)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                            this.loadData();
                            this.importBillVisible = false;
                        }
                    });
            },
            // 上传成功
            successHandle(response, file, fileList) {
                if (response.code === 0) {
                    this.fileList.push(response.data.fileId);
                }
            },
            handleRemove(file, fileList) {
                let fileId = file.response.data.fileId;
                var index = this.fileList.indexOf(fileId);
                if (index > -1) {
                    this.fileList.splice(index, 1);
                }
            },
            changeFun(val) {
                this.selectedRows = val;
            },
            submitCreateForm() {
                let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
                this.createBillForm.deptIdList = res;
                this.$request
                    .mchbillpackSave(this.createBillForm)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.loadData();
                            this.$util.clearObj(this.createBillForm);
                        }
                    });
            },
            submitEditForm() {
                this.$request
                    .mchbillpackSetStartEndTime(this.editBillForm)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.loadData();
                            this.dialogEditFormVisible = false;
                        }
                    });
            },
            addinput() {
                this.createBillForm.itemList.push({
                    'itemId': '',
                    'itemAmount': '',
                    'remark': ''
                })
            },
            deleteinput() {
                if (this.createBillForm.itemList.length == 1) {
                    this.$message({
                        message: "请最少保留一组缴费项目",
                        type: "error"
                    });
                    return;
                }
                this.createBillForm.itemList.pop();
            },
            handleCheckChange(a, b) {
                this.findchildren(a, b);
                //this.findParent(a,b);
            },
            findchildren(current, b) {
                this.$refs.tree.setChecked(current.id, b, b);
                let d = current.children;
                if (d && d.length > 0) {
                    d.forEach(a => {
                        this.findchildren(a, b);
                    })
                }
            },
            clickImport(type) {
                this.fileList = [];
                this.importUserVisible = true;
                this.dialogImpoertType = type;
            },
            reduceQueryFormTime(days) {
                if(days == 7){
                    this.choose7 = true;
                    this.choose30 = false;
                    this.choose180 = false;
                }else if (days == 30){
                    this.choose7 = false;
                    this.choose30 = true;
                    this.choose180 = false;
                }else if (days == 180){
                    this.choose7 = false;
                    this.choose30 = false;
                    this.choose180 = true;
                }
                var date = new Date();
                let formatDate1 = this.$util.formatDate(date);
                date.setDate(date.getDate() - days);
                let formatDate2 = this.$util.formatDate(date);
                this.$set(this.queryForm, "createTime", [formatDate2, formatDate1]);
            },
            changeSwitch(event, data, index, type) {
                if ('startStatus' == type) {
                    var reqData = {};
                    reqData.packNo = data.packNo;
                    reqData.startStatus = data.startStatus;
                    this.$request.mchbillpackChangeStartStatus(reqData).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.loadData();
                        }
                    });
                } else if ('partStatus' == type) {
                    var reqData = {};
                    reqData.packNo = data.packNo;
                    reqData.partStatus = data.partStatus;
                    this.$request.mchbillpackChangePartStatus(reqData).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                        }
                    });
                }

            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            createClick() {
                this.createBillForm.itemList = [];
                this.createBillForm.partStatus = "on"
                this.createBillForm.startStatus = "on"
                this.createBillForm.deptIdList = [];
                this.addinput();
                if (this.optionList.length == 0) {
                    this.$request.dictBillItemList({}).then((res) => {
                        if (res.code == 0) this.optionList = res.data;
                    })
                }
                if (this.elTreeData2.length == 0) {
                    this.$request.deptTree({}).then((res) => {
                        if (res.code == 0) {
                            this.elTreeData2 = res.data;
                            var obj = this.$util.cloneObj(this.elTreeData2);
                            this.elTreeData = obj;
                        }
                    });
                } else {
                    var obj = this.$util.cloneObj(this.elTreeData2);
                    this.elTreeData = obj;
                }
                this.dialogFormVisible = true;
            },
            editClick(item) {
                this.loginNameVisible = false;
                this.orgNoDisabled = true;
                this.orgTypeDisabled = true;
                this.$request.bashOrgInfo(item).then((res) => {
                    if (res.code == 0) {
                        this.curDataForm = res.data;
                        if (this.curDataForm.orgType == 1) {
                            this.parentIdVisible = false;
                        } else {
                            this.parentIdVisible = true;
                        }
                    }
                });
                this.dialogFormVisible = true;
            },
            createBatchDelete() {
                if (this.selectedRows.length == 0) {
                    this.$message({
                        message: "请选择数据",
                        type: "error",
                    });
                    return;
                }
                var arr = [];
                this.selectedRows.forEach(a => {
                    arr.push(a.packNo);
                })
                this.deleteItem.packNoList = arr;
                this.dialogDeleteVisible = true;
            },
            deleteClick(item) {
                var arr = [];
                arr.push(item.packNo);
                this.deleteItem.packNoList = arr;
                this.dialogDeleteVisible = true;
            },
            setStartEndTime(item) {
                this.editBillForm.packNo = item.packNo;
                this.editBillForm.startTime = item.startTime;
                this.editBillForm.endTime = item.endTime;
                this.dialogEditFormVisible = true;
            },
            detailClick(item) {
                window.open("/#/base/bill?packNo=" + item.packNo);
            },
            cuijiaoClick(item) {
                this.cuijiaoItem = item;
                this.dialogCuijiaoVisible = true;
            },
            cuijiaoSure() {
                this.$request.mchbillpackCuijiao(this.cuijiaoItem).then((res) => {
                    if (res.code == 0) {
                        this.$alert(res.data, {
                            confirmButtonText: '确定',
                            callback: action => {}
                        });
                        this.dialogCuijiaoVisible = false;
                    }
                });
            },
            deleteSure() {
                this.$request.mchbillpackDelete(this.deleteItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            queryMchSelect(queryName) {
                if (queryName !== '') {
                    this.$request.deptGetOrgOrMchList({
                        workType: "2",
                        queryName: queryName
                    }).then((res) => {
                        if (res.code == 0) {
                            this.mchIdSelctList = res.data;
                        }
                    });
                }
            },
            queryOrgSelect(queryName) {
                this.$request.deptGetOrgOrMchList({
                    workType: "1",
                    queryName: queryName
                }).then((res) => {
                    if (res.code == 0) {
                        this.orgIdSelctList = res.data;
                    }
                });
            },
            submit() {
                if (!this.curDataForm.orgId) {
                    let curDataForm = {
                        ...this.curDataForm
                    };
                    this.$request.bashOrgSave(curDataForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.$util.clearObj(this.curDataForm);
                            this.loadData();
                            this.loadOrgItem();
                        }
                    });
                } else {
                    this.$request
                        .bashOrgUpdate({
                            ...this.curDataForm,
                        })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message({
                                    message: "成功",
                                    type: "success",
                                });
                                this.dialogFormVisible = false;
                                this.loadData();
                                this.loadOrgItem();
                                this.$util.clearObj(this.curDataForm);
                            }
                        });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
  .choose-days {
    width: 67px;
    height: 32px;
    margin-top: 3px;
    margin-left: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  .choose-days-selected {
    background: #4065E0;
    color: #FFFFFF;
  }
  .search-form {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 14px;
  }
  .search-input  /deep/.el-input__inner {
    height: 32px!important;
    width: 200px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
  }
  .vue-treeselect {
    width: 200px;
    line-height: 32px;
  }
  .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
  }
  .search-button {
    height: 52px;
    text-align: center;
    line-height: 48px;
    border-bottom: 1px solid #DCDFE6;
  }

  .op-botton{
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: right;
  }
  /deep/  .el-table th, .el-table tr {
    background-color: #F5F5F5;
    color: #909399;
  }
  /deep/ .el-table td {
    padding: 0px 0;
    height: 50px;
  }
  /deep/ .el-table th {
    padding: 0px 0;
    height: 50px;
  }
  .del .tip .tip-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .add-button-div {
    margin-bottom: 10px;
    text-align: right;
  }
</style>
