<template>
    <el-card style="width: 100%;min-width: 1200px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
            <div style="height: 47px;">
                <el-form-item label="创建时间">
                    <el-date-picker
                            v-model="queryForm.createTime"
                            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%;height: 32px"
                    >
                    </el-date-picker>
                </el-form-item>
                <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
                <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
                <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
            </div>
            <div  style="height: 47px">
                <el-form-item label="支付单号" class="search-form-item">
                    <el-input v-model="queryForm.payNo" placeholder="支付单号" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="平台单号">
                    <el-input v-model="queryForm.platNo" placeholder="平台单号" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="缴费类型">
                    <el-select v-model="queryForm.billType" clearable placeholder="缴费类型" class="search-input">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="账单缴费" value="1"></el-option>
                        <el-option label="自助缴费" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="queryForm.payType" clearable placeholder="支付方式" class="search-input">
                        <el-option label="微信" value="0"></el-option>
                        <el-option label="支付宝" value="1"></el-option>
                        <el-option label="人工销账" value="2"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div  style="height: 47px" v-show="mchVisible">
                <el-form-item label="商户名称" class="search-input">
                    <el-input v-model="queryForm.mchName" placeholder="商户名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="商户编号" class="search-input">
                    <el-input v-model="queryForm.mchNo" placeholder="商户编号" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="渠道名称" class="search-input">
                    <el-input v-model="queryForm.orgName" placeholder="渠道名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="渠道编号" class="search-input">
                    <el-input v-model="queryForm.orgNo" placeholder="渠道编号" class="search-input"></el-input>
                </el-form-item>
            </div>
            <div  style="height: 47px">
                <el-form-item label="支付状态" >
                    <el-select v-model="queryForm.payStatus" clearable placeholder="支付状态" class="search-input">
                        <el-option label="已支付" value="1"></el-option>
                        <el-option label="已销账" value="3"></el-option>
                        <el-option label="转入退款" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="mchConf.userNameRename" class="search-input">
                    <el-input v-model="queryForm.mchUserName" :placeholder="mchConf.userNameRename" class="search-input"></el-input>
                </el-form-item>
                <el-form-item :label="mchConf.userNoRename" class="search-input">
                    <el-input v-model="queryForm.mchUserNo" :placeholder="mchConf.userNoRename" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="所属部门" class="search-input" v-show="deptVisible">
                    <treeselect class="title-input" size="small"
                                :appendToBody="true"
                                name="demo"
                                :multiple="false"
                                :searchable="true"
                                :options="addUserDeptTree"
                                :limit="3"
                                v-model="queryForm.deptId"
                                placeholder="请选择所属部门"
                    />
                </el-form-item>
            </div>
            <div  style="height: 47px" v-show="mchPayOrderSpecialOrg">
                <el-form-item label="家长姓名" class="search-input">
                    <el-input v-model="queryForm.specialFieldParentName" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="学生姓名" class="search-input">
                    <el-input v-model="queryForm.specialFieldStuName" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="年级" class="search-input">
                    <el-input v-model="queryForm.specialFieldGrade" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="班级" class="search-input" >
                    <el-input v-model="queryForm.specialFieldClass" class="search-input"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div class="op-botton">
            <el-button size="small" style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" @click="createExport">导出</el-button>
            <el-button size="small" style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"  @click="createBatchRefund"  v-show="!userTypeOrg">批量退款</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                    :data="records"
                    border
                    :stripe="true">
                <el-table-column  type="selection" fixed="left" width="55">
                </el-table-column>
                <el-table-column
                        prop="payNo"
                        label="支付单号"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="specialFieldParentName"
                        show-overflow-tooltip
                        v-if="mchPayOrderSpecialOrg"
                        label="家长姓名">
                </el-table-column>
                <el-table-column
                        prop="specialFieldStuName"
                        show-overflow-tooltip
                        v-if="mchPayOrderSpecialOrg"
                        label="学生姓名">
                </el-table-column>
                <el-table-column
                        prop="specialFieldGrade"
                        show-overflow-tooltip
                        v-if="mchPayOrderSpecialOrg"
                        label="年级">
                </el-table-column>
                <el-table-column
                        prop="specialFieldClass"
                        show-overflow-tooltip
                        v-if="mchPayOrderSpecialOrg"
                        label="班级">
                </el-table-column>
                <el-table-column
                        prop="mchUserName"
                        :label="this.mchConf.userNameRename"
                        v-if="!mchPayOrderSpecialOrg"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="mchUserNo"
                        show-overflow-tooltip
                        v-if="!mchPayOrderSpecialOrg"
                        :label="this.mchConf.userNoRename">
                </el-table-column>
                <el-table-column
                        prop="deptPath"
                        show-overflow-tooltip
                        v-if="!mchPayOrderSpecialOrg"
                        label="所属部门">
                </el-table-column>
                <el-table-column
                        prop="itemNames"
                        show-overflow-tooltip
                        label="缴费项目">
                </el-table-column>
                <el-table-column
                        prop="payAmountStr"
                        show-overflow-tooltip
                        label="缴费金额">
                </el-table-column>
                <el-table-column
                        prop="platNo"
                        show-overflow-tooltip
                        label="平台订单号">
                </el-table-column>
                <el-table-column
                        prop="billTypeStr"
                        show-overflow-tooltip
                        label="缴费类型">
                </el-table-column>
                <el-table-column
                        prop="payStatusStr"
                        show-overflow-tooltip
                        label="支付状态">
                </el-table-column>
                <el-table-column
                        prop="payTime"
                        show-overflow-tooltip
                        label="支付时间">
                </el-table-column>
                <el-table-column
                        prop="payTypeStr"
                        show-overflow-tooltip
                        label="支付方式">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        show-overflow-tooltip
                        label="备注">
                </el-table-column>
                <el-table-column
                        prop="mchNo"
                        show-overflow-tooltip
                        label="商户编号">
                </el-table-column>
                <el-table-column
                        prop="mchName"
                        show-overflow-tooltip
                        label="商户名称">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="操作"
                        align="right"
                        width="230"
                        fixed="right">
                    <template slot-scope="scope">
                    <el-button
                            size="small"
                            type="text"
                            @click="payOrderInfo(scope.row)"
                    >详情
                    </el-button
                    >
                    <el-button
                            size="small"
                            type="text"
                            @click="refundClick(scope.row)"
                            :disabled="scope.row.payStatus != 1 && scope.row.payStatus != 4"
                    >退款
                    </el-button
                    >
                    <el-button
                            size="small"
                            type="text"
                            @click="partRefundClick(scope.row)"
                            :disabled="scope.row.payStatus != 1 && scope.row.payStatus != 4"
                    >部分退款
                    </el-button
                    >
                    <el-button
                            size="small"
                            type="text"
                            @click="genPdf(scope.row)"
                            :disabled="scope.row.payStatus != 1 && scope.row.payStatus != 4"
                    >打印收据
                    </el-button
                    >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
        </div>
        <el-dialog title="部分退款" :visible.sync="dialogFormVisible" >
            <el-form :rules="formRules" ref="curDataForm" :model="curDataForm">
                <el-form-item label="退款金额" :label-width="formLabelWidth" required  prop="refundAmountStr">
                    <el-input
                            v-model="curDataForm.refundAmountStr"
                            autocomplete="off"
                            oninput="value=value.replace(/[^0-9.]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" >
                    <el-input
                            v-model="curDataForm.remarks"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('curDataForm')"> 确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="详情" :visible.sync="dialogFormInfoVisible">
            <el-form>
                <el-form-item label="支付单号" :label-width="formLabelWidth" >
                    <span>{{payOrderInfoData.payNo}}</span>
                </el-form-item>
                <el-form-item label="缴费类型" :label-width="formLabelWidth" >
                    <span>{{payOrderInfoData.billTypeStr}}</span>
                </el-form-item>
                <el-form-item :label="mchConf.userNameRename" :label-width="formLabelWidth"  v-show="payOrderInfoData.billType == 1">
                    <span>{{payOrderInfoData.mchUserName}}</span>
                </el-form-item>
                <el-form-item :label="mchConf.userNoRename" :label-width="formLabelWidth"  v-show="payOrderInfoData.billType == 1">
                    <span>{{payOrderInfoData.mchUserNo}}</span>
                </el-form-item>
                <el-form-item label="所属部门" :label-width="formLabelWidth" v-show="payOrderInfoData.billType == 1">
                    <span>{{payOrderInfoData.deptPath}}</span>
                </el-form-item>
                <el-form-item v-for="(billField, index) in payOrderInfoData.mchFieldDTOS" :label=billField.fieldName :key="billField.fieldId" :label-width="formLabelWidth">
                    <span>{{billField.fieldValue}}</span>
                </el-form-item>
                <el-form-item v-for="(payItem, index) in payOrderInfoData.payItemList" :label=payItem.itemName :key="index" :label-width="formLabelWidth">
                    <span>{{payItem.itemAmountStr}}</span>
                </el-form-item>
                <el-form-item label="平台订单号" :label-width="formLabelWidth" >
                    <span>{{payOrderInfoData.platNo}}</span>
                </el-form-item>
                <el-form-item label="缴费总金额" :label-width="formLabelWidth">
                    <span>{{payOrderInfoData.payAmountStr}}</span>
                </el-form-item>
                <el-form-item label="支付状态" :label-width="formLabelWidth">
                    <span>{{payOrderInfoData.payStatusStr}}</span>
                </el-form-item>
                <el-form-item label="支付时间" :label-width="formLabelWidth">
                    <span>{{payOrderInfoData.payTime}}</span>
                </el-form-item>
                <el-form-item label="支付方式" :label-width="formLabelWidth">
                    <span>{{payOrderInfoData.payTypeStr}}</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogFormInfoVisible = false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 退款-->
        <el-dialog
                class="del"
                title="退款"
                :visible.sync="dialogRefundVisible"
                width="30%"
                center
        >
            <div class="tip">
                <img
                        class="tip-icon"
                        src="../../../assets/images/wra-icon.png"
                        alt=""
                />
                <span class="txt">您确认退款吗</span>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogRefundVisible = false">取 消</el-button>
        <el-button type="primary" @click="refundSure">确 定</el-button>
      </span>
        </el-dialog>
    </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    import Treeselect from '@riophae/vue-treeselect';
    export default {
        components: {
            Treeselect
        },
        name: "mch", // 商户管理
        data() {
            const validateMoney = (rule,value,callback) =>{
                if(!value){
                    callback(new Error('金额不能为空'))
                }else if(value.split('.').length > 2){
                    callback(new Error('输入正确格式的金额')) //防止输入多个小数点
                }else if(value.split('.').length == 2){
                    var arr = value.split('.');
                    if(arr[1].length > 2 || arr[1].length == 0){
                        callback(new Error('金额请保留到小数点后两位')) //防止输入多个小数点
                    }else{
                        callback();
                    }
                }else{
                    callback();
                }
            };
            return {
                limit: "2",
                page: "1",
                choose7: true,
                choose30: false,
                choose180: false,
                mchPayOrderSpecialOrg: false,
                startTime: "",
                endTime: "",
                records: [],
                addUserDeptTree: [],
                selectedRows: [],
                curDataForm:{
                    refundAmountStr:''
                },
                formRules:{
                    refundAmountStr:[
                        {required:true,tigger:'blur',validator:validateMoney }
                    ]
                },
                queryForm: {
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                mchConf: {
                    userNoRename: "用户编号",
                    userNameRename: "用户名称"
                },
                payOrderInfoData: {
                },
                mchVisible: false,
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, // 删除弹框
                userTypeOrg: false,
                formLabelWidth: "120px",
                dialogRefundVisible:false,
                deptVisible:false,
                dialogFormInfoVisible:false,
            };
        },
        created() {
            this.reduceQueryFormTime(7);
            this.loadData();
            this.loadUserType();
            this.loadAddUserDeptTree();
            let userType = localStorage.getItem('curUserType');
            if(userType == 'ORG'){
                this.userTypeOrg = true;
            }
             this.$request
                    .mchPayOrderSpecialOrg({
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            if(res.data == "1"){
                                this.mchPayOrderSpecialOrg = true;
                            }
                        }
                    });
        },
        computed: {},
        methods: {
            loadData(page) {
                this.$request
                    .mchPayOrderList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            loadAddUserDeptTree(){
                this.$request .mchUserDeptTree({ })
                    .then((res) => {
                        this.addUserDeptTree.push(res.data);
                    });
            },
            loadUserType(){
                let userType = localStorage.getItem('curUserType');
                if(userType != null){
                    if(userType == 'MCH'){
                        this.mchVisible = false;
                        this.deptVisible = true;
                        this.$request
                            .baseMchConfConf({})
                            .then((res) => {
                                this.mchConf = res.data;
                            });
                    }else {
                        this.mchVisible = true;
                        this.deptVisible = false;
                    }
                    return;
                }
                this.$request.rbacuserDetailRequest({}).then((res) => {
                    if(res.code == 0){
                        localStorage.setItem('curUserType', res.data.userType);
                        if(userType == 'MCH'){
                            this.mchVisible = false;
                            this.deptVisible = true;
                        }else {
                            this.mchVisible = true;
                            this.deptVisible = false;
                        }
                    }
                });
            },
            changeFun(val){
                this.selectedRows = val;
            },
            partRefundClick(item) {
                this.$util.clearObj(this.curDataForm);
                this.curDataForm.payNo=item.payNo;
                this.dialogFormVisible = true;
            },
            payOrderInfo(item) {
                this.$request
                    .mchPayOrderInfo({
                        payNo:item.payNo
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.payOrderInfoData = res.data;
                            this.dialogFormInfoVisible = true;
                        }
                    });
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            createExport() {
                this.$request
                    .mchPayOrderExport({
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            createBatchRefund() {
                if(this.selectedRows.length == 0){
                    this.$message({
                        message: "请选择数据",
                        type: "error",
                    });
                    return;
                }

                this.$request
                    .mchPayOrderBatchRefund({
                        payOrderlist:this.selectedRows
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                        }
                    });
            },
            reduceQueryFormTime(days){
                if(days == 7){
                    this.choose7 = true;
                    this.choose30 = false;
                    this.choose180 = false;
                }else if (days == 30){
                    this.choose7 = false;
                    this.choose30 = true;
                    this.choose180 = false;
                }else if (days == 180){
                    this.choose7 = false;
                    this.choose30 = false;
                    this.choose180 = true;
                }

                var date = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
                let formatDate1 = this.$util.formatDate(date);
                date.setDate(date.getDate() - (days+1));
                date = new Date(new Date(date.toLocaleDateString()).getTime());
                let formatDate2 = this.$util.formatDate(date);
                this.$set(this.queryForm, "createTime", [formatDate2, formatDate1]);
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            refundClick(item) {
                this.deleteItem = item;
                this.dialogRefundVisible = true;
            },
            genPdf(item){
                this.$request.mchPayOrderGenPdf(item).then((res) => {
                    if (res.code == 0) {
                        window.open(res.data);
                    }
                });
            },
            refundSure() {
                this.$request.mchPayOrderRefundOrder(this.deleteItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogRefundVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            submit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return false;
                    }
                    this.$request.mchPayOrderRefundOrder(this.curDataForm).then((res) => {
                        if (res.code == 0) {
                            this.dialogFormVisible = true;
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.loadData();
                        }
                    });
                });
            },
        },
    };
</script>

<style lang="less" scoped>
.choose-days {
    width: 67px;
    height: 32px;
    margin-top: 3px;
    margin-left: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
       .search-input  /deep/.el-input__inner {
        height: 32px!important;
           width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
       .vue-treeselect {
           width: 200px;
           line-height: 32px;
       }
    .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

.op-botton{
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: right;
}
/deep/  .el-table th, .el-table tr {
    background-color: #F5F5F5;
    color: #909399;
}
/deep/ .el-table td {
    padding: 0px 0;
    height: 50px;
}
/deep/ .el-table th {
    padding: 0px 0;
    height: 50px;
}
.del .tip .tip-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
</style>
