<template>
    <el-card style="width: 100%;min-width: 1200px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
            <div style="height: 47px;">
                <el-form-item label="交易时间">
                    <el-date-picker
                            v-model="queryForm.createTime"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%;height: 32px"
                    >
                    </el-date-picker>
                </el-form-item>
                <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
                <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
                <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
            </div>
            <div  style="height: 47px">
                <el-form-item label="所属地区" class="search-input" >
                    <treeselect class="title-input" size="small"
                                :appendToBody="true"
                                name="demo"
                                :multiple="false"
                                :searchable="true"
                                :options="areaList"
                                :limit="3"
                                v-model="queryForm.areaCode"
                                placeholder="请选择地区"
                    />
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <el-row >
          <el-col :span="24" >
            <el-card class="box-card" >
              <p class="titles">
                <span style="margin-right: 20px;width: 20px">交易金额</span>
                <span style="margin-right: 20px;width: 20px">交易笔数</span>
                <span style="margin-right: 20px;width: 20px">退款金额</span>
                <span style="margin-right: 20px;width: 20px">退款笔数</span>
              </p>
              <p class="numbers">
                <span style="margin-right: 20px;width: 20px">{{payTotal.payAmountStr}}</span>
                <span style="margin-right: 20px;width: 20px">{{payTotal.payCount}}</span>
                <span style="margin-right: 20px;width: 20px">{{payTotal.refundAmountStr}}</span>
                <span style="margin-right: 20px;width: 20px">{{payTotal.refundCount}}</span>
              </p>
            </el-card>
          </el-col>
        </el-row>
        <div class="op-botton">
            <el-button size="small" style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" @click="clickExport">导出</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                          :data="records"
                          border
                          style="font-size: 13px"
                          :header-cell-style="rowClass"
                          :row-style="{height:'45px'}"
                          :cell-style="{padding:'0px'}"
                  >
                    <el-table-column align="center" prop="payDate" width="160" label="日期"></el-table-column>
                    <el-table-column align="center" prop="mchName"  label="商户名称"></el-table-column>
                    <el-table-column align="center" prop="areaName"  label="地区" v-if="showArea"></el-table-column>
                    <el-table-column align="center" prop="mchNo"  label="商户编号"></el-table-column>
                    <el-table-column align="center" prop="payAmountStr"  label="收款金额"></el-table-column>
                    <el-table-column align="center" prop="payCount"  label="收款笔数"></el-table-column>
                    <el-table-column align="center" prop="refundAmountStr"  label="退款金额"></el-table-column>
                    <el-table-column align="center" prop="refundCount"  label="退款笔数"></el-table-column>
                    <el-table-column align="center" prop="sumAllAomuntStr"  label="累计金额"></el-table-column>
                    <el-table-column align="center" prop="sumAllCount"  label="累计笔数"></el-table-column>
                  </el-table>
                  <div class="block" style="margin-top: 15px;text-align: right">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.currentPage"
                            :page-sizes="queryInfo.pageSizes"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="queryInfo.total"
                    >
                    </el-pagination>
                </div>
        </div>
    </el-card>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        name: "statistics",
        data() {
            return {
                limit: "10",
                page: "1",
                choose7: true,
                choose30: false,
                choose180: false,
                showArea: false,
                records: [],
                queryForm: {

                },
                payTotal:{
                    payAmountStr:"",
                    payCount:"",
                    refundAmountStr:"",
                    refundCount:"",
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                    pageCount:3
                },
                areaList:[],
            };
        },
        created() {
            this.loadShowArea();
            this.reduceQueryFormTime(7);
            this.loadAreaList();
            this.loadData();
        },
        computed: {},
        methods: {
            rowClass({ row, rowIndex }) {
                return "background:rgba(242, 242, 242, 1);font-size: 13px;";
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            loadShowArea() {
                this.$request
                    .mchPayOrderSpecialOrg({
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.showArea = res.data == "1";
                        }
                    });
                this.$request.statisticsTotal(this.queryForm)
                    .then((res) => {
                        if (res.code == 0) {
                            this.payTotal = res.data;
                        }
                    });
            },
            reduceQueryFormTime(days) {
                if(days == 7){
                    this.choose7 = true;
                    this.choose30 = false;
                    this.choose180 = false;
                }else if (days == 30){
                    this.choose7 = false;
                    this.choose30 = true;
                    this.choose180 = false;
                }else if (days == 180){
                    this.choose7 = false;
                    this.choose30 = false;
                    this.choose180 = true;
                }

                var date = new Date();
                date.setDate(date.getDate() - 1);
                let formatDate1 = this.$util.formatDate(date, 'yyyy-MM-dd');
                date.setDate(date.getDate() - days);
                let formatDate2 = this.$util.formatDate(date, 'yyyy-MM-dd');
                this.$set(this.queryForm, "createTime", [formatDate2, formatDate1]);
            },
            loadAreaList(){
                this.$request .getAreaList({ })
                    .then((res) => {
                        this.areaList =res.data;
                    });
            },
            clickExport(){
                this.$request
                    .statisticsExport({
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            loadData(page) {
                this.$request
                    .statisticsList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
                this.$request.statisticsTotal(this.queryForm)
                    .then((res) => {
                        if (res.code == 0) {
                            this.payTotal = res.data;
                        }
                    });
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
        },
    };
</script>
<style lang="less" scoped>
.choose-days {
    width: 67px;
    height: 32px;
    margin-top: 3px;
    margin-left: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
       .search-input  /deep/.el-input__inner {
        height: 32px!important;
           width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
       .vue-treeselect {
           width: 200px;
           line-height: 32px;
       }
    .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

.op-botton{
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: right;
}
/deep/  .el-table th, .el-table tr {
    background-color: #F5F5F5;
    color: #909399;
}
/deep/ .el-table td {
    padding: 0px 0;
    height: 50px;
}
/deep/ .el-table th {
    padding: 0px 0;
    height: 50px;
}
.del .tip .tip-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}
.titles,.numbers{
    display: flex;
    span{
      flex: 1;
      text-align: center;
      padding: 10px;
    }
  }
</style>
