<template>
  <div class="header">
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="400px">
      <el-form :model="form" ref="ruleForm" :rules="rules">
        <el-form-item label="原密码" prop="oldPassWord" :label-width="formLabelWidth">
          <el-input type="password" placeholder="请输入原密码" v-model="form.oldPassWord" style="width: 60%;"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassWord" :label-width="formLabelWidth">
          <el-input type="password" placeholder="请输入原密码" v-model="form.newPassWord" style="width: 60%;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="conformPassword" :label-width="formLabelWidth">
          <el-input type="password" placeholder="请输入原密码" v-model="form.conformPassword" style="width: 60%;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <div class="left">
        <span class="el-dropdown-link">
             <img :src="logoUrl" width="50" height="30" v-show="logoUrl!=''">
            <i class="el-icon-menu" v-show="logoUrl==''"></i>
         </span>
      <div>{{logoName}}</div>
    </div>
    <div class="right">
      <i class="el-icon-setting"></i>
      <i class="el-icon-bell"></i>
      <el-dropdown @command="handleCommand">
<!--        <i class="el-icon-setting" style="margin-right: 15px"></i>-->
        <span class="el-dropdown-link">
              <img :src="headUrl"
                   alt="userName">{{userName}}
            <i class="el-icon-arrow-down el-icon--right" style="font-size: 14px;margin-left: 3px;margin-top: 4px"></i>
         </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">修改密码</el-dropdown-item>
          <el-dropdown-item command="1">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import addOrUpdateUser from "@/views/container/base/addOrUpdateUser";
export default {
    data() {
        return {
           formLabelWidth: '120px',
            userName:"",
            logoUrl:"",
            logoName:"后台管理系统",
          dialogFormVisible: false,
            headUrl:"https://charge-public-bucket-prod.oss-cn-shenzhen.aliyuncs.com/admin_default_head_img.png",
          form: {
            oldPassWord: "",
            uuid: "",
            newPassWord: "",
            conformPassword: ""
          },
          rules: {
            oldPassWord: [
              { required: true, message: '请输入原密码', trigger: 'blur' },
              { min: 6, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
            ],
            newPassWord: [
              { required: true, message: '请输入新密码', trigger: 'blur' },
              { min: 6, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
            ],
            conformPassword: [
              { required: true, message: '请确认密码', trigger: 'blur' },
              { min: 6, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' }
            ],
          }
        };
    },
    created(){
        let curUser = localStorage.getItem('curUserInfo123');
        curUser = JSON.parse(curUser)
        this.userName=curUser.userName;
        this.logoUrl=curUser.logoUrl;
        this.logoName=curUser.logoName;
        if(curUser.headUrl && curUser.headUrl != ''){
            this.headUrl = curUser.headUrl;
        }
    },
  methods: {
      ...mapMutations({
          setToken: "setToken",
      }),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.newPassWord != this.form.conformPassword){
            this.$message.error('新密码和确认密码不一致')
            return
          }
          this.$request.publicKey().then(data => {
            this.form.uuid = data.data;
            let json = {
              "newPassWord": this.$util.encryptedData(this.form.uuid,this.form.newPassWord),
              "oldPassWord": this.$util.encryptedData(this.form.uuid,this.form.oldPassWord),
              "passWord": this.$util.encryptedData(this.form.uuid,this.form.oldPassWord),
              "uuid": this.form.uuid
            }
            this.$request.rbacuserUpdatePassword(json).then(res => {
              if(0 == res.code){
                this.dialogFormVisible = false
                this.$message.success("修改密码成功")
              }
            });
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCommand(res) {
      this.$util.clearObj(this.form)
      if (res == 1) {
        this.setToken("");
        localStorage.clear();
          this.$request
              .rbacroleLogout({ })
              .then((res) => {
                  if (res.code == 0) {
                      this.$router.push('/login')
                  }
              });
      }else if(res == 0){
        this.dialogFormVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 50px;
  background: #4065E0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  .left {
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
    }
    div {
      margin-left: 15px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    i {
      margin: 0 15px;
      font-size: 24px;
    }
      .el-dropdown-link {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              margin-right: 5px;
          }
      }
  }
}
</style>