<template>
  <el-card style="width: 100%;min-width: 1200px">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
      <div  style="height: 47px">
        <el-form-item label="用户名称" class="search-form-item">
          <el-input v-model="form.userName" placeholder="用户名称" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input v-model="form.createUserName" placeholder="创建人" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="所属商户">
          <el-select
                  size="small"
                  class="title-input"
                  v-model="form.mchId"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="请输入商户名称或编号"
                  :remote-method="queryMchSelect"
          >
            <el-option
                    v-for="item in mchIdSelctList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="search-button">
      <div style="margin: 0 auto;height: 48px;width: 200px">
        <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
        >查询
        </el-button
        >
        <el-button size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
    <div class="op-botton">
      <el-button type="primary" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick">新增</el-button>
    </div>
    <div style="width: 100%;margin-top: 0px;">
      <el-table
              :data="records"
              border
              :stripe="true">
        <el-table-column  type="selection" fixed="left" width="55">
        </el-table-column>
        <el-table-column
                prop="loginName"
                label="登录账号"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="roleName"
                show-overflow-tooltip
                label="所属角色">
        </el-table-column>
        <el-table-column
                prop="userName"
                show-overflow-tooltip
                label="姓名">
        </el-table-column>
        <el-table-column
                prop="userTypeStr"
                show-overflow-tooltip
                label="用户类型">
        </el-table-column>
        <el-table-column
                prop="userStatus"
                show-overflow-tooltip
                label="账号状态">
          <template slot-scope="scope">
            <el-switch
                    v-model="scope.row.userStatus"
                    active-value="on"
                    inactive-value="off"
                    active-color="#4A7DFA"
                    inactive-color="#E4E7ED"
                    @change=changeSwitch($event,scope.row,scope.$index)>
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
                prop="createUserName"
                show-overflow-tooltip
                label="创建人">
        </el-table-column>
        <el-table-column
                prop="createTime"
                show-overflow-tooltip
                label="创建时间">
        </el-table-column>
        <el-table-column
                prop="address"
                label="操作"
                align="right"
                width="330"
                fixed="right">
          <template slot-scope="scope">
            <el-button
                    plain
                    size="small"
                    @click="changePasswordClick(scope.row)"
                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
            >重置密码
            </el-button
            >
            <el-button
                    plain
                    size="small"
                    @click="editClick(scope.row)"
                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
            >编辑
            </el-button
            >
            <el-button
                    plain
                    size="small"
                    @click="deleteClick(scope.row)"
                    style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"
                    v-show="scope.row.isDefault != 'YES'"
            >删除
            </el-button
            >
            <el-button
                    plain
                    size="small"
                    @click="urlClick(scope.row)"
                    style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"
                    v-show="curUserType == 'SUPER'"
            >链接
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block" style="margin-top: 15px;text-align: right">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.currentPage"
              :page-sizes="queryInfo.pageSizes"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="queryInfo.total"
      >
      </el-pagination>
    </div>
    <el-dialog
            :title="'重置 ' + codeForm.item.loginName + ' 的密码'"
            :visible.sync="dialogCode"
    >
      <el-form :model="codeForm" :rules="dataRule" ref="codeForm">
        <el-form-item label="登录账号" :label-width="formLabelWidth">
          <el-input
                  v-model="codeForm.item.loginName"
                  autocomplete="off"
                  disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="操作人的密码" :label-width="formLabelWidth" required>
          <el-input
                  v-model.trim="codeForm.passWord"
                  autocomplete="off"
                  show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" required>
          <el-input
                  show-password
                  v-model.trim="codeForm.newPassWord"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" :label-width="formLabelWidth" required>
          <el-input
                  show-password
                  v-model.trim="codeForm.newPassWord2"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCode = false">取 消</el-button>
        <el-button type="primary" @click="changePasswordSure">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹框内容-->
    <el-dialog title="新增/编辑账户" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="登录账号" :label-width="formLabelWidth" required>
          <el-input
                  v-model.trim="currentAccount.loginName"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth" >
          <el-input
                  v-model.trim="currentAccount.phone"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" required>
          <el-input
                  v-model.trim="currentAccount.userName"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户类型" :label-width="formLabelWidth" required v-show="searchMchVisible">
          <el-select
                  style="width: 100%"
                  v-model="currentAccount.userType"
                  placeholder="请选择"
                  @change="changeUserType(currentAccount.userType)"
                  :disabled="currentAccount.userId ? true : false"
          >
            <el-option
                    :label="item.value"
                    :value="item.key"
                    v-for="(item, index) in dictUserTypeList"
                    :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属渠道" :label-width="formLabelWidth" required v-show="orgVisible">
          <el-select
                  style="width: 100%"
                  v-model="currentAccount.orgId"
                  placeholder="请选择"
          >
            <el-option
                    :label="item.value"
                    :value="item.key"
                    v-for="(item, index) in orgSelectList"
                    :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属商户" :label-width="formLabelWidth"  v-show="mchVisible">
          <el-select
                  style="width: 100%"
                  v-model="currentAccount.mchId"
                  placeholder="请选择"
                  @change="changeMchId(currentAccount.mchId)"
          >
            <el-option
                    :label="item.value"
                    :value="item.key"
                    v-for="(item, index) in mchSelectList"
                    :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门权限" :label-width="formLabelWidth" required v-show="deptVisible">
          <el-tree
                  :data="elTreeData"
                  show-checkbox
                  :default-checked-keys="currentAccount.deptIdList"
                  :check-strictly="true"
                  node-key="id"
                  :props="defaultProps"
                  ref="tree"
                  @check-change="handleCheckChange"
          >
          </el-tree>
          <span style="color: red">若已选择部门，则只能看到所选部门对应的账单缴费数据</span>
        </el-form-item>
        <el-form-item label="所属角色" :label-width="formLabelWidth" required v-show="roleVisible">
          <el-select
                  style="width: 100%"
                  v-model="currentAccount.roleId"
                  placeholder="请选择角色"
          >
            <el-option
                    :label="item.value"
                    :value="item.key"
                    v-for="(item, index) in dictRoleList"
                    :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
                class="accountStatus"
                label="账号状态"
                :label-width="formLabelWidth"
                required
        >
          <el-radio v-model="currentAccount.userStatus" label="on"
          >启用
          </el-radio
          >
          <el-radio v-model="currentAccount.userStatus" label="off"
          >禁用
          </el-radio
          >
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="currentAccount.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除内容-->
    <el-dialog
            class="del"
            title="删除账号"
            :visible.sync="dialogDeleteVisible"
            width="30%"
            center
    >
      <div class="tip">
        <img
                class="tip-icon"
                src="../../../assets/images/wra-icon.png"
                alt=""
        />
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    import Treeselect from '@riophae/vue-treeselect';
    export default {
        name: "account", // 账户管理
        components: {
            Treeselect
        },
        data() {
            var checkPass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('密码不能为空'));
                } else {
                    if (value !== '') {
                        let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
                        if (!reg.test(value)) {
                            callback(new Error('请输入不少于8位包含大小写数字的密码'))
                        }
                    }
                }
            }
            var checkPass1 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.codeForm.newPassWord2) {
                    callback(new Error('两次输入密码不一致'));
                } else {
                    callback();
                }
            }
            return {
                limit: "2",
                page: "1",
                records: [],
                currentAccount: {
                    userId: "",
                    loginName: "",
                    mchId: "",
                    orgId: "",
                    roleId: "",
                    userName: "",
                    userType: 1,
                    remark: "",
                    userStatus: "",
                    password: "",
                    phone: "",
                },
                codeForm: {
                    newPassWord: "",
                    newPassWord2: "",
                    passWord: "",
                    prvkey: "",
                    uuid: "",
                    item: {},
                },
                deleteItem: {},
                dictRoleList: [],
                orgSelectList: [],
                mchSelectList: [],
                dictUserTypeList: [],
                mchIdSelctList: [],
                elTreeData: [],
                form: {
                    roleName: "", // 角色名称
                    createUserName: "", // 创建者
                    mchId: "", // 商户编号
                    mchName: "", // 商户名称
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                defaultProps: {
                    children: "children",
                    label: "label",
                },
                dialogCode: false, // 重置密码弹框
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, // 删除弹框
                orgVisible: false,
                mchVisible: false,
                roleVisible: false,
                deptVisible: false,
                searchMchVisible: false,
                formLabelWidth: "120px",
                curUserType:"",

                dataRule: {
                    newPassWord: [
                        {validator: checkPass, trigger: 'blur'}
                    ],
                    newPassWord2: [
                        {validator: checkPass1, trigger: 'blur'}
                    ]
                }
            };
        },
        created() {
            this.loadData();
            this.getRoleData();
            this.getMchSelectList();
            let userType = localStorage.getItem('curUserType');
            this.curUserType = userType;
            if(userType != null){
                if(userType == 'MCH'){
                    this.searchMchVisible = false;
                }else {
                    this.searchMchVisible = true;
                }
            }
        },
        computed: {},
        methods: {
            loadData(page) {
                this.$request
                    .rbacuserList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.form,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            queryMchSelect(queryName){
                if (queryName !== '') {
                    this.$request.deptGetOrgOrMchList({
                        workType:"2",
                        queryName:queryName
                    }).then((res) => {
                        if (res.code == 0) {
                            this.mchIdSelctList = res.data;
                        }
                    });
                }
            },
            handleCheckChange(a, b) {
                this.findchildren(a,b);
                //this.findParent(a,b);
            },
            findchildren(current,b){
                this.$refs.tree.setChecked(current.id,b,b);
                let d=current.children;
                if(d && d.length>0){
                    d.forEach(a =>{
                        this.findchildren(a, b);
                    })
                }
            },
            changeSwitch(event, data, index){
                var reqData = {};
                reqData.userId=data.userId;
                reqData.userStatus=data.userStatus;
                this.$request
                    .updateStatus(reqData)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message:"成功",
                                type: "success",
                            });
                        }
                    });
            },
            changeUserType(userType){
                if(userType == 1){
                    this.orgVisible = false;
                    this.mchVisible = false;
                    this.roleVisible = false;
                    this.deptVisible = false;
                }else if(userType == 2){
                    this.orgVisible = true;
                    this.mchVisible = false;
                    this.roleVisible = true;
                    this.deptVisible = false;
                }else if(userType == 3){
                    this.orgVisible = false;
                    this.mchVisible = true;
                    this.roleVisible = true;
                    this.deptVisible = true;
                }
            },
            changeMchId(mchId){
                var reqData = {};
                reqData.mchId=mchId;
                this.$request.deptTree(reqData).then((res) => {
                    if (res.code == 0) this.elTreeData = res.data;
                });
            },
            getRoleData() {
                this.$request.dictRole().then((res) => {
                    if (res.code == 0) this.dictRoleList = res.data;
                });
                this.$request.dictUserType().then((res) => {
                    if (res.code == 0) this.dictUserTypeList = res.data;
                });
            },
            handleSizeChange(val) {
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                this.loadData(val);
            },
            createClick() {
                this.orgVisible=false;
                this.mchVisible=false;
                this.roleVisible= false;
                this.deptVisible= false;
                this.$util.clearObj(this.currentAccount);
                if(!this.searchMchVisible){
                    this.deptVisible=true;
                    this.currentAccount.mchId=this.mchSelectList[0].key;
                    this.changeMchId( this.currentAccount.mchId);
                    this.currentAccount.userType = 3;
                    this.roleVisible = true;
                }
                this.elTreeData=[];
                this.currentAccount.userStatus = "on";
                this.dialogFormVisible = true;
                if(this.orgSelectList.length == 0){
                    this.orgSelectList=this.getOrgSelectList();
                }
                if(this.mchSelectList.length == 0){
                    this.mchSelectList=this.getMchSelectList();
                }
            },
            getOrgSelectList() {
                this.$request.dictOrg().then((res) => {
                    if (res.code == 0) this.orgSelectList = res.data;
                });
            },
            getMchSelectList() {
                this.$request.dictMch().then((res) => {
                    if (res.code == 0) this.mchSelectList = res.data;
                });
            },
            changePasswordClick(item) {
                this.dialogCode = true;
                this.$util.clearObj(this.codeForm);
                this.codeForm.item = item;
            },
            changePasswordSure() {
                this.$request
                    .publicKey({
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            var uuid = res.data;
                            var data = {};
                            data.userId =  this.codeForm.item.userId;
                            data.newPassWord = this.$util.encryptedData( uuid, this.codeForm.newPassWord);
                            data.passWord = this.$util.encryptedData( uuid, this.codeForm.passWord);
                            data.uuid = uuid;
                            this.$request.restPassword(data).then((res) => {
                                if (res.code == 0) {
                                    this.dialogCode = false;
                                    this.$message({
                                        message: "成功",
                                        type: "success",
                                    });
                                }
                            })
                        }
                    });
            },
            editClick(item) {
                this.$request.rbacuserInfo(item.userId).then((res) => {
                    if (res.code == 0) {
                        this.currentAccount = res.data;
                        if(!this.searchMchVisible){
                            this.mchVisible = false;
                        }
                        this.changeUserType(this.currentAccount.userType);
                        if(this.orgSelectList.length == 0){
                            this.orgSelectList=this.getOrgSelectList();
                        }
                        if(this.mchSelectList.length == 0){
                            this.mchSelectList=this.getMchSelectList();
                        }
                        if(this.currentAccount.userType == 3){
                            this.changeMchId(this.currentAccount.workId);
                        }
                        this.dialogFormVisible = true;
                    } else {
                        this.$message.error("查询用户信息出错,userId=" + item.userId);
                    }
                });
            },
            deleteClick(item) {
                this.deleteItem = item;
                this.dialogDeleteVisible = true;
            },
            urlClick(item) {
                this.$request.rbacuserUrl(item).then((res) => {
                    if (res.code == 0) {
                        this.$alert(res.data, {
                            confirmButtonText: '确定',
                            callback: action => {
                            }
                        });
                    }
                });
            },
            deleteSure() {
                this.$request.rbacuserDelete(this.deleteItem.userId).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.form);
            },
            submit() {
                if (this.currentAccount.userId) {
                    let currentAccount = {...this.currentAccount};
                    delete currentAccount.password;
                    let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
                    currentAccount.deptIdList = res;
                    this.$request.rbacuserUpdate(currentAccount).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.$util.clearObj(this.currentAccount);
                            this.loadData();
                        }
                    });
                } else {
                    let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
                    this.currentAccount.deptIdList = res;
                    let that = this.currentAccount;
                    this.$request
                        .rbacuserSave(that)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$alert(res.data, {
                                    confirmButtonText: '确定',
                                    callback: action => {
                                    }
                                });
                                this.dialogFormVisible = false;
                                this.loadData();
                                this.$util.clearObj(this.currentAccount);
                            }
                        });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
  .choose-days {
    width: 67px;
    height: 32px;
    margin-top: 3px;
    margin-left: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  .choose-days-selected {
    background: #4065E0;
    color: #FFFFFF;
  }
  .search-form {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 14px;
  }
  .search-input  /deep/.el-input__inner {
    height: 32px!important;
    width: 200px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
  }
  .vue-treeselect {
    width: 200px;
    line-height: 32px;
  }
  .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
  }
  .search-button {
    height: 52px;
    text-align: center;
    line-height: 48px;
    border-bottom: 1px solid #DCDFE6;
  }

  .op-botton{
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: right;
  }
  /deep/  .el-table th, .el-table tr {
    background-color: #F5F5F5;
    color: #909399;
  }
  /deep/ .el-table td {
    padding: 0px 0;
    height: 50px;
  }
  /deep/ .el-table th {
    padding: 0px 0;
    height: 50px;
  }
  .del .tip .tip-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
</style>
