import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    saveKeys: ['token'],
    token: '',
    userInfo: {
    }, // 用户信息
    menus: [],
    elMenuSelectValue: '0',
    publicKey: ''
  },
  mutations: {
    setState(state, payload) {
      Object.keys(payload).forEach(key => {
        if (state.saveKeys.includes(key)) state[key] = payload[key]
      })
    },
    setToken(state, item) {
      state.token = item
    },
    setUserInfo(state, item) {
      state.userInfo = item
    },
    setMenus(state, menus) {
      state.menus = menus
    },
    setElMenuSelectValue(state, elMenuSelectValue) {
      state.elMenuSelectValue = elMenuSelectValue
    },
    setPublicKey(state, item) {
      state.publicKey = item
    },
  },
  getters: {
    menuList(state) {
      return getMenuList(state)
    }
  },
  actions: {

  },
  modules: {
  }
})

function getMenuList(state) {
  let type = state.userInfo.type
  let menuList = [
    {
      rbacMenu: {
        menuName: '首页',
        url: '/home'
      }
    },
    {
      rbacMenu: {
        menuName: '系统管理',
        name: 'system',
      },
      children: (() => {
        let children = [
          {
            rbacMenu: {
              menuName: '角色管理',
              url: '/system/role'
            }
          },
          {
            rbacMenu: {
              menuName: '账户管理',
              url: '/system/account'
            }
          }]
        if (type == 1) children.push({
          rbacMenu: {
            menuName: '商户设置',
            url: '/system/business'
          }
        })
        if (type == 2) children.push({
          rbacMenu: {
            menuName: '渠道设置',
          }
        })
        return children
      })()
    },
    {
      rbacMenu: {
        menuName: '基础信息',
        name: 'base',
      },
      children:
        (() => {
          let children = []
          if (type != 1) {
            children.push({
              rbacMenu: {
                menuName: '渠道管理'
              }
            })
            children.push({
              rbacMenu: {
                menuName: '缴费场景'
              }
            })
            children.push({
              rbacMenu: {
                menuName: '商户管理',
              }
            })
          }
          if (type != 2) {
            children.push({
              rbacMenu: {
                menuName: '用户管理',
                url: '/base/user'
              }
            })
            children.push({
              rbacMenu: {
                menuName: '缴费项目'
              }
            })
          }
          if (type != 1) {
            children.push({
              rbacMenu: {
                menuName: 'Banner管理'
              }
            })
          }
          return children
        })()
    },
    {
      rbacMenu: {
        menuName: '账单管理',
      },
      children: [
        {
          rbacMenu: {
            menuName: '账单批次',
          }
        },
        {
          rbacMenu: {
            menuName: '账单明细',
          }
        }
      ]
    },
    {
      rbacMenu: {
        menuName: '交易管理',
      },
      children: [
        {
          rbacMenu: {
            menuName: '缴费记录',
          }
        },
        {
          rbacMenu: {
            menuName: '退费记录',
          }
        },
        {
          rbacMenu: {
            menuName: '日结统计',
          }
        },
        {
          rbacMenu: {
            menuName: '对账单下载',
          }
        }
      ]
    }
  ]
  return menuList
}