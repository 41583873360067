<template>
  <el-card style="width: 100%;min-width: 1300px">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="90px" >
      <div style="height: 47px;">
        <el-form-item label="创建时间">
          <el-date-picker
                  v-model="queryForm.createTime"
                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width: 100%;height: 32px"
          >
          </el-date-picker>
        </el-form-item>
        <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
        <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
        <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
      </div>
      <div  style="height: 47px">
        <el-form-item label="账单批次号" class="search-form-item">
          <el-input v-model="queryForm.packNo" placeholder="账单批次号" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="账单编号">
          <el-input v-model="queryForm.billNo" placeholder="账单编号" class="search-input"></el-input>
        </el-form-item>
        <el-form-item :label="mchConfQuery.userNameRename">
          <el-input v-model="queryForm.mchUserName" :placeholder="mchConfQuery.userNameRename" class="search-input"></el-input>
        </el-form-item>
        <el-form-item :label="mchConfQuery.userNoRename">
          <el-input v-model="queryForm.mchUserNo" :placeholder="mchConfQuery.userNoRename" class="search-input"></el-input>
        </el-form-item>
      </div>
      <div  style="height: 47px">
        <el-form-item label="缴费状态">
          <el-select
                  v-model="queryForm.billStatus"
                  placeholder="请选择"
                  size="small"
                  class="search-input"
                  clearable
          >
            <el-option label="未缴费" value="0"></el-option>
            <el-option label="已缴费" value="1"></el-option>
            <el-option label="已销账" value="2"></el-option>
            <el-option label="缴费中" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费项目" >
          <el-select
                  size="small"
                  clearable
                  class="search-input"
                  v-model="queryForm.itemId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入缴费项目名称"
                  :remote-method="queryItemSelect"
          >
            <el-option
                    v-for="item in optionList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
          <el-form-item label="所属部门" class="search-input" v-show="deptVisible">
        <treeselect class="title-input" size="small"
                    :appendToBody="true"
                    name="demo"
                    :multiple="false"
                    :searchable="true"
                    :options="addUserDeptTree"
                    :limit="3"
                    v-model="queryForm.deptId"
                    placeholder="请选择所属部门"
        />
      </el-form-item>
        <el-form-item label="所属商户" class="search-input" v-show="mchVisible">
          <el-select
                  size="small"
                  class="search-input"
                  v-model="queryForm.mchIdList"
                  multiple
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入商户名称或编号"
                  :remote-method="queryMchSelect"
          >
            <el-option
                    v-for="item in mchIdSelctList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属渠道" class="search-input" v-show="mchVisible">
          <el-select
                  size="small"
                  class="search-input"
                  v-model="queryForm.orgIdList"
                  multiple
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入渠道名称或编号"
                  :remote-method="queryOrgSelect"
          >
            <el-option
                    v-for="item in orgIdSelctList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="search-button">
      <div style="margin: 0 auto;height: 48px;width: 200px">
        <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
        >查询
        </el-button
        >
        <el-button size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
    <div class="op-botton" >
      <el-button type="primary"  size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" v-show="!mchVisible"  @click="createClick()">新增</el-button>
      <el-button size="small"   style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" v-show="!mchVisible" @click="clickBatchWriteoff">批量销账</el-button>
      <el-button size="small"   style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;" v-show="!mchVisible"  @click="createBatchDelete">批量删除</el-button>
      <el-button size="small"   style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" v-show="!mchVisible" @click="clickBatchCuijiao">批量催缴</el-button>
      <el-button type="primary"  size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" v-show="!mchVisible" @click="createExport">导出</el-button>
    </div>
    <div style="width: 100%;margin-top: 0px;">
      <el-table
              :data="records"
              border
              @selection-change="changeFun"
              :stripe="true">
        <el-table-column  type="selection" fixed="left" width="55">
        </el-table-column>
        <template v-for="(item,index) in tableHead">
          <el-table-column :prop="item.columnProp"  align="center" show-overflow-tooltip :label="item.columnLabel" :key="index" v-if="item.columnProp != 'id'"></el-table-column>
        </template>
        <el-table-column
                prop="address"
                label="操作"
                align="right"
                width="280"
                fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" plain size="small" @click="editClick(scope.row)" v-show="!mchVisible"  :disabled="scope.row.billStatus!=0">修改</el-button>
            <el-button type="danger" plain size="small" @click="deleteClick(scope.row)" v-show="!mchVisible"  :disabled="scope.row.billStatus!=0">删除</el-button>
            <el-button type="primary" plain size="small" @click="clickWriteoff(scope.row)" v-show="!mchVisible"  :disabled="scope.row.billStatus!=0">销账</el-button>
            <el-button type="primary" plain size="small" @click="cuijiaoClick(scope.row)" v-show="!mchVisible"  :disabled="scope.row.billStatus!=0">催缴</el-button>
            <span v-show="mchVisible">无权限</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block" style="margin-top: 15px;text-align: right">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.currentPage"
              :page-sizes="queryInfo.pageSizes"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="queryInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 删除内容-->
    <el-dialog
            class="del"
            title="删除"
            :visible.sync="dialogDeleteVisible"
            width="30%"
            center
    >
      <div class="tip">
        <img
                class="tip-icon"
                src="../../../assets/images/wra-icon.png"
                alt=""
        />
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
            class="del"
            title="销账"
            :visible.sync="dialogWriteoffVisible"
            width="30%"
            center
    >
      <div class="tip">
        <img
                class="tip-icon"
                src="../../../assets/images/wra-icon.png"
                alt=""
        />
        <span class="txt">确定销账后将无法撤回，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogWriteoffVisible = false">取 消</el-button>
        <el-button type="primary" @click="writeoffSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
            class="del"
            title="催缴"
            :visible.sync="dialogCuijiaoVisible"
            width="30%"
            center
    >
      <div class="tip">
        <img
                class="tip-icon"
                src="../../../assets/images/wra-icon.png"
                alt=""
        />
        <span class="txt">您确认要催缴吗</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCuijiaoVisible = false">取 消</el-button>
        <el-button type="primary" @click="cuijiaoSure">确 定</el-button>
      </span>
    </el-dialog>
    <!--  创建账单弹框-->
    <el-dialog title="新增" :visible.sync="dialogFormVisible">
      <div class="adds pr20" style="margin-bottom: 7px;text-align: right;">
        <el-button class="tsBtn" type="primary" size="small" @click="addinput">添加项目</el-button>
        <el-button type="danger" plain size="small" @click="deleteinput">删除项目</el-button>
      </div>
      <el-form :model="createBillForm">
        <el-form-item :label="mchConf.userNoRename" :label-width="formLabelWidth" required>
          <el-input v-model="createBillForm.mchUserNo" autocomplete="off" @blur="fillUserInfo"></el-input>
        </el-form-item>
        <el-form-item :label="mchConf.userNameRename" :label-width="formLabelWidth" >
          <el-input v-model="createBillForm.mchUserName" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="所属部门" :label-width="formLabelWidth" >
          <treeselect
                  name="demo"
                  :multiple="false"
                  :searchable="searchable"
                  :options="addUserDeptTree"
                  :limit="3"
                  v-model="createBillForm.deptId"
                  style="width: 100%"
                  placeholder="请选择所属部门"
          />
        </el-form-item>
        <el-form-item label="账单批次" :label-width="formLabelWidth" required>
          <el-select  style="width:100%" v-model="createBillForm.packNo" placeholder="请选择" >
            <el-option
                    v-for="item in packList"
                    :key="item.packNo"
                    :label="item.packName"
                    :value="item.packNo">
            </el-option>
          </el-select>
        </el-form-item>
        <div v-for="(ddd,index) in createBillForm.itemList" class="addForm" style="background: #F7FAFE ;width: 100%;border-radius: 4px;border: 2px dashed #CCE5FF ; padding-right: 5px;box-sizing: border-box; margin-top: 10px;">
          <el-form-item label="缴费项目" :label-width="formLabelWidth" required>
            <el-select  style="width:100%" clearable  v-model="ddd.itemId" placeholder="请选择缴费项目" >
              <el-option
                      v-for="item in optionItemList"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="缴费金额(元)" :label-width="formLabelWidth" required>
            <el-input v-model="ddd.itemAmountStr" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth" >
            <el-input v-model="ddd.remark" autocomplete="off"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCreateForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="dialogEditFormVisible">
      <el-form :model="editBillForm">
        <el-form-item :label="mchConf.userNoRename" :label-width="formLabelWidth" required>
          <el-input v-model="editBillForm.mchUserNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="mchConf.userNameRename" :label-width="formLabelWidth" >
          <el-input v-model="editBillForm.mchUserName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属部门" :label-width="formLabelWidth" >
          <treeselect
                  name="demo"
                  :multiple="false"
                  :searchable="searchable"
                  :options="addUserDeptTree"
                  :limit="3"
                  v-model="editBillForm.deptId"
                  style="width: 100%"
                  placeholder="请选择所属部门"
          />
        </el-form-item>
        <el-form-item label="账单批次" :label-width="formLabelWidth" required>
          <el-select style="width:100%"  v-model="editBillForm.packNo" placeholder="请选择" :disabled="true">
            <el-option
                    v-for="item in packList"
                    :key="item.packNo"
                    :label="item.packName"
                    :value="item.packNo">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费项目" :label-width="formLabelWidth" required>
          <el-select style="width:100%"  v-model="editBillForm.itemId" placeholder="请选择缴费项目" >
            <el-option
                    v-for="item in optionItemList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费金额(元)" :label-width="formLabelWidth" required>
          <el-input v-model="editBillForm.billAmountStr" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" >
          <el-input v-model="editBillForm.remark" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEditForm">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    import Treeselect from '@riophae/vue-treeselect'
    //import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import ElForm from "../../../../node_modules/element-ui/packages/form/src/form";
    export default {
        components: {
            ElForm,
            Treeselect
        },
        //components:[myDrawer],
        name: "org", // 渠道管理
        data() {
            return {
                limit: "10",
                page: "1",
                records: [],
                tableHead:[],
                choose7: true,
                choose30: false,
                choose180: false,
                addUserDeptTree: [],
                createBillForm: {
                    itemList:[],
                    deptId:"",
                },
                editBillForm: {
                },
                queryForm: {
                },
                formLabelWidth: "120px",
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                    pageCount:5
                },
                // 创建账单弹框开始
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false,
                dialogCuijiaoVisible: false,
                dialogEditFormVisible: false,
                dialogWriteoffVisible: false,
                searchable: true,
                userTypeOrg: true,
                mchVisible: false,
                deptVisible: false,
                cuijiaoItem: {
                    billNoList:[]
                },
                mchConf: {
                    userNoRename:"",
                    userNameRename:"",
                },
                mchConfQuery: {
                    userNoRename:"",
                    userNameRename:"",
                },
                optionList: [],
                optionItemList: [],
                packList:[],
                selectedRows: [],
                deleteItem: {
                    billNoList:[]
                },
                writeoffItem: {
                    billNoList:[]
                },
                mchIdSelctList:[],
                orgIdSelctList:[],
            };
        },
        created() {
            let packNo = this.$route.query.packNo;
            if(packNo){
                this.queryForm.packNo = packNo;
            }
            this.loadUserType();
            let userType = localStorage.getItem('curUserType');
            if(userType == 'ORG'){
                this.userTypeOrg = true;
            }
            if(userType != null){
                if(userType == 'MCH'){
                    this.mchVisible = false;
                    this.deptVisible = true;
                    this.loadAddUserDeptTree();
                    this.$request.mchbillMchInfo({}).then((res) => {
                        if (res.code == 0) {
                            this.mchConf = res.data;
                            this.mchConfQuery =  this.$util.cloneObj(res.data);
                            this.mchConfQuery.userNameRename=this.mchConfQuery.userNameRename;
                            this.mchConfQuery.userNoRename=this.mchConfQuery.userNoRename;
                        }
                    });
                }else {
                    this.mchVisible = true;
                    this.deptVisible = false;
                    if(!packNo){
                        this.reduceQueryFormTime(7);
                    }
                    this.mchConfQuery.userNameRename="用户名称";
                    this.mchConfQuery.userNoRename="用户编号";
                }
            }
            this.loadTableHead();
            this.loadData();
        },
        computed: {},
        methods: {
            rowClass({ row, rowIndex }) {
                return "background:rgba(242, 242, 242, 1);font-size: 13px;";
            },
            selected(row, index) {
                return scope.row.billStatus!=0;
            },
            createExport() {
                this.$request
                    .mchbillExport({
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            fillUserInfo(){
                if(this.createBillForm.mchUserNo != ''){
                    this.$request.mchUserInfobyUserNo({ mchUserNo:this.createBillForm.mchUserNo})
                        .then((res) => {
                            if(res.data){
                                this.createBillForm.mchUserName=res.data.mchUserName;
                                this.createBillForm.deptId=res.data.deptId;
                            }else{
                                this.createBillForm.mchUserName="";
                                this.createBillForm.deptId=null;
                            }
                        });
                }
            },
            loadAddUserDeptTree(){
                this.$request .mchUserDeptTree({ })
                    .then((res) => {
                        this.addUserDeptTree.push(res.data);
                        this.clearSelectDeptChildren(this.addUserDeptTree);
                    });
            },
            clearSelectDeptChildren(root){
                root.forEach (e =>{
                    if(!e.children || e.children.length == 0){
                        delete e['children'];
                        return;
                    }else{
                        this.clearSelectDeptChildren(e.children);
                    }
                })
            },
            loadTableHead() {
                this.$request
                    .mchbillTableHead({ })
                    .then((res) => {
                        if (res.code == 0) {
                            this.tableHead = res.data;
                        }
                    });
            },
            loadUserType(){
                let userType = localStorage.getItem('curUserType');
                if(userType != null){
                    if(userType == 'MCH'){
                        this.mchVisible = false;
                        this.deptVisible = true;
                    }else {
                        this.mchVisible = true;
                        this.deptVisible = false;
                    }
                    return;
                }
            },
            loadData(page) {
                this.$request
                    .mchbillList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            changeFun(val){
                this.selectedRows = val;
            },
            submitCreateForm(){
                console.log(JSON.stringify(this.createBillForm));
                this.$request.mchbillSave( this.createBillForm)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({ message: "成功",type: "success",});
                            this.dialogFormVisible = false;
                            this.loadData();
                            this.$util.clearObj(this.createBillForm);
                        }
                    });
            },
            submitEditForm(){
                this.$request.mchbillUpdate( this.editBillForm)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({ message: "成功",type: "success",});
                            this.dialogEditFormVisible = false;
                            this.loadData();
                        }
                    });
            },
            addinput() {
                this.createBillForm.itemList.push({ 'itemId': '', 'itemAmountStr': '', 'remark': '' })
            },
            deleteinput() {
                if(this.createBillForm.itemList.length == 1){
                    this.$message({ message: "请最少保留一组缴费项目",type: "error"});
                    return;
                }
                this.createBillForm.itemList.pop();
            },
            reduceQueryFormTime(days) {
                if(days == 7){
                    this.choose7 = true;
                    this.choose30 = false;
                    this.choose180 = false;
                }else if (days == 30){
                    this.choose7 = false;
                    this.choose30 = true;
                    this.choose180 = false;
                }else if (days == 180){
                    this.choose7 = false;
                    this.choose30 = false;
                    this.choose180 = true;
                }
                var date = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
                let formatDate1 = this.$util.formatDate(date);
                date.setDate(date.getDate() - (days+1));
                date = new Date(new Date(date.toLocaleDateString()).getTime());
                let formatDate2 = this.$util.formatDate(date);
                this.$set(this.queryForm, "createTime", [formatDate2, formatDate1]);
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            createClick() {
                this.$util.clearObj(this.createBillForm);
                this.createBillForm.itemList=[];
                this.addinput();
                this.initAddEditForm();
                this.dialogFormVisible = true;
            },
            initAddEditForm(){
                if(this.packList.length == 0){
                    this.$request.mchbillPackList({}).then((res) => {
                        if (res.code == 0) this.packList = res.data;
                    })
                }
                if(this.mchConf.userNoRename == ""){
                    this.$request.mchbillMchInfo({}).then((res) => {
                        if (res.code == 0) this.mchConf = res.data;
                    })
                }
                if(this.optionItemList.length == 0){
                    this.$request.dictBillItemList({limit:1000}).then((res) => {
                        if (res.code == 0) this.optionItemList = res.data;
                    })
                }
            },
            editClick(item) {
                this.initAddEditForm();
                this.$request.mchbillInfo(item).then((res) => {
                    if (res.code == 0) {
                        this.editBillForm = res.data;
                        this.dialogEditFormVisible = true;
                    }
                });
            },
            createBatchDelete() {
                if(this.selectedRows.length == 0){
                    this.$message({ message: "请选择数据",type: "error",});
                    return;
                }
                var arr = [];
                this.selectedRows.forEach(a =>{
                    arr.push(a.billNo);
                })
                this.deleteItem.billNoList = arr;
                this.dialogDeleteVisible = true;
            },
            deleteClick(item) {
                var arr = [];
                arr.push(item.billNo);
                this.deleteItem.billNoList = arr;
                this.dialogDeleteVisible = true;
            },
            clickWriteoff(item) {
                var arr = [];
                arr.push(item.billNo);
                this.writeoffItem.billNoList = arr;
                this.dialogWriteoffVisible = true;
            },
            clickBatchWriteoff(){
                if(this.selectedRows.length == 0){
                    this.$message({ message: "请选择数据",type: "error",});
                    return;
                }
                var arr = [];
                this.selectedRows.forEach(a =>{
                    arr.push(a.billNo);
                })
                this.writeoffItem.billNoList = arr;
                this.dialogWriteoffVisible = true;
            },
            cuijiaoClick(item) {
                var arr = [];
                arr.push(item.billNo);
                this.cuijiaoItem.billNoList = arr;
                this.dialogCuijiaoVisible = true;
            },
            clickBatchCuijiao(){
                if(this.selectedRows.length == 0){
                    this.$message({ message: "请选择数据",type: "error",});
                    return;
                }
                var arr = [];
                this.selectedRows.forEach(a =>{
                    arr.push(a.billNo);
                })
                this.cuijiaoItem.billNoList = arr;
                this.dialogCuijiaoVisible = true;
            },
            cuijiaoSure() {
                this.$request.mchbillCuijiao(this.cuijiaoItem).then((res) => {
                    if(res.code == 0){
                        this.$alert(res.data, {
                            confirmButtonText: '确定',
                            callback: action => {
                            }
                        });
                        this.dialogCuijiaoVisible = false;
                    }

                    this.dialogCuijiaoVisible = false;
                });
            },
            deleteSure() {
                this.$request.mchbillDelete(this.deleteItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({ message: "成功", type: "success",});
                        this.loadData();
                    }
                });
            },
            writeoffSure() {
                this.$request.mchbillWriteoff(this.writeoffItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogWriteoffVisible = false;
                        this.$message({ message: "成功", type: "success",});
                        this.loadData();
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            queryMchSelect(queryName){
                if (queryName !== '') {
                    this.$request.deptGetOrgOrMchList({
                        workType:"2",
                        queryName:queryName
                    }).then((res) => {
                        if (res.code == 0) {
                            this.mchIdSelctList = res.data;
                        }
                    });
                }
            },
            queryOrgSelect(queryName){
                this.$request.deptGetOrgOrMchList({
                    workType:"1",
                    queryName:queryName
                }).then((res) => {
                    if (res.code == 0) {
                        this.orgIdSelctList = res.data;
                    }
                });
            },
            queryItemSelect(queryName){
                this.$request.dictBillItemList({
                    name:queryName
                }).then((res) => {
                    if (res.code == 0) {
                        this.optionList = res.data;
                    }
                });
            },
            submit() {
                if (!this.curDataForm.orgId) {
                    let curDataForm = { ...this.curDataForm };
                    this.$request.bashOrgSave(curDataForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.$util.clearObj(this.curDataForm);
                            this.loadData();
                            this.loadOrgItem();
                        }
                    });
                } else {
                    this.$request
                        .bashOrgUpdate({
                            ...this.curDataForm,
                        })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message({
                                    message: "成功",
                                    type: "success",
                                });
                                this.dialogFormVisible = false;
                                this.loadData();
                                this.loadOrgItem();
                                this.$util.clearObj(this.curDataForm);
                            }
                        });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
  .choose-days {
    width: 67px;
    height: 32px;
    margin-top: 3px;
    margin-left: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  .choose-days-selected {
    background: #4065E0;
    color: #FFFFFF;
  }
  .search-form {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 14px;
  }
  .search-input  /deep/.el-input__inner {
    height: 32px!important;
    width: 200px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
  }
  .vue-treeselect {
    width: 200px;
    line-height: 32px;
  }
  .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
  }
  .search-button {
    height: 52px;
    text-align: center;
    line-height: 48px;
    border-bottom: 1px solid #DCDFE6;
  }

  .op-botton{
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: right;
  }
  /deep/  .el-table th, .el-table tr {
    background-color: #F5F5F5;
    color: #909399;
  }
  /deep/ .el-table td {
    padding: 0px 0;
    height: 50px;
  }
  /deep/ .el-table th {
    padding: 0px 0;
    height: 50px;
  }
  .del .tip .tip-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .add-button-div {
    margin-bottom: 10px;
    text-align: right;
  }
</style>
