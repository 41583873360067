<template>
  <div class="home">
    <div >
      <mHeader></mHeader>
      <div class="mainContent">
        <container></container>
        <div v-show="false">
        <button  @click="visible = true">文件上传</button>
        <button  @click="dialogFormVisible = true">创建账单</button>
        </div>
      </div>
    </div>
    <!--  文件上传弹框-->
    <el-dialog
            title="导入账单"
            :close-on-click-modal="false"
            @close="closeHandle"
            :visible.sync="visible">
      <el-upload
              drag
              :action="url"
              :before-upload="beforeUploadHandle"
              :on-success="successHandle"
              multiple
              :file-list="fileList"
              style="text-align: center;">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">支持多个文件上传，仅支持拓展名：xls，xlsx，大小不超过24mb</div>
      </el-upload>
      <p class="txt">点击此处<span class="downTxt">下载账单模板</span></p>
      <div class="progress">
        <span class="fileName">文档.xls</span>
        <el-progress :percentage="100" status="success"></el-progress>
      </div>
      <div class="progress">
        <span class="fileName">文档.xls</span>
        <el-progress :percentage="70" status="exception"></el-progress>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="visible = false">确 定</el-button>
      </div>
    </el-dialog>
    <!--  创建账单弹框-->
    <el-dialog title="新增/编辑" :visible.sync="dialogFormVisible">
      <div class="adds pr20">
        <el-button class="tsBtn" type="primary" size="small">添加项目</el-button>
        <el-button type="danger" plain size="small">删除项目</el-button>
      </div>
      <el-form :model="form">
        <el-form-item label="账单名称" :label-width="formLabelWidth" required>
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="缴费项目" :label-width="formLabelWidth" required>
          <el-select class="wSlelct"  v-model="form.region" placeholder="请选择缴费项目" >
            <el-option
                    v-for="item in optionList"
                    :key="item.value"
                    :label="item.type"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费金额" :label-width="formLabelWidth" required>
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth" required>
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <div class="addForm">
          <el-form-item label="账单名称" :label-width="formLabelWidth" required>
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="缴费项目" :label-width="formLabelWidth" required>
            <el-select class="wSlelct"  v-model="form.region" placeholder="请选择缴费项目" >
              <el-option
                      v-for="item in optionList"
                      :key="item.value"
                      :label="item.type"
                      :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="缴费金额" :label-width="formLabelWidth" required>
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <el-form-item
                class="tree"
                label="部门"
                :label-width="formLabelWidth"
                required
        >
          <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </el-form-item>
        <el-form-item
                class="status"
                label="部分缴费"
                :label-width="formLabelWidth"
                required
        >
          <el-radio v-model="form.radio" label="on">全做</el-radio>
          <el-radio v-model="form.radio" label="off">选做</el-radio>
        </el-form-item>
        <el-form-item
                class="status"
                label="账号状态"
                :label-width="formLabelWidth"
                required
        >
          <el-radio v-model="form.radio" label="on">激活</el-radio>
          <el-radio v-model="form.radio" label="off">禁用</el-radio>
        </el-form-item>
        <el-form-item label="账单有效期" :label-width="formLabelWidth" required>
          <el-date-picker
                  v-model="form.value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
    // @ is an alias to /src
    import mHeader from "@/components/mHeader.vue";
    import container from "@/components/container.vue";
    export default {
        name: "Home",
        components: {
            mHeader,
            container
        },
        data () {
            return {
                // 文件上传弹框的数据开始
                visible: false,
                url: '',
                num: 0,
                successNum: 0,
                fileList: [],
                // 文件上传弹框的数据结束

                // 创建账单弹框开始
                dialogFormVisible: false, // 编辑弹框
                formLabelWidth: "120px",
                form: {
                    name: '',
                    region: '',
                    radio: '1',
                    value1: ''
                },
                optionList: [
                    {
                        type: '学费',
                        value: 1
                    },
                    {
                        type: '伙食费',
                        value: 2
                    }
                ],
                data: [{
                    label: '一级 1',
                    children: [{
                        label: '二级 1-1',
                        children: [{
                            label: '三级 1-1-1'
                        }]
                    }]
                }, {
                    label: '一级 2',
                    children: [{
                        label: '二级 2-1',
                        children: [{
                            label: '三级 2-1-1'
                        }]
                    }, {
                        label: '二级 2-2',
                        children: [{
                            label: '三级 2-2-1'
                        }]
                    }]
                }, {
                    label: '一级 3',
                    children: [{
                        label: '二级 3-1',
                        children: [{
                            label: '三级 3-1-1'
                        }]
                    }, {
                        label: '二级 3-2',
                        children: [{
                            label: '三级 3-2-1'
                        }]
                    }]
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                }
                // 创建账单弹框结束
            }
        },
        methods: {
            // 文件上传弹框的数据开始
            init (id) {
                this.url = this.$http.adornUrl(`/sys/oss/upload?token=${this.$cookie.get('token')}`)
                this.visible = true
            },
            // 上传之前
            beforeUploadHandle (file) {
                if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
                    this.$message.error('只支持jpg、png、gif格式的图片！')
                    return false
                }
                this.num++
            },
            // 上传成功
            successHandle (response, file, fileList) {
                this.fileList = fileList
                this.successNum++
                if (response && response.code === 0) {
                    if (this.num === this.successNum) {
                        this.$confirm('操作成功, 是否继续操作?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).catch(() => {
                            this.visible = false
                        })
                    }
                } else {
                    this.$message.error(response.msg)
                }
            },
            // 弹窗关闭时
            closeHandle () {
                this.fileList = []
                this.$emit('refreshDataList')
            },
            // 文件上传弹框的数据结束

            // 创建账单弹框数据开始
            handleNodeClick(data) {
                console.log(data);
            }
            // 创建账单弹框数据结束
        }
    };
</script>

<style lang="less" scoped>
  /*@import "~@/assets/style/element"; // 引入的是element组件公共样式，文件上传弹框的页面需要引入这个*/
  .home {
    max-height: 100vh;
    .mainContent {
      height: calc(100vh - 50px);
    }
  }
  // 文件上传的弹框样式开始
  /deep/.el-dialog__title {
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #303133;
  }
  .txt {
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #303133;
    .downTxt {
      color: #4A7DFA;
    }
  }
  .progress {
    .fileName {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #303133;
      margin-bottom: 10px;
      display: inline-block;
    }
    /deep/.el-progress {
      margin-bottom: 12px;
    }
  }
  // 文件上传的弹框样式结束

  // 创建账单弹框样式开始
  .adds {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
  }
  .wSlelct {
    width: 100%;
  }
  .addForm {
    width: 100%;
    /*height: 176px;*/
    background: #F7FAFE;
    border-radius: 4px;
    border: 2px dashed #CCE5FF;
    padding-right: 5px;
    box-sizing: border-box;
  }
  /deep/.el-dialog__body {
    padding: 10px 15px;
  }
  .tree {
    margin: 28px 0;
  }
  // 创建账单弹框样式结束
</style>
