<template>
<div class="wrap" :gutter="10">
  <index-header style="height:180px" v-show="headerVisible">
  </index-header>
  <el-row style="margin-top:10px" :gutter="10">
    <el-col :span="daySpanLenth" v-for="(item,index) in transactionList" :key="index" style="height:100%">
      <el-card style="height:100%" class="imgwrap">
        <div class="imgs">
          <img class="type-icon" src="../../assets/images/money.png" alt />
        </div>

        <!-- <div class="type"> -->
        <p class="typeName">{{item.type}}</p>
        <p class="num">{{item.num}}</p>
        <!-- </div> -->
      </el-card>
    </el-col>
  </el-row>
  <el-row style="margin-top:10px" :gutter="10">
    <el-col style="height:100%">
      <el-card class="trend-box">
        <div class="wrap">
          <span class="trend">交易走势</span>
          <div class="dayList">
            <span v-for="(item, index) in dayList" :key="index" :class="{active:activeIndex == index}" @click="changeActive(index)">
              {{item.text}}
            </span>
          </div>
          <!-- <span class="days" @click="loadEchartData('month', 6)">近6月</span>
          <span class="days" @click="loadEchartData('month', 3)">近3月</span>
          <span class="days" @click="loadEchartData('day', 30)">近30天</span>
          <span class="days" @click="loadEchartData('day', 7)">近7天</span> -->
        </div>
        <p style="margin-top: 20px;margin-bottom: 40px;font-size: 14px;font-weight: 700">
          <span class="totalLabel">汇总金额(元)：</span>
          <span class="totalData">{{payAmountStr}}</span>
          <span class="totalLabel">汇总笔数：</span>
          <span class="totalData">{{payCount}}</span>
          <!--<span class="days" @click="changeData('payCount')">交易笔数</span>
            <span class="days" @click="changeData('payAmount')">交易金额</span>-->
        </p>
        <div ref="productionSituation" style="height:270px"></div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>

<script>
import indexHeader from "../../components/indexHeader";
export default {
  name: "homePage", // 首页
  components: {
    indexHeader,
  },
  data() {
    return {
      transactionList: [],
      headerVisible: false,
      daySpanLenth: 8,
      payAmountStr: "0.00",
      payCount: 0,
      activeIndex: 0,
      dayList: [
          {
              text: '近7天',
              params: {
                  param1: 'day',
                  param2: 7
              }
          },
          {
              text: '近30天',
              params: {
                  param1: 'day',
                  param2: 30
              }
          },
          {
              text: '近3月',
              params: {
                  param1: 'month',
                  param2: 3
              }
          },
          {
          text: '近6月',
          params: {
            param1: 'month',
            param2: 6
          }
        },
      ],
      echartOptions: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.seriesName + "： " + params.data;
          }
        },
        legend: {
          data: ["交易金额", "交易笔数"]
        },
        color: ["#4a7dfa"],
        grid: {
          top: "30px",
          left: "1%",
          right: "2%",
          bottom: "0",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: [{
          type: "value",
          name: "交易金额",
        }, {
          type: "value",
          name: "交易笔数",
        }, ],
        series: [{
            data: [],
            type: "line",
            areaStyle: {},
            itemStyle: {
              normal: {
                areaStyle: {
                  type: 'default'
                },
                color: '#3146f4'
              }
            },
            name: '交易金额',
            yAxisIndex: 0, // 相对应的坐标轴
            smooth: true,
            lineStyle: {
              normal: {
                color: '#4A7DFA',
                width: 2
              }
            }
          },
          {
            data: [],
            type: "line",
            areaStyle: {},
            name: '交易笔数',
            itemStyle: {
              normal: {
                areaStyle: {
                  type: 'default'
                },
                color: '#33ccf4'
              }
            },
            yAxisIndex: 1, // 相对应的坐标轴
            smooth: true,
            lineStyle: {
              normal: {
                color: '#33ccf4',
                width: 2
              }
            }
          },

        ],
      }
    };
  },
  created() {
    let curUser = localStorage.getItem('curUserInfo123');
    curUser = JSON.parse(curUser)
    if (curUser.userType != 'SUPER') {
      this.headerVisible = true;
    }
    this.$request.statisticsToday({})
      .then((res) => {
        if (res.code == 0) {
          this.transactionList = res.data;
          if (this.transactionList.length == 4) {
            this.daySpanLenth = 6;
          }
        }
      });
    this.loadEchartData();
  },
  mounted() {
    this.productionSituations();
  },
  methods: {
    loadEchartData() {
      this.$request.statisticsEchart({
        dateType: this.dayList[this.activeIndex].params.param1,
        num: this.dayList[this.activeIndex].params.param2,
      }).then((res) => {
        if (res.code == 0) {
          this.echartOptions.xAxis.data = res.data.xlist;
          this.echartOptions.series[0].data = res.data.amountList;
          this.echartOptions.series[1].data = res.data.countList;
          this.payAmountStr = res.data.totalAmount;
          this.payCount = res.data.totalCount;
          this.productionSituations();
        }
      });
    },
    changeActive(index) {
      this.activeIndex = index
      this.loadEchartData()
    },
    changeData(type) {
      if ('payAmount' == type) {
        alert(type)
      } else if ('payCount' == type) {
        alert(type)
      }
    },
    productionSituations() {
      let myChart = this.$echarts.init(this.$refs.productionSituation);
      window.addEventListener("resize", () => {
        window.onresize = myChart.resize();
      });
      myChart.setOption(this.echartOptions);
    },
  },
};
</script>

<style lang="less" scoped>


.wrap {
  width: 100%;
  min-width: 1300px;
}

.days {
  width: 75px;
  height: 32px;
  border: 1px solid #4A7DFA;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #4A7DFA;
  line-height: 19px;
  cursor: pointer;
  float: right;
}

.totalData {
  margin-right: 30px;
}

.mcName {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  color: #606266;
  line-height: 20px;
}

.type {
  float: right;
  margin: 10px 40px 0 0;

  .typeName {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .num {
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #4a7dfa;
    font-size: 20px;
  }
}

.imgwrap /deep/.el-card__body {
  display: flex;
  align-items: center;
  position: relative;

  .imgs {
    padding: 15px;
    background-color: #F7F7F7;
    border-radius: 40px;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .typeName {
    font-size: 18px;
    font-weight: bold;
    color: #303133;
    margin-left: 20px;
    padding-right: 10px;
  }

  .num {
    font-size: 24px;
    font-weight: bold;
    color: #4A7DFA;
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
}

.trend {
  font-size: 17px;
  font-weight: bold;
  border-left: 4px solid blue;
  line-height: 21px;
  margin: 0;
  padding: 0 10px;
}

/deep/.user-box[data-v-0221009f] {
  position: relative !important;
  top: -7px !important;
}

.heavy {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #4a7dfa;
  border-radius: 50%;
  margin-right: 7px;
}

.trend-box {
  height: 100%;

  .charts-box {
    width: 100%;
    height: 76%;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 5px;

    .dayList {

      span {
        display: inline-block;
        text-align: center;
        border: 1px solid #4A7DFA;
        border-right: 0;
        width: 65px;
        font-size: 14px;
        color: #4A7DFA;
        cursor: pointer;

        &:nth-last-child(1) {
          border-right: 1px solid #4A7DFA;
          border-radius: 0 5px 5px 0;
        }

        &:nth-child(1) {
          border-radius: 5px 0 0 5px;
        }

        &.active {
          background-color: #4A7DFA;
          color: #fff;
        }
      }
    }
  }
}
</style>
