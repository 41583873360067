import {
	BaseURL
} from '@/utils/config.js'

const URL = {
    test: `https://api.apiopen.top/getJoke?page=1&count=2&type=video`,
	code: `${BaseURL.url}/index/api/code`, // 获取验证码
	rbacuser: `${BaseURL.url}/charge/admin/rbacuser`,
	menuRoles: `${BaseURL.url}/charge/admin/rbacrole/menu/roles`, // 菜单列表
	rbacroleList: `${BaseURL.url}/charge/admin/rbacrole/list`, // 查询角色列表
	rbacroleSave: `${BaseURL.url}/charge/admin/rbacrole/save`, // 添加角色
	rbacroleLogout: `${BaseURL.url}/charge/admin/rbacuser/Logout`, // 退出登录
	rbacroleDelete: `${BaseURL.url}/charge/admin/rbacrole/delete`, // 删除角色
	rbacroleUpdate: `${BaseURL.url}/charge/admin/rbacrole/update`, // 修改角色
	rbacroleInfo: `${BaseURL.url}/charge/admin/rbacrole/info`, // 角色详情
	rbacroleFindCheckMenuTree: `${BaseURL.url}/charge/admin/rbacrole/findCheckMenuTree`, // 菜单树


	rbacuserList: `${BaseURL.url}/charge/admin/rbacuser/list`, // 查询账号列表
	rbacuserSave: `${BaseURL.url}/charge/admin/rbacuser/save`, // 添加账号
	rbacuserDelete: `${BaseURL.url}/charge/admin/rbacuser/delete`, // 删除账号
	rbacuserUpdate: `${BaseURL.url}/charge/admin/rbacuser/update`, // 修改账户数据
	rbacuserUrl: `${BaseURL.url}/charge/admin/rbacuser/url`, // 修改账户数据
	rbacuserUpdatePassword: `${BaseURL.url}/charge/admin/rbacuser/updatePassword`, // 修改密码
	rbacuserDetail: `${BaseURL.url}/charge/admin/rbacuser/detail`, // 查询账号详细信息
    rbacuserExpireAlert: `${BaseURL.url}/charge/admin/rbacuser/expireAlert`, // 账号过期弹窗提醒
	login: `${BaseURL.url}/charge/admin/rbacuser/login`, // login
	loginByToken: `${BaseURL.url}/charge/admin/rbacuser/rjiiswiie`, // login
    getVeifyCode: `${BaseURL.url}/charge/admin/rbacuser/getVeifyCode`, // login
	publicKey: `${BaseURL.url}/charge/admin/rbacuser/random`, // 获取公钥
    restPassword: `${BaseURL.url}/charge/admin/rbacuser/restPassword`, // 获取公钥
    updateStatus: `${BaseURL.url}/charge/admin/rbacuser/updateStatus`, // 更新状态

	dictRole: `${BaseURL.url}/charge/admin/dict/role`, //  下拉框角色列表
	dictUserType: `${BaseURL.url}/charge/admin/dict/userType`, //  下拉框用户类型
	dictOrg: `${BaseURL.url}/charge/admin/dict/org`, //  下拉框渠道列表
	dictMch: `${BaseURL.url}/charge/admin/dict/mch`, //  下拉框商户列表
    deptTree: `${BaseURL.url}/charge/admin/dict/deptTree`, //  部门树
    deptGetOrgOrMchList: `${BaseURL.url}/charge/admin/dict/getOrgOrMchList`, //  部门树
    dictValiRule: `${BaseURL.url}/charge/admin/dict/valiRule`, //  校验规则
    dictBillItemList: `${BaseURL.url}/charge/admin/dict/billItemList`, //  校验规则
    getAreaList: `${BaseURL.url}/charge/admin/dict/getAreaList`, //  地区树

	rbacuserInfo: `${BaseURL.url}/charge/admin/rbacuser/info`, // 查询账号详细信息
    mchbillitemList: `${BaseURL.url}/charge/admin/mchbillitem/list`, // 查询缴费项目列表
    mchbillitemUpdate: `${BaseURL.url}/charge/admin/mchbillitem/update`, // 修改缴费项目
    mchbillitemSave: `${BaseURL.url}/charge/admin/mchbillitem/save`, // 新增缴费项目
    mchbillitemDelete: `${BaseURL.url}/charge/admin/mchbillitem/delete`, // 删除缴费项目
    bashMchList: `${BaseURL.url}/charge/admin/basemch/list`, // 商户列表
    bashMchUpdate: `${BaseURL.url}/charge/admin/basemch/update`, // 更新商户
    bashMchXuqi: `${BaseURL.url}/charge/admin/basemch/xuqi`, // 续期
    bashMchSave: `${BaseURL.url}/charge/admin/basemch/save`, // 新增商户
    bashMchDelete: `${BaseURL.url}/charge/admin/basemch/delete`, // 删除商户
    bashMchInfo: `${BaseURL.url}/charge/admin/basemch/info`,
    bashMchExport: `${BaseURL.url}/charge/admin/basemch/export`, // 删除商户
    bashMchBindUrl: `${BaseURL.url}/charge/admin/basemch/bindUrl`, 
    bashMchShangbaoUrl: `${BaseURL.url}/charge/admin/basemch/shangbao`, 
    getExpireDate: `${BaseURL.url}/charge/admin/basemch/getExpireDate`, // 删除商户
	merchantUserBranch:`${BaseURL.url}/charge/admin/mchuser/dept/tree`,//商户管理端查询部门树
    bashSpecialMchList: `${BaseURL.url}/charge/admin/specialMch/list`, // 商户列表
    bashSpecialMchAreaTree: `${BaseURL.url}/charge/admin/specialMch/areaTree`, // 商户列表
    bashSpecialMchSave: `${BaseURL.url}/charge/admin/specialMch/save`, // 商户列表
    bashSpecialMchUpdate: `${BaseURL.url}/charge/admin/specialMch/update`, // 商户列表


    getOrgListByLoginUser: `${BaseURL.url}/charge/admin/baseorg/getLoginUserBaseOrgList`,//获取登陆用户对应渠道列表
    getSceneListByOrgId: `${BaseURL.url}/charge/admin/basepayscene/getSceneListByOrgId`,//根据渠道id获取缴费场景列表
    getUserSceneList: `${BaseURL.url}/charge/admin/basepayscene/getUserSceneList`,//根据渠道id获取缴费场景列表

	//渠道管理
    bashOrgList: `${BaseURL.url}/charge/admin/baseorg/list`,
    bashOrgSave: `${BaseURL.url}/charge/admin/baseorg/addBaseOrg`,  //新增渠道
    bashOrgUpdate: `${BaseURL.url}/charge/admin/baseorg/update`,  //修改渠道
    bashOrgXuqi: `${BaseURL.url}/charge/admin/baseorg/xuqi`,  //续期
    bashOrgInfo: `${BaseURL.url}/charge/admin/baseorg/info`,  //渠道详情
    bashOrgDelete: `${BaseURL.url}/charge/admin/baseorg/delete`,  //删除
    bashOrgExport: `${BaseURL.url}/charge/admin/baseorg/export`,  //导出

    //缴费记录
    mchPayOrderList: `${BaseURL.url}/charge/admin/mchpayorder/list`,
    mchPayOrderSpecialOrg: `${BaseURL.url}/charge/admin/mchpayorder/specialOrg`,
    mchPayOrderListByMch: `${BaseURL.url}/charge/admin/mchpayorder/listByMch`,
    mchPayOrderRefundOrder: `${BaseURL.url}/charge/admin/mchpayorder/refundOrder`,
    mchPayOrderBatchRefund: `${BaseURL.url}/charge/admin/mchpayorder/batchRefund`,
    mchPayOrderExport: `${BaseURL.url}/charge/admin/mchpayorder/export`,
    mchPayOrderExportByMch: `${BaseURL.url}/charge/admin/mchpayorder/exportByMch`,
    mchPayOrderGenPdf: `${BaseURL.url}/charge/admin/mchpayorder/genPdf`,
    mchPayOrderInfo: `${BaseURL.url}/charge/admin/mchpayorder/info`,
    payOrderTableHead: `${BaseURL.url}/charge/admin/mchpayorder/payOrderTableHead`,
    payOrderUpdateRemark: `${BaseURL.url}/charge/admin/mchpayorder/updateRemark`,

    //退款记录
    mchRefundOrderList: `${BaseURL.url}/charge/admin/mchrefundorder/list`,
    mchRefundOrderExport: `${BaseURL.url}/charge/admin/mchrefundorder/export`,

    //缴费场景
    paySceneList: `${BaseURL.url}/charge/admin/basepayscene/list`,
    paySceneSave: `${BaseURL.url}/charge/admin/basepayscene/save`,
    paySceneUpdate: `${BaseURL.url}/charge/admin/basepayscene/update`,
    paySceneInfo: `${BaseURL.url}/charge/admin/basepayscene/info`,
    paySceneDelete: `${BaseURL.url}/charge/admin/basepayscene/delete`,

	//banner管理
    bannerList: `${BaseURL.url}/charge/admin/basebanner/list`,
    bannerSave: `${BaseURL.url}/charge/admin/basebanner/save`,
    bannerUpdate: `${BaseURL.url}/charge/admin/basebanner/update`,
    bannerInfo: `${BaseURL.url}/charge/admin/basebanner/info`,
    bannerDelete: `${BaseURL.url}/charge/admin/basebanner/delete`,

	//文件管理
	updatePic:`${BaseURL.url}/charge/admin/file/upload`,

    //商户设置
    baseMchConfConf:`${BaseURL.url}/charge/admin/basemch/conf/conf`,
    baseMchConfChangePayType:`${BaseURL.url}/charge/admin/basemch/conf/changePayType`,
    baseMchConfUpdateOnBlur:`${BaseURL.url}/charge/admin/basemch/conf/updateOnBlur`,
    baseMchConfAddField:`${BaseURL.url}/charge/admin/basemch/conf/addField`,
    baseMchConfEditField:`${BaseURL.url}/charge/admin/basemch/conf/editField`,
    baseMchConfDeleteField:`${BaseURL.url}/charge/admin/basemch/conf/deleteField`,
    baseMchConfAddSelfItem:`${BaseURL.url}/charge/admin/basemch/conf/addSelfItem`,
    baseMchConfUpdateSelfItem:`${BaseURL.url}/charge/admin/basemch/conf/updateSelfItem`,
    baseMchConfDeleteSelfItem:`${BaseURL.url}/charge/admin/basemch/conf/deleteSelfItem`,
    baseMchConfDefaultValueList:`${BaseURL.url}/charge/admin/basemch/conf/defaultValueList`,
    baseMchConfDefaultValueSave:`${BaseURL.url}/charge/admin/basemch/conf/defaultValueSave`,
    baseMchConfSaveOrUpdateOrgMchKey:`${BaseURL.url}/charge/admin/basemch/conf/saveOrUpdateOrgMchKey`,
    baseMchConfGetKeyInfo:`${BaseURL.url}/charge/admin/basemch/conf/getKeyInfo`,
    baseMchSaveHeadUrl:`${BaseURL.url}/charge/admin/basemch/conf/saveHeadUrl`,
    baseMchUserMarkSave:`${BaseURL.url}/charge/admin/basemch/conf/userMarkSave`,


    // 用户管理（商户端）
    mchUserDeptSave:`${BaseURL.url}/charge/admin/mchuser/dept/save`,
    mchUserDeptUpdate:`${BaseURL.url}/charge/admin/mchuser/dept/update`,
    mchUserDeptDelete:`${BaseURL.url}/charge/admin/mchuser/dept/delete`,
    mchUserDeptTree:`${BaseURL.url}/charge/admin/mchuser/dept/tree`,
    mchUserSave:`${BaseURL.url}/charge/admin/mchuser/save`,
    mchUserUpdate:`${BaseURL.url}/charge/admin/mchuser/update`,
    mchUserChangeStatus:`${BaseURL.url}/charge/admin/mchuser/changeStatus`,
    mchUserInfo:`${BaseURL.url}/charge/admin/mchuser/info`,
    mchUserInfobyUserNo:`${BaseURL.url}/charge/admin/mchuser/infobyUserNo`,
    mchUserTableHead:`${BaseURL.url}/charge/admin/mchuser/tableHead`,
    mchUserDelete:`${BaseURL.url}/charge/admin/mchuser/delete`,
    mchUserImport:`${BaseURL.url}/charge/admin/mchuser/importMchUser`,
    mchUserMoveDeptId:`${BaseURL.url}/charge/admin/mchuser/moveDeptId`,
    merchantUserList:`${BaseURL.url}/charge/admin/mchuser/mchUserList`,//商户管理端查询list
    merchantUserList2:`${BaseURL.url}/charge/admin/mchuser/list`,//商户管理端查询list
    merchantUserExportMchUser:`${BaseURL.url}/charge/admin/mchuser/exportMchUser`,
    merchantUserDownTemplate:`${BaseURL.url}/charge/admin/mchuser/downTemplate`,
    merchantUserDeptTemplate:`${BaseURL.url}/charge/admin/mchuser/dept/downTemplate`,
    merchantUserDeptImport:`${BaseURL.url}/charge/admin/mchuser/dept/importDept`,

    //账单批次
    mchbillpackList:`${BaseURL.url}/charge/admin/mchbillpack/list`,
    mchbillpackSave:`${BaseURL.url}/charge/admin/mchbillpack/save`,
    mchbillpackChangeStartStatus:`${BaseURL.url}/charge/admin/mchbillpack/changeStartStatus`,
    mchbillpackChangePartStatus:`${BaseURL.url}/charge/admin/mchbillpack/changePartStatus`,
    mchbillpackDelete:`${BaseURL.url}/charge/admin/mchbillpack/delete`,
    mchbillpackDownTemplate:`${BaseURL.url}/charge/admin/mchbillpack/downTemplate`,
    mchbillpackImportBill:`${BaseURL.url}/charge/admin/mchbillpack/importBill`,
    mchbillpackCuijiao:`${BaseURL.url}/charge/admin/mchbillpack/cuijiao`,
    mchbillpackSetStartEndTime:`${BaseURL.url}/charge/admin/mchbillpack/setStartEndTime`,
    mchbillpackUpdatePackName:`${BaseURL.url}/charge/admin/mchbillpack/updatePackName`,

    //账单明细
    mchbillTableHead:`${BaseURL.url}/charge/admin/mchbill/billTableHead`,
    mchbillList:`${BaseURL.url}/charge/admin/mchbill/list`,
    mchbillPackList:`${BaseURL.url}/charge/admin/mchbill/getPackList`,
    mchbillMchInfo:`${BaseURL.url}/charge/admin/mchbill/mch/info`,
    mchbillSave:`${BaseURL.url}/charge/admin/mchbill/save`,
    mchbillInfo:`${BaseURL.url}/charge/admin/mchbill/info`,
    mchbillUpdate:`${BaseURL.url}/charge/admin/mchbill/update`,
    mchbillDelete:`${BaseURL.url}/charge/admin/mchbill/delete`,
    mchbillWriteoff:`${BaseURL.url}/charge/admin/mchbill/writeoff`,
    mchbillCuijiao:`${BaseURL.url}/charge/admin/mchbill/cuijiao`,
    mchbillExport:`${BaseURL.url}/charge/admin/mchbill/export`,

    //渠道设置
    orgConfConf:`${BaseURL.url}/charge/admin/baseorg/conf/conf`,
    orgConfSaveLogoUrl:`${BaseURL.url}/charge/admin/baseorg/conf/saveLogoUrl`,
    orgConfKeyinfo:`${BaseURL.url}/charge/admin/baseorg/conf/keyinfo`,
    orgConfKeysave:`${BaseURL.url}/charge/admin/baseorg/conf/keysave`,
    orgConfWechatSave:`${BaseURL.url}/charge/admin/baseorg/conf/wechatSave`,
    wechatInfo:`${BaseURL.url}/charge/admin/baseorg/conf/wechatInfo`,
    logoInfo:`${BaseURL.url}/charge/admin/baseorg/conf/logoInfo`,
    updateLogoInfo:`${BaseURL.url}/charge/admin/baseorg/conf/updateLogoInfo`,
    clearOrgWechatConf:`${BaseURL.url}/charge/admin/baseorg/conf/clearOrgWechatConf`,

    //统计相关
    statisticsToday:`${BaseURL.url}/charge/admin/mchpaystatistics/today`,
    statisticsEchart:`${BaseURL.url}/charge/admin/mchpaystatistics/echart`,//折线图数据
    statisticsList:`${BaseURL.url}/charge/admin/mchpaystatistics/list`,//折线图数据
    statisticsExport:`${BaseURL.url}/charge/admin/mchpaystatistics/export`,
    statisticsTotal:`${BaseURL.url}/charge/admin/mchpaystatistics/total`,//折线图数据

    //用户管理-渠道端
    orgMchUserList:`${BaseURL.url}/charge/admin/org/mch/user/list`,
    orgMchUserExport:`${BaseURL.url}/charge/admin/org/mch/user/export`,

    //续费记录
    mchXuqiRecordList:`${BaseURL.url}/charge/admin/mchXuqiRecord/list`,
    mchXuqiRecordExport:`${BaseURL.url}/charge/admin/mchXuqiRecord/export`,
}

const ORGMANAGEURL = {
	getOrgList: `${BaseURL.url}/charge/admin/baseorg/list`,  //获取渠道列表
	getOrgListByLoginUser: `${BaseURL.url}/charge/admin/baseorg/getLoginUserBaseOrgList`,  //获取登陆用户对应渠道列表
	addOrg: `${BaseURL.url}/charge/admin/baseorg/addBaseOrg`,  //新增渠道
	updateOrg: `${BaseURL.url}/charge/admin/baseorg/update`, //修改渠道
	getOrgById: function (id) {
		return `${BaseURL.url}/charge/admin/baseorg/info/` + id;
	},  //根据id获取渠道新消息
	deleteOrgById: function (id) {
		return `${BaseURL.url}/charge/admin/baseorg/delete/` + id;
	},  //根据id获取渠道新消息
}
export {
	URL,
	ORGMANAGEURL
}
