<template>
  <div>
    <div class="wrap">
      <index-header style="margin: 0; padding: 0;margin-bottom: 10px;width: 100%;min-width: 1200px">
        <div class="mcName mtb20">
          <span >密钥设置：</span>
          <span @click="setFormClick" class="keySettingDescBotton" style="color: #4065E0">{{keySettingDesc}}</span>
        </div>
      </index-header>
      <el-card style="width: 100%;min-width: 1200px">
        <div class="contentBox" >
          <el-tabs v-model="mchConf.payType" @tab-click="handleClick">
            <el-tab-pane label="账单缴费" name="1">
              <!-- <div class="top">
                <div class="line"></div>
                <p class="name">账单缴费</p>
              </div> -->
              <div class="content">
                <p>
                  <span>人员标识</span>
                  <el-tooltip effect="light" content="若开启，则使用用户编号+用户名称的方式来获取账单，开启之后再次关闭需要删除重复的用户" placement="right"><i class="el-icon-question"/></el-tooltip>
                  <el-switch v-model="mchConf.userMark" style="margin-left:20px" active-value="on" inactive-value="off" @change=changeUserMark()></el-switch>
                </p>
                <el-table :data="mchConf.reNameList" border style="width: 100%">
                  <el-table-column prop="name" label="字段名称" width="180"></el-table-column>
                  <el-table-column prop="reName" label="重命名" width="180">
                    <template slot-scope="scope">
                      <el-input
                              v-model.trim="scope.row.reName"
                              @blur="handch(scope.row.name,scope.row.reName)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="rule" label="校验规则">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.rule"  @change="changeRule(scope.row.name,scope.row.rule)" placeholder="请选择">
                        <el-option
                          v-for="item in valiRule"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="table-top">
                <el-alert title="新增自定义字段会展示在用户信息和账单信息，请谨慎添加，最多支持8个" type="warning" show-icon></el-alert>
                <!--<button class="btn" @click="dialogEditVisible = true">新增字段</button>-->
                <button class="btn" @click="addBillField">新增字段</button>
              </div>
              <div class="content">
                <el-table :data="mchConf.mchBillFieldDTOList" border style="width: 100%">
                  <el-table-column prop="fieldId" label="字段id" width="120"></el-table-column>
                  <el-table-column prop="fieldName" label="字段名称">
                    <template slot-scope="scope">
                      <el-input
                              v-model.trim="scope.row.fieldName"
                              @blur="changeBillField(scope.row.fieldId,scope.row,1)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="required" label="是否必填">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.required"  @change="changeBillField(scope.row.fieldId,scope.row,1)" placeholder="请选择">
                        <el-option
                                v-for="item in requiredList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="valiRule" label="校验规则">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.valiRule"  @change="changeBillField(scope.row.fieldId,scope.row,1)" placeholder="请选择">
                        <el-option
                                v-for="item in valiRule"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="fieldSort" label="优先级">
                    <template slot-scope="scope">
                      <el-input
                              v-model.trim="scope.row.fieldSort"
                              @blur="changeBillFieldSort(scope.row.fieldId,scope.row,1)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column align="right" label="操作">
                    <template slot-scope="scope">
                      <el-button
                        type="danger"
                        plain
                        size="small"
                        @click="deletBillField(scope.row)"
                      >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="自助缴费" name="0" >
              <!-- <div class="top">
                <div class="line"></div>
                <p class="name">自助缴费</p>
              </div> -->
              <div class="table-top">
                <el-alert title="支持预设置缴费项目和金额" type="warning" show-icon></el-alert>
                <button class="btn" @click="addSelfItem">新增项目</button>
              </div>
              <el-table :data="mchConf.mchSelfItemDTOList" border style="width: 100%">
                <el-table-column prop="itemId" label="ID" width="120"></el-table-column>
                <el-table-column prop="itemName" label="缴费项目">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.itemName"
                            @blur="changeSelfItem(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="payAmountStr" label="缴费金额">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.payAmountStr"
                            @blur="changeSelfItem(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="itemStatus" label="状态">
                  <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.itemStatus"
                            active-value="on"
                            inactive-value="off"
                            active-color="#4A7DFA"
                            inactive-color="#E4E7ED"
                            @change=changeSelfItem(scope.row)>
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="itemSort" label="优先级">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.itemSort"
                            @blur="changeSelfItem(scope.row)"
                            placeholder="数字越小越靠前"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column align="right" label="操作">
                  <template slot-scope="scope">
                  <el-button
                    type="danger"
                    plain
                    size="small"
                    @click="deleteSelfItemClick(scope.row)"
                  >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-top">
                <el-alert title="新增自定义字段会展示在用户信息和账单信息，请谨慎添加，最多支持8个" type="warning" show-icon></el-alert>
                <button class="btn" @click="addSelfField">新增字段</button>
              </div>
              <el-table :data="mchConf.mchSelfFieldDTOList" border style="width: 100%">
                <el-table-column prop="fieldId" label="字段id" width="120"></el-table-column>
                <el-table-column prop="fieldName" label="字段名称">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.fieldName"
                            @blur="changeSelfField(scope.row.fieldId,scope.row,2)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="required" label="是否必填">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.required"  @change="changeSelfField(scope.row.fieldId,scope.row,2)" placeholder="请选择">
                      <el-option
                              v-for="item in requiredList"
                              :key="item.code"
                              :label="item.name"
                              :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="valiRule" label="校验规则">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.valiRule"  @change="changeSelfField(scope.row.fieldId,scope.row,2)" placeholder="请选择">
                      <el-option
                              v-for="item in valiRule"
                              :key="item.code"
                              :label="item.name"
                              :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="fieldSort" label="优先级">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.fieldSort"
                            @blur="changeSelfField(scope.row.fieldId,scope.row,2)"
                            placeholder="数字越小越靠前"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="fieldSort" label="用户填写方式">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.inputType"  @change="changeSelfField(scope.row.inputType,scope.row,2)" placeholder="请选择">
                        <el-option
                                v-for="item in inputTypeList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                    </el-select>
                    </template>
                  </el-table-column>
                <el-table-column align="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                            type="danger"
                            plain
                            size="small"
                            @click="deletSelfField(scope.row)"
                    >删除</el-button>
                    <el-button
                            type="primary"
                            plain
                            size="small"
                            @click="setDefaultValue(scope.row)"
                            v-show="scope.row.inputType != 'input'"
                    >设置值</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="账单和自助缴费" name="2">
              <div class="top">
                <div class="line"></div>
                <p class="name">账单缴费</p>
              </div>
              <p style="margin-left:20px">
                <span>人员标识</span>
                <el-tooltip effect="light" content="若开启，则使用用户编号+用户名称的方式来获取账单" placement="right"><i class="el-icon-question"/></el-tooltip>
                <el-switch v-model="mchConf.userMark" style="margin-left:20px" active-value="on" inactive-value="off" @change=changeUserMark()></el-switch>
              </p>
              <div class="content">
                <el-table :data="mchConf.reNameList" border style="width: 100%">
                  <el-table-column prop="name" label="字段名称" width="180"></el-table-column>
                  <el-table-column prop="reName" label="重命名" width="180">
                    <template slot-scope="scope">
                      <el-input
                              v-model.trim="scope.row.reName"
                              @blur="handch(scope.row.name,scope.row.reName)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="rule" label="校验规则">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.rule"  @change="changeRule(scope.row.name,scope.row.rule)" placeholder="请选择">
                        <el-option
                                v-for="item in valiRule"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="table-top">
                <el-alert title="新增自定义字段会展示在用户信息和账单信息，请谨慎添加，最多支持8个" type="warning" show-icon></el-alert>
                <!--<button class="btn" @click="dialogEditVisible = true">新增字段</button>-->
                <button class="btn" @click="addBillField">新增字段</button>
              </div>
              <div class="content">
                <el-table :data="mchConf.mchBillFieldDTOList" border style="width: 100%">
                  <el-table-column prop="fieldId" label="字段id" width="120"></el-table-column>
                  <el-table-column prop="fieldName" label="字段名称">
                    <template slot-scope="scope">
                      <el-input
                              v-model.trim="scope.row.fieldName"
                              @blur="changeBillField(scope.row.fieldId,scope.row,1)"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="required" label="是否必填">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.required"  @change="changeBillField(scope.row.fieldId,scope.row,1)" placeholder="请选择">
                        <el-option
                                v-for="item in requiredList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="valiRule" label="校验规则">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.valiRule"  @change="changeBillField(scope.row.fieldId,scope.row,1)" placeholder="请选择">
                        <el-option
                                v-for="item in valiRule"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column align="right" label="操作">
                    <template slot-scope="scope">
                      <el-button
                              type="danger"
                              plain
                              size="small"
                              @click="deletBillField(scope.row)"
                      >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="top">
                <div class="line"></div>
                <p class="name">自助缴费</p>
              </div>
              <div class="table-top">
                <el-alert title="支持预设置缴费项目和金额" type="warning" show-icon></el-alert>
                <button class="btn" @click="addSelfItem">新增项目</button>
              </div>
              <el-table :data="mchConf.mchSelfItemDTOList" border style="width: 100%">
                <el-table-column prop="itemId" label="ID" width="120"></el-table-column>
                <el-table-column prop="itemName" label="缴费项目">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.itemName"
                            @blur="changeSelfItem(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="payAmountStr" label="缴费金额">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.payAmountStr"
                            @blur="changeSelfItem(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="itemStatus" label="状态">
                  <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.itemStatus"
                            active-value="on"
                            inactive-value="off"
                            active-color="#4A7DFA"
                            inactive-color="#E4E7ED"
                            @change=changeSelfItem(scope.row)>
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column prop="itemSort" label="优先级">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.itemSort"
                            @blur="changeSelfItem(scope.row)"
                            placeholder="数字越小越靠前"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column align="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                            type="danger"
                            plain
                            size="small"
                            @click="deleteSelfItemClick(scope.row)"
                    >删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-top">
                <el-alert title="新增自定义字段会展示在用户信息和账单信息，请谨慎添加，最多支持8个" type="warning" show-icon></el-alert>
                <button class="btn" @click="addSelfField">新增字段</button>
              </div>
              <el-table :data="mchConf.mchSelfFieldDTOList" border style="width: 100%">
                <el-table-column prop="fieldId" label="字段id" width="120"></el-table-column>
                <el-table-column prop="fieldName" label="字段名称">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.fieldName"
                            @blur="changeSelfField(scope.row.fieldId,scope.row,2)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="required" label="是否必填">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.required"  @change="changeSelfField(scope.row.fieldId,scope.row,2)" placeholder="请选择">
                      <el-option
                              v-for="item in requiredList"
                              :key="item.code"
                              :label="item.name"
                              :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="valiRule" label="校验规则">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.valiRule"  @change="changeSelfField(scope.row.fieldId,scope.row,2)" placeholder="请选择">
                      <el-option
                              v-for="item in valiRule"
                              :key="item.code"
                              :label="item.name"
                              :value="item.code"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="fieldSort" label="优先级">
                  <template slot-scope="scope">
                    <el-input
                            v-model.trim="scope.row.fieldSort"
                            @blur="changeSelfField(scope.row.fieldId,scope.row,2)"
                            placeholder="数字越小展示越靠前(默认为0)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="fieldSort" label="用户填写方式">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.inputType"  @change="changeSelfField(scope.row.inputType,scope.row,2)" placeholder="请选择">
                        <el-option
                                v-for="item in inputTypeList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                    </el-select>
                    </template>
                </el-table-column>
                <el-table-column align="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                            type="danger"
                            plain
                            size="small"
                            @click="deletSelfField(scope.row)"
                    >删除</el-button>
                    <el-button
                            type="primary"
                            plain
                            size="small"
                            @click="setDefaultValue(scope.row)"
                            v-show="scope.row.inputType != 'input'"
                    >设置值</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>
    </div>

    <!-- 设置/查看密钥弹框-->
    <el-dialog title="设置密钥" :visible.sync="dialogSetVisible">
      <el-form :model="setForm">
          <el-form-item label="签名类型" :label-width="formLabelWidth" required v-show="setForm.gatewayType == 'hsty'">
              <el-select v-model="setForm.signType"  placeholder="请选择">
                  <el-option
                          v-for="item in signTypeList"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                  ></el-option>
              </el-select> 
          </el-form-item>
          <el-form-item label="md5密钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'MD5'">
              <el-input v-model="setForm.md5Key" type="textarea" autocomplete="off" placeholder="请输入md5密钥"></el-input>
          </el-form-item>
          <el-form-item label="RSA私钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'RSA' || setForm.gatewayType != 'hsty'">
            <el-input v-model.trim="setForm.outPriKey" type="textarea" autocomplete="off" placeholder="请输入商户RSA私钥"></el-input>
          </el-form-item>
          <el-form-item label="RSA公钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'RSA' || setForm.gatewayType != 'hsty'">
            <el-input v-model.trim="setForm.outPubKey" type="textarea" autocomplete="off" placeholder="请输入商户RSA公钥"></el-input>
          </el-form-item>
          <el-form-item label="平台公钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'RSA' || setForm.gatewayType != 'hsty'">
            <el-input v-model.trim="setForm.platPubKey" type="textarea" autocomplete="off" placeholder="请输入平台公钥"></el-input>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveSetForm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="设置默认值" :visible.sync="dialogSetDefaultValueVisible">
      <div class="table-top">
        <el-button type="primary" @click="addDefaultValue">新增</el-button>
      </div>
      <el-table :data="defaultValueList" border style="width: 100%" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="defaultId" @cell-click="cellClickAction">
        <el-table-column prop="defaultId" label="ID" width="210"></el-table-column>
        <el-table-column prop="defaultValue" label="默认值">
          <template slot-scope="scope">
            <el-input
                    v-model.trim="scope.row.defaultValue"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作">
          <template slot-scope="scope">
          <el-button
                  type="danger"
                  plain
                  size="small"
                  @click="deleteDefaultValue(scope.row)"
          >删除<i class="el-icon-delete"  @click="deleteDefaultValue(scope.row)"></i></el-button>
          <el-button
                  type="default"
                  plain
                  size="small"
                  @click="moveUp(scope.$index, scope.row)"
          >上移<i class="el-icon-top icon_class"/></el-button>
          <el-button
                  type="default"
                  plain
                  size="small"
                  @click="moveDown(scope.$index, scope.row)"
          >下移<i class="el-icon-bottom icon_class"/></el-button>
          <el-button
                  type="default"
                  plain
                  size="small"
                  @click="addSub(scope.$index, scope.row)"
                  v-show="setDefaultValueInputType == 'select2' && !scope.row.parentId"
          >添加子选项</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetDefaultValueVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDefaultValue">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 删除账单缴费弹框-->
    <el-dialog class="del" title="提示" :visible.sync="dialogDeleteVisible" width="30%">
      <div class="tip">
        <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt />
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteBillFiledSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除自助缴费项目弹框-->
    <el-dialog class="del" title="提示" :visible.sync="dialogDeleteSelfProject" width="30%">
      <div class="tip">
        <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt />
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteSelfProject = false">取 消</el-button>
        <el-button type="primary" @click="deleteSelfSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除自助缴费字段弹框-->
    <el-dialog class="del" title="提示" :visible.sync="dialogDeleteSelfFieldVisible" width="30%">
      <div class="tip">
        <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt />
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteSelfFieldVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSelfFiledSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import indexHeader from "../../../components/indexHeader";

export default {
  name: "business", // 商户设置
  components: {
    indexHeader,
  },
  data() {
    return {
      dialogSetVisible: false, // 密钥设置弹框
      dialogEditVisible: false, // 账单缴费编辑/修改弹框
      dialogDeleteVisible: false, // 账单缴费删除弹框
      dialogSelEditVisible: false, //  自助缴费编辑/修改弹框
      dialogDeleteSelfProject: false, // 自助缴费项目删除弹框
      dialogEditSelfField: false, // 自助缴费字段编辑/修改弹框
      dialogDeleteSelfField: false, // 自助缴费字段删除弹框
        dialogDeleteSelfFieldVisible: false, // 自助缴费字段删除弹框
        dialogSetDefaultValueVisible: false, // 自助缴费字段删除弹框
        dialogSubSetDefaultValueVisible: false,
        dialogSubSetDefaultValueTitle:'',
      // dialogActivation: false, // 激活弹框
      // 设置密钥弹框绑定数据
      setForm: {
          outPriKey: "", // 支付通道
          outPubKey: "", // 加密类型
          platPubKey: "" // MD5密钥
      },
      formLabelWidth: "120px",
      payType: "",
        keySettingDesc:"",
      // 账单缴费新增字段信息
        requiredList:[
            {
                code: 0,
                name: "否"
            },
            {
                code: 1,
                name: "是"
            }
        ],
        inputTypeList:[
            {
                code: "input",
                name: "由用户输入"
            },
            {
                code: 'select1',
                name: "提供下拉项由用户选择"
            },
            {
                code: 'select2',
                name: "提供二级下拉项由用户选择"
            }
        ],
        //reNameList:[],
        mchConf:{
            payType:"",
            reNameList:[],
            mchBillFieldDTOList:[],
            mchSelfItemDTOList:[],
            mchSelfFieldDTOList:[],
            userMark:"off",
        },
        signTypeList:[
            {code:"MD5", name:"MD5"},
            {code:"RSA", name:"RSA"}
        ],

        defaultValueList:[],
        setDefaultValueFieldId:"",
        setDefaultValueInputType:"",
        valiRule:[],
        deleteBillFieldItem: {},
        deleteSelfFieldItem: {},
        deleteSelfItem: {},
        addBillFieldindex: 1,
        addSelftItemindex: 1,
        addSelfFieldindex: 1,
        addSelfFieldDefaultIndex: 1,
    };
  },
  created() {
      this.loadData();
  },
  methods: {
      changeUserMark(value){
        var that = this;
        var origalValue;
        if(value == "on"){
          origalValue = "off";
        }else {
          origalValue = "on";
        }
        this.$request.baseMchUserMarkSave({userMark:this.mchConf.userMark}).then((res) => {
            if(res.code != 0){
              that.mchConf.userMark=origalValue;
            }
        });
      },
      loadData() {
          this.$request
              .baseMchConfConf({
              })
              .then((res) => {
                  this.mchConf.userMark=res.data.userMark;
                  this.mchConf.payType=res.data.payType+"";
                  this.mchConf.reNameList=res.data.reNameList;
                  this.mchConf.mchBillFieldDTOList=res.data.mchBillFieldDTOList;
                  this.mchConf.mchSelfItemDTOList=res.data.mchSelfItemDTOList;
                  this.mchConf.mchSelfFieldDTOList=res.data.mchSelfFieldDTOList;
              });
          this.$request
              .dictValiRule({
              })
              .then((res) => {
                  this.valiRule=res.data;
              });
          this.$request.baseMchConfGetKeyInfo({})
              .then((res) => {
                  if(res.data.outPriKey){
                      this.keySettingDesc="查看";
                  }else{
                      this.keySettingDesc="设置";
                  }
              });
      },
       moveUp(index, row) {
        var that = this
        if(!row.parentId){//父节点
          var rowIndex = 0;
          this.defaultValueList.forEach(function(item, index, arr){
              if(item.defaultId && row.defaultId == item.defaultId){
                  rowIndex = index;
              }
          })
          if (rowIndex > 0) {
            const upDate = that.defaultValueList[rowIndex - 1]
            that.defaultValueList.splice(rowIndex - 1, 1)
            that.defaultValueList.splice(rowIndex, 0, upDate)
          } else {
            this.$message({message:"已经是第一条，不可上移",type: "warn",});
          }
        }else{
          this.defaultValueList.forEach(function(item, index, arr){
              if(item.defaultId && row.parentId == item.defaultId){
                  var rowIndex = 0;
                  var children = item.children;
                  children.forEach(function(child, index, arr){
                    if(child.defaultId && row.defaultId == child.defaultId){
                        rowIndex = index;
                    }
                  })
                  if (rowIndex > 0) {
                    const upDate = children[rowIndex - 1]
                    children.splice(rowIndex - 1, 1)
                    children.splice(rowIndex, 0, upDate)
                  } else {
                    that.$message({message:"已经是第一条，不可上移",type: "warn",});
                  }
              }
          })
          
        }
      },
      // 下移
      moveDown(index, row) {
        var that = this
        if(!row.parentId){//父节点
          var rowIndex = 0;
          this.defaultValueList.forEach(function(item, index, arr){
              if(item.defaultId && row.defaultId == item.defaultId){
                  rowIndex = index;
              }
          })
          if ((rowIndex+1) == that.defaultValueList.length) {
            this.$message({message:"已经是最后一条，不可下移",type: "warn",});
          } else {
            const downDate = that.defaultValueList[rowIndex + 1]
            that.defaultValueList.splice(rowIndex + 1, 1)
            that.defaultValueList.splice(rowIndex, 0, downDate)
          }
        }else {
          this.defaultValueList.forEach(function(item, index, arr){
              if(item.defaultId && row.parentId == item.defaultId){
                  var rowIndex = 0;
                  var children = item.children;
                  children.forEach(function(child, index, arr){
                    if(child.defaultId && row.defaultId == child.defaultId){
                        rowIndex = index;
                    }
                  })
                  if ((rowIndex+1) == children.length) {
                    that.$message({message:"已经是最后一条，不可下移",type: "warn",});
                  } else {
                    const downDate = children[rowIndex + 1]
                    children.splice(rowIndex + 1, 1)
                    children.splice(rowIndex, 0, downDate)
                  }
              }
          })
        }
      },
      cellClickAction(row, column, cell, event){
        if(event.target.nodeName === "INPUT" || event.target.nodeName ==="TEXTAREA"){
            event.target.focus();
        }
      },
      addSub(index, row) {
        var arr1 = [];
        var that = this;
        this.defaultValueList.forEach(function(item, index, arr){
            if(item.defaultId && row.defaultId == item.defaultId){
              if(!item.children){
                item.children = [];
              }
              var defaultId = that.generateRandomString(10);
              item.children.push({defaultId:defaultId, defaultValue: "",  parentId: row.defaultId});
            }
            arr1.push(item)
        })
        this.defaultValueList = arr1;
        console.log(JSON.stringify(arr1))
        // alert(JSON.stringify(row))
        // this.dialogSubSetDefaultValueTitle = row.defaultValue;
        // this.dialogSubSetDefaultValueVisible = true;
      },
      saveSetForm(){
          this.$request.baseMchConfSaveOrUpdateOrgMchKey(this.setForm)
              .then((res) => {
                if(res.code == 0){
                  this.$message({message:"成功",type: "success",});
                  this.dialogSetVisible = false;
                  this.keySettingDesc="查看";
                }
              });
      },

      generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        
        return Array.from({ length })
          .reduce((result) => result + characters.charAt(Math.floor(Math.random() * characters.length)), '');
      },
      setFormClick(){
          this.dialogSetVisible = true;
          this.$request.baseMchConfGetKeyInfo({})
              .then((res) => {
                  this.setForm = res.data;
              });
      },
    handleClick(tab, event) {
        this.$request.baseMchConfChangePayType({payType:tab.name})
            .then((res) => {
            });
    },
      handch(name, value){
        if(name == "用户编号"){
            this.$request.baseMchConfUpdateOnBlur({userNoRename:value }) .then((res) => {});
        }else if(name == "用户名称"){
              this.$request.baseMchConfUpdateOnBlur({userNameRename:value }) .then((res) => {});
          }
      },
      changeRule(name, value){
        if(name == "用户编号"){
            this.$request.baseMchConfUpdateOnBlur({userNoVali:value }) .then((res) => {});
        }else if(name == "用户名称"){
              this.$request.baseMchConfUpdateOnBlur({userNameVali:value }) .then((res) => {});
          }
      },
      addBillField(){
          var billField = {};
          billField.fieldName="";
          billField.required=1;
          billField.valiRule="3";
          let number = this.addBillFieldindex++;
          billField.index=number;
          this.mchConf.mchBillFieldDTOList.push(billField)
      },
      addSelfField(){
          var billField = {};
          billField.fieldName="";
          billField.required=1;
          billField.valiRule="3";
          billField.fieldSort=0;
          let number = this.addSelfFieldindex++;
          billField.index=number;
          this.mchConf.mchSelfFieldDTOList.push(billField)
      },
      changeSelfField(id, row, type){
          row.fileType = type;
          if (!row.fieldId) {
              var arr1 = [];
              this.$request.baseMchConfAddField(row).then((res) => {
                  if (res.code == 0) {
                      this.mchConf.mchSelfFieldDTOList.forEach(function (item, index, arr) {
                          if (item.index && row.index == item.index) {
                              item.fieldId = res.data;
                          }
                          arr1.push(item);
                      })
                      this.mchConf.mchSelfFieldDTOList = arr1;
                  }
              });
          } else {
              this.$request.baseMchConfEditField(row).then((res) => {
              });
          }
      },
      changeBillField(id, row, type){
          row.fileType = type;
          if(!row.fieldId){
              var arr1 = [];
              this.$request.baseMchConfAddField(row) .then((res) => {
                  if(res.code==0){
                      this.mchConf.mchBillFieldDTOList.forEach(function(item, index, arr){
                            if(item.index && row.index == item.index){
                                item.fieldId = res.data;
                            }
                          arr1.push(item);
                      })
                      this.mchConf.mchBillFieldDTOList = arr1;
                  }
              });
          }else{
              this.$request.baseMchConfEditField(row) .then((res) => {});
          }
      },
      setDefaultValue(row){
          this.defaultValueList=[];
          this.setDefaultValueFieldId=row.fieldId;
          this.setDefaultValueInputType=row.inputType;
          this.dialogSetDefaultValueVisible = true;
          this.$request.baseMchConfDefaultValueList(row) .then((res) => {
              if(res.code == 0){
                  this.defaultValueList = res.data;
              }
          });
      },
      deleteDefaultValue(row){
          var arr1 = [];

          if(!row.parentId){//父节点
              this.defaultValueList.forEach(function(item, index, arr){
                  if(item.defaultId && row.defaultId == item.defaultId){
                  }else{
                      arr1.push(item);
                  }
              })
          }else{
              this.defaultValueList.forEach(function(item, index, arr){
                if(item.defaultId && row.parentId == item.defaultId){
                    var children = item.children;
                    var newChildren = [];
                    children.forEach(function(child, index, arr){
                      if(child.defaultId == row.defaultId){
                      }else {
                        newChildren.push(child);
                      }
                    })
                    item.children = newChildren;
                }
                arr1.push(item);
              })
          }
          this.defaultValueList = arr1;
      },
      addDefaultValue(row){
          var defaultValue = {};
          defaultValue.defaultId=this.generateRandomString(10);
          defaultValue.defaultValue="";
          defaultValue.children = [];
          this.defaultValueList.push(defaultValue);
      },
      saveDefaultValue(){
          var reqData = {};
          reqData.mchFieldDefaultDTOList=this.defaultValueList;
          reqData.fieldId=this.setDefaultValueFieldId
          this.$request.baseMchConfDefaultValueSave(reqData) .then((res) => {
              if(res.code == 0){
                  this.dialogSetDefaultValueVisible = false;
                  this.defaultValueList = [];
                  this.setDefaultValueFieldId='';
              }
          });
      },
      deletSelfField(row){
          if(!row.fieldId){
              var arr1 = [];
              this.mchConf.mchSelfFieldDTOList.forEach(function(item, index, arr){
                  if(item.index && row.index == item.index){
                  }else{
                      arr1.push(item);
                  }
              })
              this.mchConf.mchSelfFieldDTOList = arr1;
              return;
          }
          this.dialogDeleteSelfFieldVisible=true;
          this.deleteSelfFieldItem = row;
      },
      deletBillField(row){
          if(!row.fieldId){
              var arr1 = [];
              this.mchConf.mchBillFieldDTOList.forEach(function(item, index, arr){
                  if(item.index && row.index == item.index){
                  }else{
                      arr1.push(item);
                  }
              })
              this.mchConf.mchBillFieldDTOList = arr1;
              return;
          }
          this.dialogDeleteVisible=true;
          this.deleteBillFieldItem = row;
      },
      deleteSelfFiledSure(){
          this.$request.baseMchConfDeleteField(this.deleteSelfFieldItem) .then((res) => {
              if(res.code == 0){
                  this.$request
                      .baseMchConfConf({
                      })
                      .then((res2) => {
                          this.mchConf.mchSelfFieldDTOList=res2.data.mchSelfFieldDTOList;
                      });
                  this.$util.clearObj(this.deleteSelfFieldItem);
                  this.dialogDeleteSelfFieldVisible=false;
              }
          });
      },
      deleteBillFiledSure(){
          this.$request.baseMchConfDeleteField(this.deleteBillFieldItem) .then((res) => {
                if(res.code == 0){
                    this.$request
                        .baseMchConfConf({
                        })
                        .then((res2) => {
                            this.mchConf.mchBillFieldDTOList=res2.data.mchBillFieldDTOList;
                        });
                    this.$util.clearObj(this.deleteBillFieldItem);
                    this.dialogDeleteVisible=false;
                }
          });
      },
      addSelfItem(){
          var selftItem = {};
          selftItem.itemName="";
          selftItem.payAmountStr="";
          selftItem.itemStatus="on";
          let number = this.addSelftItemindex++;
          selftItem.index=number;
          this.mchConf.mchSelfItemDTOList.push(selftItem)
      },
      changeSelfItem(row){
          if(!row.itemId){
              var arr1 = [];
              this.$request.baseMchConfAddSelfItem(row) .then((res) => {
                  if(res.code==0){
                      this.mchConf.mchSelfItemDTOList.forEach(function(item, index, arr){
                          if(item.index && row.index == item.index){
                              item.itemId = res.data;
                          }
                          arr1.push(item);
                      })
                      this.mchConf.mchSelfItemDTOList = arr1;
                  }
              });
          }else{
              this.$request.baseMchConfUpdateSelfItem(row) .then((res) => {
                  /*var arr1 = [];
                  if(res.code==0){
                      this.mchConf.mchSelfItemDTOList.forEach(function(item, index, arr){
                          if(item.index && row.index == item.index){
                              item.itemId = res.data;
                          }
                          arr1.push(item);
                      })
                      this.mchConf.mchSelfItemDTOList = arr1;
                  }*/
              });
          }
      },
      deleteSelfItemClick(row){
          if(!row.itemId){
              var arr1 = [];
              this.mchConf.mchSelfItemDTOList.forEach(function(item, index, arr){
                  if(item.index && row.index == item.index){
                  }else{
                      arr1.push(item);
                  }
              })
              this.mchConf.mchSelfItemDTOList = arr1;
              return;
          }
          this.dialogDeleteSelfProject=true;
          this.deleteSelfItem = row;
      },
      deleteSelfSure(){
          this.$request.baseMchConfDeleteSelfItem(this.deleteSelfItem) .then((res) => {
              if(res.code == 0){
                  this.$request
                      .baseMchConfConf({
                      })
                      .then((res2) => {
                          this.mchConf.mchSelfItemDTOList=res2.data.mchSelfItemDTOList;
                      });
                  this.$util.clearObj(this.deleteSelfItem);
                  this.dialogDeleteSelfProject=false;
              }
          });
      },

    searchSelect(value) {
      // console.log(value, '选择的值')
      this.setForm.Encrypted = value;
    },
    deleteSure() {
      console.log("删除");
    },

  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/style/element";
/deep/ .el-row {
  margin: 0;
  padding: 0;
  height: initial;
  margin-bottom: 10px;
  line-height: 30px !important;
}
/deep/.el-tabs__item.is-active {
  font-size: 20px;
  font-weight: bold;
}
/deep/.el-tabs__header {
    margin: 0 20px 15px;
}
.color1 {
  color: #4065e0;
}
.wrap {
  /*width: 100%;*/
  background-color: #f5f5f5;
  .mcName {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #606266;
    cursor: pointer;
  }
  .contentBox {
    width: 100%;
    background-color: #fff;
    /deep/.el-tabs {
      .top {
        display: flex;
        align-items: center;
        .line {
          width: 4px;
          height: 26px;
          background: #4065e0;
          margin-right: 15px;
        }
        .name {
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #303133;
        }
      }
    }
    /deep/.el-alert__closebtn {
      margin-left: 20px;
      position: relative;
      top: 0;
      right: 0;
    }
    /deep/.el-alert--warning {
      background-color: #ffffff;
      padding-left: 20px;
    }
    .table-top {
      display: flex;
      justify-content: space-between;
      .btn {
        width: 88px;
        height: 32px;
        background: #4a7dfa;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        border: 0;
        outline: none;
        margin-right: 20px;
        cursor: pointer;
        margin-bottom: 5px;
      }
    }
    /deep/.el-table {
      margin-bottom: 40px;
    }
    .keySettingDescBotton{
      font-family: MicrosoftYaHei;
      color: red;
      cursor:pointer;
    }
  }
}
</style>