<template>
  <div style="height:100%;">
    <el-row style="height:9%">
      <el-col :span="24" style="height:100%;margin-bottom:10px">
        <el-card class="box-card" style="height:100%">
          <el-row :gutter="30" class="input-box">
            <el-col :span="6">
              <label class="label">姓名：</label>
              <el-input placeholder="请输入姓名" size="small" class="title-input"></el-input>
            </el-col>
            <el-col :span="6">
              <label class="label">用户编号：</label>
              <el-input placeholder="请输入用户编号" size="small" class="title-input"></el-input>
            </el-col>
            <el-col :span="12">
              <label class="label">创建时间：</label>
              <el-date-picker
                v-model="value2"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                size="small"
                class="title-input"
              ></el-date-picker>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="height:89%">
      <el-col :span="24" style="height:100%">
        <el-card class="box-card" style="height:100%">
          <el-row>
            <el-col :span="24">
              <div class="header2">
                <el-button icon="el-icon-search" type="success" plain size="mini">查询</el-button>
                <el-button icon="el-icon-refresh" type="primary" plain size="mini">重置</el-button>
                <el-button
                  icon="el-icon-plus"
                  type="warning"
                  plain
                  size="mini"
                  style="float:right;margin-right:10px"
                  @click="drawer = true"
                >新增</el-button>
              </div>
              <div class="el-table-bg">
                <el-table
                  :data="tableData"
                  border
                  style="font-size: 13px"
                  :header-cell-style="rowClass"
                  :row-style="{height:'45px'}"
                  :cell-style="{padding:'0px'}"
                >
                  <el-table-column align="center" prop="date" label="缴费场景" width="150"></el-table-column>
                  <el-table-column align="center" prop="name" label="Logo" width="120"></el-table-column>
                  <el-table-column align="center" prop="province" label="备注" width="120"></el-table-column>
                  <el-table-column align="center" prop="city" label="状态" width="120">
                    <template>
                      <el-switch
                        v-model="statusValue"
                        active-color="#13ce66"
                        disabled
                        inactive-color="#ddd"
                      ></el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="address" label="创建人" width="300"></el-table-column>
                  <el-table-column align="center" prop="zip" label="创建时间" width="120"></el-table-column>
                  <el-table-column align="center" fixed="right" label="操作" width="210">
                    <template slot-scope="scope">
                      <el-button
                        type="success"
                        plain
                        size="small"
                        icon="el-icon-edit"
                        @click="handleClick(scope.row)"
                      >修改</el-button>
                      <el-button type="danger" plain size="small" icon="el-icon-delete">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- <drawer></drawer> -->
              <div class="footer">
                <el-pagination
                  :current-page="tableData.page"
                  :page-sizes="[100, 200, 300, 400]"
                  :page-size="100"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="tableData.current"
                ></el-pagination>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 新增 -->
    <el-drawer class="drawer-wrap" :visible.sync="drawer" :with-header="false">
      <el-row>
        <el-col :span="24">
          <div class="drawer-title__box">创意账单/详情</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="drawer-item__btn">
            <el-button icon="el-icon-plus" size="small" type="primary" @click="addItem">添加项目</el-button>
            <transition name="active">
              <el-button
                icon="el-icon-delete"
                size="small"
                type="danger"
                @click="deleteTableItem(item, index)"
              >删除项目</el-button>
            </transition>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="tableFroms">
          <el-scrollbar style="height:100%">
            <el-form :rules="formRules" :model="tableForm" label-width="110px" size="mini">
              <!-- 固定项目 -->
              <el-form-item label="账单名称" prop="billName">
                <el-input v-model="tableForm.billName" placeholder="请输入账单名称"></el-input>
              </el-form-item>
              <el-form-item label="缴费项目" prop="paymentItems">
                <el-select
                  v-model="tableForm.paymentItems"
                  clearable
                  placeholder="请选择缴费项目"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="缴费金额(元)" prop="paymentAmount">
                <el-input v-model="tableForm.paymentAmount" placeholder="请输入缴费金额(元)"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input type="textarea" v-model="tableForm.remarks"></el-input>
              </el-form-item>
            </el-form>
            <!-- 动态增加项目 -->
            <transition-group name="active">
              <div v-for="(item, index) in tableForm.dynamicItem" :key="index">
                <el-form :rules="formRules" :model="tableForm" label-width="110px" size="mini">
                  <el-form-item label="缴费项目" prop="paymentItems">
                    <el-select
                      v-model="tableForm.paymentItems"
                      clearable
                      placeholder="请选择缴费项目"
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="缴费金额(元)" prop="paymentAmount">
                    <el-input v-model="tableForm.paymentAmount" placeholder="请输入缴费金额(元)"></el-input>
                  </el-form-item>
                  <el-form-item label="备注" prop="remarks">
                    <el-input type="textarea" v-model="tableForm.remarks"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </transition-group>
            <el-form :rules="formRules" :model="tableForm" label-width="110px" size="mini">
              <el-form-item label="部门" prop="department">
                <el-tree
                  :data="data"
                  show-checkbox
                  node-key="id"
                  :default-expanded-keys="[2, 3]"
                  :default-checked-keys="[5]"
                  :props="defaultProps"
                ></el-tree>
              </el-form-item>
              <el-form-item label="部分缴费" prop="aymentBox">
                <el-radio v-model="aymentRadio" label="1" style="padding-left:15px">全缴</el-radio>
                <el-radio v-model="aymentRadio" label="2">选缴</el-radio>
              </el-form-item>
              <el-form-item label="账单状态" prop="billingStatus">
                <el-radio v-model="billingRadio" label="1" style="padding-left:15px">激活</el-radio>
                <el-radio v-model="billingtRadio" label="2">禁用</el-radio>
              </el-form-item>
              <el-form-item label="账单有效期" prop="billingTimes">
                <el-date-picker
                  v-model="billingTimesValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width:100%"
                ></el-date-picker>
              </el-form-item>
            </el-form>
            <el-alert title="温馨提示：开始时间账单会自动激活..." type="warning" show-icon></el-alert>
          </el-scrollbar>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="drawer-item__btn form-submission">
            <el-button type="primary" size="small">确定</el-button>
            <el-button type="info" size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import drawer from "@/components/drawer.vue";
export default {
  components: { drawer },
  data() {
    return {
      billingTimesValue: "",
      billingRadio: "1",
      aymentRadio: "1",
      data: [
        {
          id: 1,
          label: "小羊有限公司",
          children: [
            {
              id: 2,
              label: "商业BU",
              children: [
                {
                  id: 5,
                  label: "研发中心",
                },
                {
                  id: 6,
                  label: "产品中心",
                  children: [
                    { id: 7, label: "基础产品组" },
                    { id: 8, label: "行业产品组" },
                  ],
                },
              ],
            },
            {
              id: 3,
              label: "金融BU",
              children: [],
            },
            {
              id: 4,
              label: "海外BU",
              children: [],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      tableValue: "",
      options: [
        { value: "选项1", label: "学费" },
        { value: "选项2", label: "书本费" },
      ],
      drawer: false, //抽屉开关
      tableForm: {
        billName: "",
        paymentAmount: "",
        paymentItems: "",
        remarks: "",
        department: "",
        aymentBox: "",
        billingStatus: "",
        billingTimes: "",
        dynamicItem: [],
      },
      formRules: {
        billName: [
          { required: true, message: "请输入账单名称", trigger: "blur" },
        ],
        paymentAmount: [
          { required: true, message: "请输入缴费金额", trigger: "blur" },
        ],
        paymentItems: [{ required: true, message: "请选择缴费项目" }],
        department: [{ required: true }],
        aymentBox: [{ required: true }],
        billingStatus: [{ required: true }],
        billingTimes: [
          { required: true, message: "请选择时间", trigger: "blur" },
        ],
        remarks: [{ required: false }],
      },

      statusValue: Boolean,
      value2: "",
      merchant: true,
      channel: false,
      filterText: "",
      tree: [
        {
          label: "",
          children: [],
        },
      ],
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    currentChange(res) {
      console.log("currentChange", res);
    },

    rowClass({ row, rowIndex }) {
      return "background:rgba(242, 242, 242, 1);font-size: 13px;";
    },

    addItem() {
      this.tableForm.dynamicItem.push({
        name: "",
        phone: "",
      });
    },
    deleteTableItem(item, index) {
      this.tableForm.dynamicItem.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.header2 {
  margin: 15px 0 10px 10px;
}
.el-table-bg {
  margin: 6px 10px 10px 10px;
}
.footer {
  position: fixed;
  bottom: 20px;
  right: 15px;
}

.el-card {
  height: calc(100vh - 132px);
}
/deep/.el-card__header {
  white-space: nowrap;
  padding: 18px 13px;
}
/deep/.el-card__body {
  padding: 0;
}
.el-table th,
.el-table tr {
  font-size: 13px;
}

/deep/.el-table__header tr,
/deep/.el-table__header th {
  padding: 0;
  height: 40px;
}
/deep/.el-main {
  overflow: hidden !important;
}
.input-box {
  padding: 10px 10px 10px 15px;
  box-sizing: border-box;
  .el-col-6 {
    width: 25%;
    white-space: nowrap;
    margin-bottom: 12px;
    .label {
      font-size: 14px;
      float: left;
      line-height: 32px;
    }
    .title-input {
      width: 73%;
      float: right;
    }
  }
}
.input-box {
  padding: 10px 10px 10px 15px;
  box-sizing: border-box;
  .el-col-12 {
    white-space: nowrap;
    margin-bottom: 12px;
    .label {
      font-size: 14px;
      float: left;
      line-height: 32px;
    }
    .title-input {
      width: 86.5%;
      float: right;
    }
  }
}
/deep/.el-table td,
.el-table th {
  text-align: center;
}

.drawer-wrap {
  /deep/.el-drawer__body {
    padding: 10px;
  }

  /deep/.el-alert__closebtn {
    margin-left: 20px;
    position: relative;
    top: 0;
    right: 0;
  }
  /deep/.el-alert--warning {
    height: 30px;
    position: relative;
    top: -15px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0);
  }
  .drawer-item__btn {
    float: right;
    margin-bottom: 20px;
  }
  .drawer-title__box {
    border-left: 4px solid #4a7dfa;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px !important;
    padding-left: 10px;
    margin-top: 6px;
  }
  .upload-demo {
    width: 100%;
    /deep/.el-upload {
      width: 100%;
    }
    /deep/.el-upload-dragger {
      width: 100%;
    }
  }
  .form-submission {
    position: fixed;
    bottom: 5px;
    right: 15px;
  }
}
</style>