<template>
    <el-card style="width: 100%;min-width: 1200px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
            <div style="height: 47px;">
                <el-form-item label="订单时间" >
                    <el-date-picker
                            v-model="queryForm.createTime"
                            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%;height: 32px"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="缴费场景" >
                    <el-select v-model="queryForm.mchType" clearable placeholder="商户类型" class="search-input">
                        <el-option
                                v-for="item in mchTypeList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商户名称">
                    <el-input v-model="queryForm.mchName" placeholder="商户名称" class="search-input"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div class="op-botton">
            <el-button size="small" style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" @click="createExport">导出</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                    :data="records"
                    border
                    :stripe="true">
                <el-table-column
                        prop="id"
                        label="订单ID"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="mchName"
                        label="商户名称"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="mchNo"
                        show-overflow-tooltip
                        label="商户编号">
                </el-table-column>
                <el-table-column
                        prop="mchTypeStr"
                        show-overflow-tooltip
                        label="商户类型">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="订单时间">
                </el-table-column>
                <el-table-column
                        prop="xuqiCount"
                        show-overflow-tooltip
                        label="订单周期">
                </el-table-column>
                <el-table-column
                        prop="oriExpireDate"
                        show-overflow-tooltip
                        label="原到期时间">
                </el-table-column>
                <el-table-column
                        prop="newExpireDate"
                        show-overflow-tooltip
                        label="新到期时间">
                </el-table-column>
                <el-table-column
                        prop="createUserName"
                        show-overflow-tooltip
                        label="操作人">
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
        </div>
    </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    import Clipboard from "clipboard";
    export default {
        name: "mch", // 商户管理
        data() {
            return {
                limit: "2",
                page: "1",
                records: [],
                choose7: false,
                choose30: false,
                choose180: false,
                mchTypeList:[{code:'default',name:'默认商户'},{code:'channel',name:'渠道大商户'}],
                queryForm: {
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
            };
        },
        created() {
            this.loadData();
            this.$request
                .getOrgListByLoginUser({
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.orgItem = res.data;
                    }
                });
            this.$request
                .getUserSceneList({
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.payAllScene = res.data;
                        this.payAllScene.unshift({"sceneId":"","sceneName":"全部"});
                    }
                });
        },
        computed: {},
        methods: {
            loadData(page) {
                this.$request
                    .mchXuqiRecordList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            createExport() {
                this.$request
                    .mchXuqiRecordExport({
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
        },
    };
</script>

<style lang="less" scoped>
    .choose-days {
        width: 67px;
        height: 32px;
        margin-top: 3px;
        margin-left: 9px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 32px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
    .search-input  /deep/.el-input__inner {
        height: 32px!important;
        width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    .vue-treeselect {
        width: 200px;
        line-height: 32px;
    }
    .el-range-editor.el-input__inner {
        display: inline-flex;
        align-items: center;
        padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

    .op-botton{
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: right;
    }
    /deep/  .el-table th, .el-table tr {
        background-color: #F5F5F5;
        color: #909399;
    }
    /deep/ .el-table td {
        padding: 0px 0;
        height: 50px;
    }
    /deep/ .el-table th {
        padding: 0px 0;
        height: 50px;
    }
    .del .tip .tip-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
</style>
