import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import homePage from '../views/container/homePage.vue'
import systemAccount from '../views/container/system/account.vue'
import systemRole from '../views/container/system/role.vue'
import systemBusiness from '../views/container/system/business.vue'
import baseUser from '../views/container/base/user.vue'
import payItem from '../views/container/base/payItem.vue'
import orgManagement from './../views/container/base/orgManagement.vue'
import mch from './../views/container/base/mch.vue'
import specialMch from './../views/container/base/specialMch.vue'
import payOrder from '../views/container/pay/payOrder.vue'
import refundOrder from './../views/container/pay/refundOrder.vue'
import payScene from './../views/container/base/payScene.vue'
import banner from './../views/container/base/banner.vue'
import paymentScenario from './../views/container/base/paymentScenario.vue'
import billBatch from './../views/container/base/billBatch.vue'
import bill from './../views/container/base/bill.vue'
import orgSetting from './../views/container/system/orgSetting.vue'
import statistics from './../views/container/pay/statistics.vue'
import payOrderForMch from './../views/container/pay/payOrderForMch.vue'
import mchXuqiRecord from './../views/container/base/mchXuqiRecord.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home/home', // 首页
                component: homePage
            },
            {
                path: 'system/account',
                name: 'home/system/account', // 账户管理
                component: systemAccount
            },
            {
                path: 'system/role',
                name: 'home/system/role', // 角色管理
                component: systemRole
            },
            {
                path: 'system/business',
                name: 'home/system/business', // 商户设置
                component: systemBusiness
            },
            {
                path: 'base/user',
                name: 'home/base/user', // 用户管理（商户端）
                component: baseUser
            },
            {
                path: 'base/orgManagement',
                name: 'home/base/orgManagement', // 渠道管理
                component: orgManagement
            },
            {
                path: 'base/payItem',
                name: 'home/base/payItem',
                component: payItem
            },
            {
                path: 'base/mch',
                name: 'home/base/mch',
                component: mch
            }
            ,
            {
                path: 'base/specialMch',
                name: 'home/base/specialMch',
                component: specialMch
            }
            ,
            {
                path: 'pay/payOrder',
                name: 'home/pay/payOrder',
                component: payOrder
            }
            ,
            {
                path: 'pay/refundOrder',
                name: 'home/pay/refundOrder',
                component: refundOrder
            }
            ,
            {
                path: 'base/payScene',
                name: 'home/pay/payScene',
                component: payScene
            }
            ,
            {
                path: 'base/banner',
                name: 'home/base/banner',
                component: banner
            },
            {
                path: 'base/paymentScenario',
                name: 'home/base/paymentScenario', // 缴费场景
                component: paymentScenario
            },
            {
                path: 'base/billBatch',
                name: 'home/base/billBatch', // 账单批次
                component: billBatch
            },
            {
                path: 'base/bill',
                name: 'home/base/bill', // 账单明细
                component: bill
            },
            {
                path: 'system/orgSetting',
                name: 'home/system/orgSetting', // 渠道设置
                component: orgSetting
            },
            {
                path: 'pay/statistics',
                name: 'home/pay/statistics', // 日结统计
                component: statistics
            },
            {
                path: 'pay/payOrderForMch',
                name: 'home/pay/payOrderForMch', //缴费记录（商户）
                component: payOrderForMch
            },
            {
                path: 'base/mchXuqiRecord',
                name: 'home/base/mchXuqiRecord', 
                component: mchXuqiRecord
            }
        ]
    },
    {
        path: '/login',
        name: 'login', // 登录
        component: () => import(/* webpackChunkName: "main" */ '../views/login.vue')
},
{
    path: '/about',
        name: 'about', // 登录
    component: () => import(/* webpackChunkName: "main" */ '../views/about.vue')
}
]

const router = new VueRouter({
    routes
})

export default router
