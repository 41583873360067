import { render, staticRenderFns } from "./orgManagement.vue?vue&type=template&id=110e4b81&scoped=true&"
import script from "./orgManagement.vue?vue&type=script&lang=js&"
export * from "./orgManagement.vue?vue&type=script&lang=js&"
import style0 from "./orgManagement.vue?vue&type=style&index=0&id=110e4b81&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110e4b81",
  null
  
)

export default component.exports