import JSEncrypt from 'jsencrypt/bin/jsencrypt';
import request from '@/utils/request.js'
function isFullObj(obj) {
	return Object.keys(obj).filter(key => !obj[key]).length == 0
}

function clearObj(obj, option={}) {
	return Object.keys(obj).forEach(key => {
		if (typeof (obj[key]) == 'number' && option.number !== undefined) {
			obj[key] = option.number
		} else {
			obj[key] = null
		}
	})
}

function encryptedData(publicKey, data) {
	//new一个对象
	let encrypt = new JSEncrypt();
	//设置公钥
	encrypt.setPublicKey(publicKey);
	//password是要加密的数据,此处不用注意+号,因为rsa自己本身已经base64转码了,不存在+,全部是二进制数据
	let result = encrypt.encrypt(data);
	return result;
}

function decryptData(privateKey, data) {
	// 新建JSEncrypt对象
	let decrypt = new JSEncrypt();
	// 设置私钥
	decrypt.setPrivateKey(privateKey);
	// 解密数据
	let result = decrypt.decrypt(data);
	return result;
}

function formatDate(date, fmt) {
	if(!fmt){
        fmt = "yyyy-MM-dd HH:mm:ss";
	}
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}
function padLeftZero (str) {
    return ('00' + str).substr(str.length);
}
function cloneObj(obj) {
    var newObj = {};
    if (obj instanceof Array) {
        newObj = [];
    }
    for (var key in obj) {
        var val = obj[key];
        //newObj[key] = typeof val === 'object' ? arguments.callee(val) : val; //arguments.callee 在哪一个函数中运行，它就代表哪个函数, 一般用在匿名函数中。
        newObj[key] = typeof val === 'object' ? cloneObj(val): val;
    }
    return newObj;
};

export default {
	isFullObj,
	clearObj,
	encryptedData,
	decryptData,
    formatDate,
    padLeftZero,
    cloneObj
}