const isProduction = true
const testHostname = '127.0.0.1' // 测试环境
const productionHostname = '127.0.0.1' // 生产环境
const protocol = 'http:'
const url = isProduction ? productionHostname : testHostname
const port = isProduction ? '9000' : '9000'
const host = `${url}${port?(':' + port):''}`
const BaseURL = {
	//url: `${protocol}//${host}`,
	url: '',
	updateUrl: 'https://wbt-app.oss-cn-shanghai.aliyuncs.com/manifest.json',
	androidDownloadUrl: 'https://wbt-app.oss-cn-shanghai.aliyuncs.com/wbt.apk',
	iosDownloadUrl: 'https://wbt-app.oss-cn-shanghai.aliyuncs.com/wbt.plist',
}
const Config = {
	cachekey: 'Store' + (isProduction ? '1' : '0'),
	appName: 'appName',
	downloadUrl: BaseURL.url + '/app/h5/#/pages/download',
	shareUrl: BaseURL.url + '/app/h5/#/pages/account/registered',
	style: {
		navColor: '#ffffff',
		navBgColor: '#151936',
		navBgColor2:"#FF3F88",
		tabColor: '#366AF3',
		tabBgColor: '#151936',
		tabInactiveColor: '#435C89',
		textColor: '#4075F0 ',
	}
}
export {
	BaseURL,
	Config
}
