<template>
    <el-card style="width: 100%;min-width: 1200px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
            <div  style="height: 47px">
                <el-form-item label="商户名称">
                    <el-input v-model="queryForm.mchName" placeholder="商户名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="所属地区" class="search-input" >
                    <treeselect class="title-input" size="small"
                                :appendToBody="true"
                                name="demo"
                                :multiple="false"
                                :searchable="true"
                                :options="areaTree"
                                :limit="3"
                                v-model="queryForm.areaCode"
                                placeholder="请选择地区"
                    />
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div class="op-botton">
            <el-button type="primary" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick()">新增</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                    :data="records"
                    border
                    :stripe="true">
                <el-table-column
                        prop="mchName"
                        label="商户名称"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="mchNo"
                        show-overflow-tooltip
                        label="商户编号">
                </el-table-column>
                <el-table-column
                        prop="payMchId"
                        show-overflow-tooltip
                        label="支付商户号">
                </el-table-column>
                <el-table-column
                        prop="areaName"
                        show-overflow-tooltip
                        label="所属地区">
                </el-table-column>
                <el-table-column
                        prop="createUserName"
                        show-overflow-tooltip
                        label="创建人">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="操作"
                        align="right"
                        width="330"
                        fixed="right">
                    <template slot-scope="scope" >
                        <div class="controls">
                            <el-button
                                    type="primary"
                                    plain
                                    size="mini"
                                    @click="editClick(scope.row)"
                                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
                            >编辑
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
        </div>
        <!-- 编辑弹框内容-->
        <el-dialog title="新增/编辑" :visible.sync="dialogFormVisible">
            <el-form>
                <el-form-item label="商户名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="curDataForm.mchName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="支付商户号" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="curDataForm.payMchId"
                            autocomplete="off"
                            placeholder="支付商户号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属地区" :label-width="formLabelWidth" required>
                    <treeselect
                            name="demo"
                            :multiple="false"
                            :searchable=true
                            :options="areaTree"
                            :limit="3"
                            v-model="curDataForm.areaCode"
                            style="width: 100%"
                            placeholder="请选择所属地区"
                    />
                    </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    import Clipboard from "clipboard";
    import Treeselect from '@riophae/vue-treeselect';
    export default {
        components: {
            Treeselect
        },
        name: "mch", // 商户管理
        data() {
            return {
                limit: "2",
                page: "1",
                startTime:"",
                endTime:"",
                records: [],
                choose7: false,
                choose30: false,
                choose180: false,
                curDataForm: {
                },
                orgItem:[],
                payScene:[],
                payAllScene:[],
                queryForm: {
                    name: "", // 项目名称
                    createUserName: "", // 创建人
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                dialogCode: false, // 重置密码弹框
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, // 删除弹框
                xuqiFormVisible: false,// 续期弹框
                formLabelWidth: "120px",
                loginNameVisible:true,
                mchNoDisabled:false,
                areaTree: [],
            };
        },
        created() {
            this.loadData();
            this.loadAddUserDeptTree();
            
            this.$request
                .getOrgListByLoginUser({
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.orgItem = res.data;
                    }
                });
            this.$request
                .getUserSceneList({
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.payAllScene = res.data;
                        this.payAllScene.unshift({"sceneId":"","sceneName":"全部"});
                    }
                });
        },
        computed: {},
        methods: {
            loadAddUserDeptTree(){
                this.$request.bashSpecialMchAreaTree({ })
                    .then((res) => {
                        this.areaTree.push(res.data);
                    });
            },
            loadData(page) {
                this.$request
                    .bashSpecialMchList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            createClick() {
                this.loginNameVisible=true;
                this.mchNoDisabled = false;
                this.$util.clearObj(this.curDataForm);
                this.dialogFormVisible = true;
            },
            editClick(item) {
                this.$request.bashMchInfo(item).then((res) => {
                    if (res.code == 0) {
                        this.curDataForm = res.data;
                        this.dialogFormVisible = true;
                    }
                });

            },
            submit() {
                if (!this.curDataForm.mchId) {
                    let curDataForm = {...this.curDataForm};
                    this.$request.bashSpecialMchSave(curDataForm).then((res) => {
                        if (res.code == 0) {
                            this.$alert(res.data, {
                                confirmButtonText: '确定',
                                callback: action => {
                                }
                            });
                            this.dialogFormVisible = false;
                            this.$util.clearObj(this.curDataForm);
                            this.loadData();
                        }
                    });
                } else {
                    this.$request
                        .bashSpecialMchUpdate({
                            ...this.curDataForm,
                        })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message({
                                    message: "成功",
                                    type: "success",
                                });
                                this.dialogFormVisible = false;
                                this.loadData();
                                this.$util.clearObj(this.curDataForm);
                            }
                        });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .choose-days {
        width: 67px;
        height: 32px;
        margin-top: 3px;
        margin-left: 9px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 32px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
    .search-input  /deep/.el-input__inner {
        height: 32px!important;
        width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    .vue-treeselect {
        width: 200px;
        line-height: 32px;
    }
    .el-range-editor.el-input__inner {
        display: inline-flex;
        align-items: center;
        padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

    .op-botton{
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: right;
    }
    /deep/  .el-table th, .el-table tr {
        background-color: #F5F5F5;
        color: #909399;
    }
    /deep/ .el-table td {
        padding: 0px 0;
        height: 50px;
    }
    /deep/ .el-table th {
        padding: 0px 0;
        height: 50px;
    }
    .del .tip .tip-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
    .vue-treeselect {
        width: 200px;
        line-height: 32px;
    }
</style>
