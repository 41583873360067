import store from '@/store'
import {
    URL
} from '@/utils/url.js'
import http from '@/utils/http'

export default {
    test() {
        return http({
            method: "get",
            url: URL.test,
            showLoading: true,
            showSuccess: false,
            showFail: true
        }).then(res => {
            store.commit('setUser', res)
            return res
        })
    },
    search(params) {
        return http({
            method: "post",
            url: URL.search,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    getKAccountList(params) {
        return http({
            method: "get",
            url: URL.getKAccountList,
            params,
            showLoading: true
        })
    },
    rbacuserList(params) {
        return http({
            method: "get",
            url: URL.rbacuserList,
            params,
            showLoading: true
        })
    },
    menuRoles() {
        return http({
            method: "get",
            url: URL.menuRoles
        }).then(res => {
            if (res.code == 0) {
                store.commit('setMenus', res.data.children)
            }
            return res
        })
    },
    rbacroleList(params) {
        return http({
            method: "get",
            url: URL.rbacroleList,
            params,
            showLoading: true
        })
    },
    rbacroleSave(params) {
        return http({
            method: "post",
            url: URL.rbacroleSave,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    rbacroleDelete(roleId) {
        return http({
            method: "delete",
            url: URL.rbacroleDelete + '/' + roleId,
            showLoading: true,
            isAll: true
        })
    },
    rbacroleUpdate(params) {
        return http({
            method: "put",
            url: URL.rbacroleUpdate,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    rbacuserSave(params) {
        return http({
            method: "post",
            url: URL.rbacuserSave,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    rbacuserDelete(id) {
        return http({
            method: "delete",
            url: URL.rbacuserDelete + '/' + id,
            showLoading: true,
            isAll: true
        })
    },
    rbacuserUpdatePassword(params) {
        return http({
            method: "post",
            url: URL.rbacuserUpdatePassword,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    rbacuserDetail(params = {}) {
        if (store.state.userInfo.userId) {
           /* return new Promise((resolve, reject) => {
                resolve(store.state.userInfo)
            })*/
        } else {
            return http({
                method: "get",
                url: URL.rbacuserDetail,
                params
            }).then(res => {
                /*if (res.code == 0) {
                    store.commit('setUserInfo', res.data)
                }*/
                return res;
            })
        }
    },
    expireAlert(params = {}) {
        return http({
            method: "get",
            url: URL.rbacuserExpireAlert,
            data: {
                ...params,
        },
        showLoading: false
     })
    },
    
    rbacuserDetailRequest(params = {}) {
        return http({
                method: "get",
                url: URL.rbacuserDetail,
                data: {
                    ...params,
            },
            showLoading: false
         })
    },
    rbacuserInfo(id) {
        return http({
            method: "get",
            url: URL.rbacuserInfo + '/' + id
        })
    },
    login(params) {
        return http({
            method: "post",
            url: URL.login,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        }).then(res => {
            if (res.code == 0) store.commit('setToken', res.data);
            return res
        })
    },
    loginByToken(params) {
        return http({
            method: "post",
            url: URL.loginByToken,
            data: {
                ...params,
            },
            showLoading: false
        })
    },
    publicKey(params = {}) {
        return http({
                method: "get",
                url: URL.publicKey,
                data: {
                    ...params,
            },
            showLoading: false
         })
    },
    restPassword(params = {}) {
        return http({
                method: "post",
                url: URL.restPassword,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    dictRole(params = {}) {
        return http({
            method: "get",
            url: URL.dictRole,
            params
        })
    },
    dictUserType(params = {}) {
        return http({
            method: "get",
            url: URL.dictUserType,
            params
        })
    },
    rbacuserUpdate(params) {
        return http({
            method: "put",
            url: URL.rbacuserUpdate,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    rbacuserUrl(params) {
        return http({
            method: "put",
            url: URL.rbacuserUrl,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    mchbillitemList(params) {
        return http({
            method: "POST",
            url: URL.mchbillitemList,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    mchbillitemUpdate(params) {
        return http({
            method: "post",
            url: URL.mchbillitemUpdate,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    mchbillitemDelete(params) {
        return http({
            method: "post",
            url: URL.mchbillitemDelete,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    mchbillitemSave(params) {
        return http({
            method: "post",
            url: URL.mchbillitemSave,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bashMchList(params) {
        return http({
            method: "post",
            url: URL.bashMchList,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    bashSpecialMchList(params) {
        return http({
            method: "post",
            url: URL.bashSpecialMchList,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    bashSpecialMchAreaTree(params) {
        return http({
            method: "post",
            url: URL.bashSpecialMchAreaTree,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    bashSpecialMchSave(params) {
        return http({
            method: "post",
            url: URL.bashSpecialMchSave,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    bashSpecialMchUpdate(params) {
        return http({
            method: "post",
            url: URL.bashSpecialMchUpdate,
            data: {
                ...params,
            },
            showLoading: true
        })
    },
    bashMchUpdate(params) {
        return http({
            method: "post",
            url: URL.bashMchUpdate,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bashMchXuqi(params) {
        return http({
            method: "post",
            url: URL.bashMchXuqi,
            data: {
                ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
		merchantUserBranch(){
			return http({
			    method: "get",
			    url: URL.merchantUserBranch,
			    data: {
			    },
			    showLoading: true
			})
		},
		merchantUserList(params = {}) {
		    return http({
		        method: "get",
		        url: URL.merchantUserList,
				params,
		        showLoading: true
		    })
		},
    merchantUserList2(params = {}) {
		    return http({
		        method: "get",
		        url: URL.merchantUserList2,
				params,
		        showLoading: true
		    })
		},
    getOrgListByLoginUser(params) {
        return http({
            method: "post",
            url: URL.getOrgListByLoginUser,
            data: {
                ...params,
            },
            showLoading: false
        })
    },
    getSceneListByOrgId(params) {
        return http({
                method: "post",
                url: URL.getSceneListByOrgId,
                data: {
                    ...params,
            },
            showLoading: false
         })
    },
    bashMchSave(params) {
        return http({
                method: "post",
                url: URL.bashMchSave,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
         })
    },
    bashMchDelete(params) {
        return http({
                method: "post",
                url: URL.bashMchDelete,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
         })
    },
    bashMchInfo(params) {
        return http({
                method: "post",
                url: URL.bashMchInfo,
                data: {
                    ...params,
            },
            showLoading: true
         })
    },
    bashMchBindUrl(params) {
        return http({
                method: "post",
                url: URL.bashMchBindUrl,
                data: {
                    ...params,
            },
            showLoading: true
         })
    },
    bashMchShangbaoUrl(params) {
        return http({
                method: "post",
                url: URL.bashMchShangbaoUrl,
                data: {
                    ...params,
            },
            showLoading: true
         })
    },
    getExpireDate(params) {
        return http({
                method: "post",
                url: URL.getExpireDate,
                data: {
                    ...params,
            },
            showLoading: false
         })
    },
    getUserSceneList(params) {
        return http({
                method: "post",
                url: URL.getUserSceneList,
                data: {
                    ...params,
            },
            showLoading: true
         })
    },
    bashMchExport(params) {
        return http({
                method: "post",
                url: URL.bashMchExport,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
         })
    },
    bashOrgList(params) {
        return http({
                method: "post",
                url: URL.bashOrgList,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    bashOrgSave(params) {
        return http({
                method: "post",
                url: URL.bashOrgSave,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bashOrgUpdate(params) {
        return http({
                method: "post",
                url: URL.bashOrgUpdate,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bashOrgXuqi(params) {
        return http({
                method: "post",
                url: URL.bashOrgXuqi,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bashOrgInfo(params) {
        return http({
                method: "post",
                url: URL.bashOrgInfo,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    bashOrgDelete (params) {
        return http({
                method: "post",
                url: URL.bashOrgDelete ,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bashOrgExport (params) {
        return http({
                method: "post",
                url: URL.bashOrgExport ,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    dictOrg (params) {
        return http({
                method: "post",
                url: URL.dictOrg ,
                data: {
                    ...params,
            },
            showLoading: false
        })
    },
    dictMch (params) {
        return http({
                method: "post",
                url: URL.dictMch ,
                async: false,
                data: {
                    ...params,
            },
            showLoading: false
        })
    },
    deptTree (params) {
        return http({
                method: "post",
                url: URL.deptTree ,
                data: {
                    ...params,
            },
            showLoading: false
        })
    },
    rbacroleInfo (params) {
        return http({
                method: "post",
                url: URL.rbacroleInfo ,
                data: {
                    ...params,
            },
            showLoading: false
        })
    },
    rbacroleFindCheckMenuTree (params) {
        return http({
                method: "post",
                url: URL.rbacroleFindCheckMenuTree ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
        })
    },
    mchPayOrderList (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    mchPayOrderSpecialOrg (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderSpecialOrg ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
        })
    },
    mchPayOrderListByMch (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderListByMch ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    mchPayOrderRefundOrder (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderRefundOrder ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    mchPayOrderBatchRefund (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderBatchRefund ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    mchPayOrderExport (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderExport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    mchPayOrderExportByMch (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderExportByMch ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    mchRefundOrderList (params) {
        return http({
                method: "post",
                url: URL.mchRefundOrderList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    mchRefundOrderExport (params) {
        return http({
                method: "post",
                url: URL.mchRefundOrderExport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    paySceneList (params) {
        return http({
                method: "post",
                url: URL.paySceneList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    paySceneSave (params) {
        return http({
                method: "post",
                url: URL.paySceneSave ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    paySceneUpdate (params) {
        return http({
                method: "post",
                url: URL.paySceneUpdate ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    paySceneInfo (params) {
        return http({
                method: "post",
                url: URL.paySceneInfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    paySceneDelete (params) {
        return http({
                method: "post",
                url: URL.paySceneDelete ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
        })
    },
    bannerList (params) {
        return http({
                method: "post",
                url: URL.bannerList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },
    bannerSave (params) {
        return http({
                method: "post",
                url: URL.bannerSave ,
                async:false,
                data: {
                    ...params,
                 },
            showLoading: true,
            isAll: true
        })
    },
    bannerUpdate (params) {
        return http({
                method: "post",
                url: URL.bannerUpdate ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true,
            isAll: true
        })
    },
    deptGetOrgOrMchList (params) {
        return http({
                method: "post",
                url: URL.deptGetOrgOrMchList ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    bannerInfo (params) {
        return http({
                method: "post",
                url: URL.bannerInfo ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    bannerDelete (params) {
        return http({
                method: "post",
                url: URL.bannerDelete ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false,
            isAll: true
        })
    },
    baseMchConfConf (params) {
        return http({
                method: "post",
                url: URL.baseMchConfConf ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfChangePayType (params) {
        return http({
                method: "post",
                url: URL.baseMchConfChangePayType ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchUserMarkSave (params) {
        return http({
                method: "post",
                url: URL.baseMchUserMarkSave ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    baseMchConfUpdateOnBlur (params) {
        return http({
                method: "post",
                url: URL.baseMchConfUpdateOnBlur ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    dictValiRule (params) {
        return http({
                method: "post",
                url: URL.dictValiRule ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfAddField (params) {
        return http({
                method: "post",
                url: URL.baseMchConfAddField ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfEditField (params) {
        return http({
                method: "post",
                url: URL.baseMchConfEditField ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfDeleteField (params) {
        return http({
                method: "post",
                url: URL.baseMchConfDeleteField ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    baseMchConfAddSelfItem (params) {
        return http({
                method: "post",
                url: URL.baseMchConfAddSelfItem ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfUpdateSelfItem (params) {
        return http({
                method: "post",
                url: URL.baseMchConfUpdateSelfItem ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfDeleteSelfItem (params) {
        return http({
                method: "post",
                url: URL.baseMchConfDeleteSelfItem ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfDefaultValueList (params) {
        return http({
                method: "post",
                url: URL.baseMchConfDefaultValueList ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    baseMchConfDefaultValueSave (params) {
        return http({
                method: "post",
                url: URL.baseMchConfDefaultValueSave ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    baseMchConfSaveOrUpdateOrgMchKey (params) {
        return http({
                method: "post",
                url: URL.baseMchConfSaveOrUpdateOrgMchKey ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    baseMchConfGetKeyInfo (params) {
        return http({
                method: "post",
                url: URL.baseMchConfGetKeyInfo ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    baseMchSaveHeadUrl (params) {
        return http({
                method: "post",
                url: URL.baseMchSaveHeadUrl ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    mchbillpackList (params) {
        return http({
                method: "post",
                url: URL.mchbillpackList ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true
        })
    },
    mchUserDeptSave (params) {
        return http({
                method: "post",
                url: URL.mchUserDeptSave ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true,
            isAll: true
        })
    },
    mchUserDeptTree (params) {
        return http({
                method: "post",
                url: URL.mchUserDeptTree ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    mchUserDeptUpdate (params) {
        return http({
                method: "post",
                url: URL.mchUserDeptUpdate ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false,
            isAll: true
        })
    },
    mchUserDeptDelete (params) {
        return http({
                method: "post",
                url: URL.mchUserDeptDelete ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false,
            isAll: true
        })
    },
    mchUserSave (params) {
        return http({
                method: "post",
                url: URL.mchUserSave ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true,
            isAll: true
        })
    },
    mchUserUpdate (params) {
        return http({
                method: "post",
                url: URL.mchUserUpdate ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true,
            isAll: true
        })
    },
    mchUserChangeStatus (params) {
        return http({
                method: "post",
                url: URL.mchUserChangeStatus ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: true,
            isAll: true
        })
    },
    mchUserInfo (params) {
        return http({
                method: "post",
                url: URL.mchUserInfo ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    mchUserInfobyUserNo (params) {
        return http({
                method: "post",
                url: URL.mchUserInfobyUserNo ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    mchUserTableHead (params) {
        return http({
                method: "post",
                url: URL.mchUserTableHead ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false
        })
    },
    mchUserDelete (params) {
        return http({
                method: "post",
                url: URL.mchUserDelete ,
                async:false,
                data: {
                    ...params,
                },
            showLoading: false,
            isAll: true
        })
    },
    merchantUserExportMchUser(params = {}) {
        return http({
            method: "get",
            url: URL.merchantUserExportMchUser,
            params,
            showLoading: true,
            isAll: true
        })
    },
    mchUserImport (params) {
        return http({
                method: "post",
                url: URL.mchUserImport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    merchantUserDownTemplate (params) {
        return http({
                method: "post",
                url: URL.merchantUserDownTemplate ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    merchantUserDeptTemplate (params) {
        return http({
                method: "post",
                url: URL.merchantUserDeptTemplate ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    merchantUserDeptImport (params) {
        return http({
                method: "post",
                url: URL.merchantUserDeptImport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    dictBillItemList (params) {
        return http({
                method: "post",
                url: URL.dictBillItemList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    getAreaList (params) {
        return http({
                method: "post",
                url: URL.getAreaList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    mchbillpackSave (params) {
        return http({
                method: "post",
                url: URL.mchbillpackSave ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackChangeStartStatus (params) {
        return http({
                method: "post",
                url: URL.mchbillpackChangeStartStatus ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackChangePartStatus (params) {
        return http({
                method: "post",
                url: URL.mchbillpackChangePartStatus ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackDelete (params) {
        return http({
                method: "post",
                url: URL.mchbillpackDelete ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackDownTemplate (params) {
        return http({
                method: "post",
                url: URL.mchbillpackDownTemplate ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackImportBill (params) {
        return http({
                method: "post",
                url: URL.mchbillpackImportBill ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackCuijiao (params) {
        return http({
                method: "post",
                url: URL.mchbillpackCuijiao ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillTableHead (params) {
        return http({
                method: "post",
                url: URL.mchbillTableHead ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    payOrderTableHead (params) {
        return http({
                method: "post",
                url: URL.payOrderTableHead ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    mchbillList (params) {
        return http({
                method: "post",
                url: URL.mchbillList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    mchbillPackList (params) {
        return http({
                method: "post",
                url: URL.mchbillPackList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    mchbillMchInfo (params) {
        return http({
                method: "post",
                url: URL.mchbillMchInfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    mchbillSave (params) {
        return http({
                method: "post",
                url: URL.mchbillSave ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillInfo (params) {
        return http({
                method: "post",
                url: URL.mchbillInfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    mchbillUpdate (params) {
        return http({
                method: "post",
                url: URL.mchbillUpdate ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillDelete (params) {
        return http({
                method: "post",
                url: URL.mchbillDelete ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillWriteoff (params) {
        return http({
                method: "post",
                url: URL.mchbillWriteoff ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    mchbillCuijiao (params) {
        return http({
                method: "post",
                url: URL.mchbillCuijiao ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillExport (params) {
        return http({
                method: "post",
                url: URL.mchbillExport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    orgConfConf (params) {
        return http({
                method: "post",
                url: URL.orgConfConf ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    clearOrgWechatConf (params) {
        return http({
                method: "post",
                url: URL.clearOrgWechatConf ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    orgConfSaveLogoUrl (params) {
        return http({
                method: "post",
                url: URL.orgConfSaveLogoUrl ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false,
            isAll: true
    })
    },
    orgConfKeyinfo (params) {
        return http({
                method: "post",
                url: URL.orgConfKeyinfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    orgConfKeysave (params) {
        return http({
                method: "post",
                url: URL.orgConfKeysave ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    orgConfWechatSave (params) {
        return http({
                method: "post",
                url: URL.orgConfWechatSave ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    wechatInfo (params) {
        return http({
                method: "post",
                url: URL.wechatInfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    logoInfo (params) {
        return http({
                method: "post",
                url: URL.logoInfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    updateLogoInfo (params) {
        return http({
                method: "post",
                url: URL.updateLogoInfo ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    rbacroleLogout (params) {
        return http({
                method: "post",
                url: URL.rbacroleLogout ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    statisticsToday (params) {
        return http({
                method: "post",
                url: URL.statisticsToday ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    statisticsEchart (params) {
        return http({
                method: "post",
                url: URL.statisticsEchart ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    statisticsList (params) {
        return http({
                method: "post",
                url: URL.statisticsList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    statisticsExport (params) {
        return http({
                method: "post",
                url: URL.statisticsExport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    statisticsTotal (params) {
        return http({
                method: "post",
                url: URL.statisticsTotal ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    mchUserMoveDeptId (params) {
        return http({
                method: "post",
                url: URL.mchUserMoveDeptId ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    orgMchUserList (params) {
        return http({
                method: "post",
                url: URL.orgMchUserList ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    orgMchUserExport (params) {
        return http({
                method: "post",
                url: URL.orgMchUserExport ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackSetStartEndTime (params) {
        return http({
                method: "post",
                url: URL.mchbillpackSetStartEndTime ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchbillpackUpdatePackName (params) {
        return http({
                method: "post",
                url: URL.mchbillpackUpdatePackName ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    getVeifyCode (params) {
        return http({
                method: "post",
                url: URL.getVeifyCode ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false
    })
    },
    updateStatus (params) {
        return http({
                method: "post",
                url: URL.updateStatus ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: false,
            isAll: true
    })
    },
    mchPayOrderGenPdf (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderGenPdf ,
                async:false,
                data: {
                    ...params,
            },
            showLoading: true,
            isAll: true
    })
    },
    mchPayOrderInfo (params) {
        return http({
                method: "post",
                url: URL.mchPayOrderInfo ,
                data: {
                    ...params,
            },
            showLoading: true
    })
    },
    payOrderUpdateRemark (params) {
        return http({
                method: "post",
                url: URL.payOrderUpdateRemark ,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },

    mchXuqiRecordList (params) {
        return http({
                method: "post",
                url: URL.mchXuqiRecordList ,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },

    mchXuqiRecordExport (params) {
        return http({
                method: "post",
                url: URL.mchXuqiRecordExport ,
                data: {
                    ...params,
            },
            showLoading: true
        })
    },

}