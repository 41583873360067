<template>
<el-row style="height:26%" :visible.sync="headerVisible">
  <el-card style="height:100%">
    <el-col style="position: relative;">
      <el-upload
              class="avatar-uploader"
              style="display:inline-block"
              action="/charge/admin/file/upload"
              :show-file-list="false"
              :on-success="handleSuccess"
              v-show="userType == 'MCH'"
              :before-upload="handlePreview">
        <div class="avatar">
          <img :src="headUrl" class="avatar">
        </div>
      </el-upload>
      <img v-show="userType != 'MCH'" class="avatar" :src="headUrl" alt />
      <div class="user-box">
        <div style="float: left">
        <p class="name">{{userDetail.userName}}</p>
        <img class="edit-icon" src="../assets/images/edit.png" alt />
        <p class="mcName">
          <!-- <span class="heavy"></span> -->
          {{userDetail.mchOrgNameLabel}}
          <span class="defColorv">{{userDetail.mchOrgName}}</span>
        </p>
        <p class="mcName">
          <!-- <span class="heavy"></span> -->
          {{userDetail.mchOrgNoLabel}}
          <span class="defColorv">{{userDetail.mchOrgNo}}</span>
        </p>
        <slot></slot>
        <p class="mcName">
          {{userDetail.payUrlLabel}}
          <span>{{userDetail.payUrl}}</span><span  class="copyPayUrl"
                                                v-clipboard:copy="userDetail.payUrl"
                                                v-clipboard:success="copySuccess"
                                                v-clipboard:error="copyError">复制链接</span>
        </p>
        </div>
      </div>
      <div class="vcode-box">
        <div style="float: left">
          <p class="title">用户缴费二维码</p>
          <vue-qr ref="Qrcode" :text="userDetail.payUrl" download="downloadFilename" :margin="100" :size="105" style="margin-left: 17px">
          </vue-qr>
        </div >
        <div class="down">
          <img class="down-icon" src="../assets/images/download.png" alt />
          <p class="downTxt" @click="downQrCode">下载二维码</p>
        </div>
      </div>
    </el-col>
    <!-- 点击二维码 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="qrCode" style="width:200px;height:200px"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </el-card>
</el-row>
</template>

<script>
import QRCode from 'qrcodejs2';
import VueQr from 'vue-qr';
import Clipboard from "clipboard";
export default {
  components: {
    VueQr
  },
  name: "indexHeader",
  data() {
    return {
      dialogVisible: false,
      headerVisible: false,
        userType:"",
        headUrl:"https://charge-public-bucket-prod.oss-cn-shenzhen.aliyuncs.com/admin_default_head_img.png",
      userDetail: {
        userName: "",
        mchOrgNameLabel: "",
        mchOrgName: "",
        payUrlLabel: "",
        payUrl: "",
        mchOrgNoLabel: "",
        mchOrgNo: "",
      },
    };
  },
  created() {
    let curUser = localStorage.getItem('curUserInfo123');
    curUser = JSON.parse(curUser)
      this.userType = curUser.userType;
      if(curUser.headUrl && curUser.headUrl != ''){
          this.headUrl = curUser.headUrl;
      }
    this.userDetail.userName = curUser.userName;
    if (curUser.userType == 'MCH') {
      this.userDetail.mchOrgNameLabel = '商户名称：';
      this.userDetail.mchOrgName = curUser.baseMch.mchName;
      this.userDetail.payUrlLabel = '商户缴费链接：';
      this.userDetail.payUrl = curUser.baseMch.payUrl;
      this.userDetail.mchOrgNoLabel = '商户编号：';
      this.userDetail.mchOrgNo = curUser.baseMch.mchNo;;
      this.headerVisible = true;
    } else if (curUser.userType == 'ORG') {
      this.userDetail.mchOrgNameLabel = '渠道名称：';
      this.userDetail.mchOrgName = curUser.baseOrgDetail.orgName;
      this.userDetail.payUrlLabel = '渠道缴费链接：';
      this.userDetail.payUrl = curUser.baseOrgDetail.payUrl;
      this.userDetail.mchOrgNoLabel = '渠道编号：';
      this.userDetail.mchOrgNo = curUser.baseOrgDetail.orgNo;;
      this.headerVisible = true;
    } else {
      this.headerVisible = false;
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
      handleSuccess(res) {
          this.headUrl = res.data.url;
          this.$request.baseMchSaveHeadUrl({headUrl:this.headUrl})
              .then((res2) => {
                  if(res2.code == 0){
                      this.$message({message:"成功",type: "success",});
                      let curUser = localStorage.getItem('curUserInfo123');
                      curUser = JSON.parse(curUser)
                      curUser.headUrl=res.data.url;
                      localStorage.setItem('curUserInfo123', JSON.stringify(curUser))
                  }
              });
      },
      handlePreview(file){
          if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
              this.$message.error('只支持jpg、png格式的图片！')
              return false
          }
      },
    downQrCode() {
      const iconUrl = this.$refs.Qrcode.$el.src
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = this.userDetail.mchOrgName + "-缴费二维码";
      a.href = iconUrl
      a.dispatchEvent(event)
    },
      copySuccess(){
          this.$message({message:"已复制至剪贴板", type: "success",});
      },
      copyError(){
          this.$message({message:"失败", type: "error",});
      },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/style/reset";

/deep/ .el-row {
  margin: 0;
  padding: 0;
  height: initial;
  margin-bottom: 10px;
  line-height: 30px !important;
}

.user-box {
  display: inline-block;
  margin-bottom: 10px;
}
.vcode-box {
  float: right;
  margin-buttom: 12px;
  margin-right: 40px;
  position: absolute;
  bottom: 17px;
  right: 0;
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #303133;
  }
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 40px;
  margin-bottom: 20px;
}

.name {
  font-size: 25px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #303133;
  // letter-spacing: 3px;
  display: inline-block;
  margin-bottom: 10px;
}

.edit-icon {
  width: 17px;
  height: 16px;
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.mcName {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #606266;
  line-height:30px;
}

.mcNames {
  font-size: 14px;
}

.qrCode {
  width: 80px;
  height: 80px;
  background-color: deeppink;
  margin: 0 auto;
  cursor: pointer;
}

.down {
  float: left;
  height: 135px;
  width: 100px;
  position: relative;
  .down-icon {
    height: 18px;
    position: absolute;
    bottom: 0px;
  }

  .downTxt {
    font-size: 15px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #4a7dfa;
    line-height: 20px;
    position: absolute;
    bottom: 0px;
    left: 20px;
    display: inline-block;
    cursor: pointer;
  }
}

/*.el-col-2 {
  text-align: center !important;
  white-space: nowrap;

  .vcode-box {


  }
}*/

.copyPayUrl{
  margin-left: 10px;
  font-family: MicrosoftYaHei;
  color: #4065E0;
  cursor:pointer;
}

.heavy {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #4a7dfa;
  border-radius: 50%;
  margin-right: 7px;
}
</style>
