<template>
<div>
  <add-or-update-user :visible="showDeptDialog" @closeDialog="closeDialog" :form="deptForm" @submit="submitDept"></add-or-update-user>
  <!-- 商户 -->
  <el-row :gutter="10" style="min-width: 1400px;overflow-y: scroll" v-show="mchVisible" class="m_body">
    <el-col :span="5" style="min-width: 280px">
      <el-card class="box-card" >
        <div slot="header" class="clearfix">
          <el-input placeholder="请输入部门，用户编号，用户名称" size="small" v-model.trim="queryForm.queryName" style="width:72%;padding-right:10px"></el-input>
          <el-button type="primary" size="small" @click="merchantUserList(1)" plain>查询</el-button>
        </div>
        <div class="departmentWrap">
          <span class="department"> 部门</span>
          <el-button type="primary" size="small" @click="clickImport('2')" plain>批量导入部门</el-button>
        </div>

        <div class="text item">
          <el-tree :ref="tree" :data="tree" default-expand-all :highlight-current="true" :expand-on-click-node="false" @current-change="deptCurrentChange">
            <span class="custom-tree-node" slot-scope="{ node,data }">
              <span>{{ node.label }}</span>
              <el-dropdown @command="handleDeptMoreCommand">
                <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="'add,'+data.id">添加子部门</el-dropdown-item>
                  <el-dropdown-item :command="'edit,'+data.id+','+data.label">编辑</el-dropdown-item>
                  <el-dropdown-item :command="'delete,'+data.id">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!--<span class="operate-btns">
                <dot-dropdown :events="dropMenuEvents" @handleDept="handleDept" :data="{node,data}" />
              </span>-->
            </span>
          </el-tree>
        </div>
      </el-card>
    </el-col>
    <el-col :span="19" >
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <div class="header">
            <div class="left">
              <div>{{deptTitels}}</div>
            </div>
            <div class="right">
              <el-button type="primary" plain size="mini" @click="editDept2">修改部门</el-button>
              <el-button type="primary" plain size="mini" @click="addDept2">添加子部门</el-button>
            </div>
          </div>
        </div>
        <div class="text item">
          <div class="header2 flexEnd">
            <el-button type="primary" plain size="mini" @click="addMchUser">添加用户</el-button>
            <el-button type="primary" plain size="mini" @click="clickImport('1')">批量导入</el-button>
            <el-button type="primary" plain size="mini" @click="clickBatchMove">批量移动</el-button>
            <el-button type="danger" plain size="mini" @click="clickBatchDelete">批量删除</el-button>
            <el-button type="primary" plain size="mini" @click="createExportMchUser">导出用户</el-button>
          </div>
          <div class="el-table-bg">
            <el-table :data="tableData" border style="font-size: 13px" :header-cell-style="rowClass" :row-style="{height:'45px'}" :cell-style="{padding:'0px'}" @selection-change="changeFun">
              <el-table-column align="center" fixed="left" type="selection" width="55">
              </el-table-column>
              <template v-for="(item,index) in tableHead">
                <el-table-column :prop="item.columnProp" align="center"  show-overflow-tooltip :label="item.columnLabel" :key="index" v-if="item.columnProp != 'id'"></el-table-column>
              </template>
              <el-table-column align="center" prop="deptPath" show-overflow-tooltip label="所属部门"></el-table-column>
              <el-table-column align="center" prop="userStatus" show-overflow-tooltip label="状态">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.userStatus" active-value="on" inactive-value="off" active-color="#4A7DFA" inactive-color="#E4E7ED" @change=changeSwitch($event,scope.row,scope.$index)>
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="createUserName" show-overflow-tooltip label="创建人"></el-table-column>
              <el-table-column align="center" prop="createTime" show-overflow-tooltip label="创建时间"></el-table-column>
              <el-table-column align="center" fixed="right" label="操作"  width="300">
                <template slot-scope="scope">
                  <el-button @click="updateMchUser(scope.row)" type="primary" plain size="mini">修改</el-button>
                  <el-button @click="deleteMchUser(scope.row)" type="danger" plain size="mini">删除</el-button>
                  <el-button @click="infoMchUser(scope.row)" type="primary" plain size="mini">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <el-dialog title="详情" :visible.sync="dialogFormInfoVisible">
    <el-form>
      <el-form-item :label="mchConf.userNameRename" :label-width="formLabelWidth" required>
        <span>{{curDataForm.mchUserName}}</span>
      </el-form-item>
      <el-form-item :label="mchConf.userNoRename" :label-width="formLabelWidth" required>
        <span>{{curDataForm.mchUserNo}}</span>
      </el-form-item>
      <el-form-item v-for="(billField, index) in curDataForm.fieldList" :label=billField.fieldName :key="billField.fieldId" :label-width="formLabelWidth">
        <span>{{billField.fieldValue}}</span>
      </el-form-item>
      <el-form-item label="所属部门" :label-width="formLabelWidth" required>
        <span>{{curDataForm.deptPath}}</span>
      </el-form-item>
      <el-form-item label="状态" :label-width="formLabelWidth" required>
        <span>{{curDataForm.userStatusStr}}</span>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
        <span>{{curDataForm.remark}}</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogFormInfoVisible = false">确 定</el-button>
    </div>
  </el-dialog>
  <el-dialog title="新增/修改" :visible.sync="dialogDeptFormVisible">
    <el-form ref="mchDeptForm">
      <el-form-item label="部门名称" :label-width="formLabelWidth" required>
        <el-input v-model.trim="mchDeptForm.deptName" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogDeptFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitDept2()">确 定</el-button>
    </div>
  </el-dialog>
  <el-dialog title="新增/修改" :visible.sync="dialogDeptFormVisible">
    <el-form ref="mchDeptForm">
      <el-form-item label="部门名称" :label-width="formLabelWidth" required>
        <el-input v-model.trim="mchDeptForm.deptName" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogDeptFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitDept2()">确 定</el-button>
    </div>
  </el-dialog>
  <el-dialog title="批量移动" :visible.sync="dialogMoveMchUserVisible">
    <el-form ref="mchUserForm">
      <el-form-item label="所属部门" :label-width="formLabelWidth" required>
        <!--<tree-select :data="tree" :checkedKeys="checkedIds" :popoverHide="popoverHide"></tree-select>-->
        <treeselect name="demo" :multiple="false" :searchable="searchable" :options="addUserDeptTree" :limit="3" :max-height="200" v-model="moveMchUserItem.deptId" placeholder="请选择所属部门" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogMoveMchUserVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitMoveMchUser()">确 定</el-button>
    </div>
  </el-dialog>
  <el-dialog title="新增/编辑" :visible.sync="dialogFormVisible">
    <el-form ref="mchUserForm">
      <el-form-item :label="mchConf.userNameRename" :label-width="formLabelWidth" required>
        <el-input v-model.trim="curDataForm.mchUserName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item :label="mchConf.userNoRename" :label-width="formLabelWidth" required>
        <el-input v-model.trim="curDataForm.mchUserNo" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item v-for="(billField, index) in curDataForm.fieldList" :label=billField.fieldName :key="billField.fieldId" :label-width="formLabelWidth" :required="billField.required == 1">
        <el-input v-model.trim="billField.fieldValue"></el-input>
      </el-form-item>
      <el-form-item label="所属部门" :label-width="formLabelWidth" required>
        <!--<tree-select :data="tree" :checkedKeys="checkedIds" :popoverHide="popoverHide"></tree-select>-->
        <treeselect name="demo" :multiple="false" style="width: 100%" :searchable="searchable" :options="addUserDeptTree" :limit="3" :max-height="200" v-model="curDataForm.deptId" placeholder="请选择所属部门" />
      </el-form-item>
      <el-form-item class="accountStatus" label="状态" :label-width="formLabelWidth" required>
        <el-radio v-model="curDataForm.userStatus" label="on">启用
        </el-radio>
        <el-radio v-model="curDataForm.userStatus" label="off">禁用
        </el-radio>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model.trim="curDataForm.remark">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </div>
  </el-dialog>
  <el-dialog class="del" title="删除" :visible.sync="dialogDeleteVisible" width="30%" center>
    <div class="tip">
      <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt="" />
      <span class="txt">删除后该部门成员将会被纳入“未分组”管理，是否确定删除该部门？</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogDeleteVisible = false">取 消</el-button>
      <el-button type="primary" @click="deleteSure">确 定</el-button>
    </span>
  </el-dialog>
  <el-dialog :title="importTitle" :close-on-click-modal="false" @close="closeHandle" :visible.sync="importUserVisible">
    <el-upload drag action="/charge/admin/file/upload/excel" :before-upload="beforeUploadHandle" :on-success="successHandle" multiple :file-list="fileList" style="text-align: center;">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">支持多个文件上传，仅支持拓展名：xls，xlsx，大小不超过24mb</div>
    </el-upload>
    <p class="txt">点击此处<span class="downTxt" @click="downImportMchUserTemp" style="color:#4A7DFA; cursor: pointer;">下载模板</span></p>
    <!--<div class="progress">
        <span class="fileName">文档.xls</span>
        <el-progress :percentage="100" status="success"></el-progress>
      </div>
      <div class="progress">
        <span class="fileName">文档.xls</span>
        <el-progress :percentage="70" status="exception"></el-progress>
      </div>-->
    <div slot="footer" class="dialog-footer">
      <el-button @click="importUserVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitImportMchUser">确 定</el-button>
    </div>
  </el-dialog>
  <el-dialog class="del" title="删除" :visible.sync="dialogDeleteMchUserVisible" width="30%" center>
    <div class="tip">
      <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt="" />
      <span class="txt">确定删除将无法恢复，请确认！</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogDeleteMchUserVisible = false">取 消</el-button>
      <el-button type="primary" @click="deleteMchUserSure">确 定</el-button>
    </span>
  </el-dialog>
  <!-- 渠道 -->
  <el-row v-show="!mchVisible">
    <el-card style="width: 100%;min-width: 1300px">
      <el-form :inline="true" :model="queryForm2" class="demo-form-inline search-form"  label-position="right" label-width="90px" >
        <div style="height: 47px;">
          <el-form-item label="创建时间">
            <el-date-picker
                    v-model="queryForm2.createTime"
                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%;height: 32px"
            >
            </el-date-picker>
          </el-form-item>
          <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
          <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
          <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
        </div>
        <div  style="height: 47px">
          <el-form-item label="所属商户" class="search-input" v-show="mchVisible">
            <el-select
                    size="small"
                    class="search-input"
                    v-model="queryForm2.mchIdList"
                    multiple
                    clearable
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入商户名称或编号"
                    :remote-method="queryMchSelect"
            >
              <el-option
                      v-for="item in mchIdSelctList"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属渠道" class="search-input" v-show="mchVisible">
            <el-select
                    size="small"
                    class="search-input"
                    v-model="queryForm2.orgIdList"
                    multiple
                    clearable
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入渠道名称或编号"
                    :remote-method="queryOrgSelect"
            >
              <el-option
                      v-for="item in orgIdSelctList"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名称" class="search-form-item">
            <el-input v-model="queryForm2.mchUserName" placeholder="用户名称" class="search-input"></el-input>
          </el-form-item>
          <el-form-item label="用户编号">
            <el-input v-model="queryForm2.mchUserNo" placeholder="用户编号" class="search-input"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="search-button">
        <div style="margin: 0 auto;height: 48px;width: 200px">
          <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
          >查询
          </el-button
          >
          <el-button size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="op-botton" >
        <el-button size="small"   style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" @click="orgMchUserExport">导出</el-button>
      </div>
      <div style="width: 100%;margin-top: 0px;">
        <el-table
                :data="orgMchUserRecords"
                border
                :stripe="true">
          <el-table-column align="center" prop="mchUserName" show-overflow-tooltip label="用户名称"></el-table-column>
          <el-table-column align="center" prop="mchUserNo" show-overflow-tooltip label="用户编号"></el-table-column>
          <el-table-column align="center" prop="deptPath" show-overflow-tooltip label="所属部门"></el-table-column>
          <el-table-column align="center" prop="userStatusStr" show-overflow-tooltip label="状态"></el-table-column>
          <el-table-column align="center" prop="createUserName" show-overflow-tooltip label="创建人"></el-table-column>
          <el-table-column align="center" prop="createTime" show-overflow-tooltip label="创建时间"></el-table-column>
          <el-table-column align="center" prop="createUserName" show-overflow-tooltip label="用户名称"></el-table-column>
          <el-table-column
                  prop="address"
                  label="操作"
                  align="right"
                  width="100"
                  fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain size="small" @click="infoMchUser(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" style="margin-top: 15px;text-align: right">
        <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="queryInfo.currentPage"
                :page-sizes="queryInfo.pageSizes"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="queryInfo.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </el-row>
</div>
</template>

<script>
import drawer from "@/components/drawer.vue";
import dotDropdown from "@/components/dotDropdown";
import addOrUpdateUser from "@/views/container/base/addOrUpdateUser";
//import treeSelect from "@/components/treeSelect";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    drawer,
    dotDropdown,
    addOrUpdateUser,
    Treeselect
  },
  data() {
    return {
      importTitle: "",
      checkedIds: [],
        choose7: true,
        choose30: false,
        choose180: false,
      limit: "10",
      page: "1",
      showDeptDialog: false,
      dialogFormVisible: false,
      dialogDeptFormVisible: false,
      dialogDeleteVisible: false,
      dialogDeleteMchUserVisible: false,
      dialogFormInfoVisible: false,
      dialogMoveMchUserVisible: false,
      importUserVisible: false,
      mchVisible: true,
      searchable: true,
      dialogImpoertType: "1",
      fileList: [],
      orgMchUserRecords: [],
      tableHead: [],
      deptTitels: "",
      curDept: {
        id: "",
        label: ""
      },
      mchDeptForm: {
        deptId: "",
        deptName: "",
        parentId: ""
      },
      deleteDeptItem: {},
      deleteMchUserItem: {
        mchUserIdList: []
      },
      moveMchUserItem: {
        mchUserIdList: []
      },
      moveDeptId: "",
      selectedRows: [],
      curDataForm: {
        fieldList: []
      },
      formLabelWidth: "120px",
      mchConf: {
        userNoRename: "",
        userNameRename: ""
      },
      // 分页器
      queryInfo: {
        total: 0, // 总数
        pageSize: 10, // 每页显示的条数
        pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
        currentPage: 1, // 当前页数
      },
      deptForm: {
        deptId: "",
        deptName: ""
      },
      dropMenuEvents: [{
          label: '添加子部门',
          funcName: 'addDept'
        },
        {
          label: '编辑',
          funcName: 'editDept'
        },
        {
          label: '删除',
          funcName: 'deleteDept'
        }
      ],
      queryForm: {
        queryName: "", // 创建人
      },
      queryForm2: {
        createTime: [],
        mchIdList: [],
        orgIdList: [],
        mchUserName: "",
        mchUserNo: ""
      },
      statusValue: Boolean,
      value2: "",
      merchant: true,
      channel: true,
      filterText: "",
      tree: [{
        label: "",
        id: "",
        children: [],
        deptPath: "",
      }, ],
      addUserDeptTree: [],
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      mchIdSelctList: [],
      orgIdSelctList: [],
    };
  },
  computed: {},
  mounted() {
    //this.merchantUserBranch();
    //this.merchantUserList();
  },
  created() {
    let userType = localStorage.getItem('curUserType');
    if (userType != null) {
      if (userType == 'MCH') {
        this.mchVisible = true;
        this.$request
          .baseMchConfConf({})
          .then((res) => {
            this.mchConf = res.data;
          });
        this.merchantUserList();
        this.loadTableHead();
      } else {
        this.mchVisible = false;
        this.reduceQueryFormTime(7);
        this.loadData(1);
      }
    }
  },

  methods: {
    popoverHide() {
    },
      handleDeptMoreCommand(command){
        let commandArr =   command.split(',');
        if(commandArr[0] == 'add'){
            this.$util.clearObj(this.mchDeptForm);
            this.dialogDeptFormVisible = true;
            this.mchDeptForm.parentId = commandArr[1];
        }else if(commandArr[0] == 'edit'){
            this.$util.clearObj(this.mchDeptForm);
            this.dialogDeptFormVisible = true;
            this.mchDeptForm.deptName = commandArr[2];
            this.mchDeptForm.deptId = commandArr[1];
          }else if(commandArr[0] == 'delete'){
            this.dialogDeleteVisible = true;
            this.deleteDeptItem.deptId = commandArr[1];
        }
      },
    loadTableHead() {
      this.$request
        .mchUserTableHead({})
        .then((res) => {
          if (res.code == 0) {
            this.tableHead = res.data;
          }
        });
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.queryInfo.pageSize = val;
      this.merchantUserList2();
    },
    handleSizeChange2(val) {
      //   console.log(`每页 ${val} 条`);
      this.queryInfo.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`);
      this.merchantUserList2(val);
    },
    handleCurrentChange2(val) {
      //   console.log(`当前页: ${val}`);
      this.loadData(val);
    },
    // 文件上传弹框的数据开始
    beforeUploadHandle(file) {
      if (!file.name.endsWith("xls") && !file.name.endsWith("xlsx")) {
        this.$message.error('只支持xls、xlsx格式的文件！')
        return false
      }
      this.num++
    },
    downImportMchUserTemp() {
      if (this.dialogImpoertType == '1') {
        this.$request.merchantUserDownTemplate({}).then((res) => {
          if (res.code == 0) {
            window.open(res.data.url);
          }
        });
      } else if (this.dialogImpoertType == '2') {
        this.$request.merchantUserDeptTemplate({}).then((res) => {
          if (res.code == 0) {
            window.open(res.data.url);
          }
        });
      }

    },
    submitImportMchUser() {
      if (this.fileList.length == 0) {
        this.$message.error('请上传文件');
        return false;
      }
      var req = {};
      req.fileIdList = this.fileList;
      if (this.dialogImpoertType == '1') {
        this.$request.mchUserImport(req)
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "成功",
                type: "success",
              });
              this.merchantUserList();
              this.importUserVisible = false;
            }
          });
      } else if (this.dialogImpoertType == '2') {
        this.$request.merchantUserDeptImport(req)
          .then((res) => {
            if (res.code == 0) {
              this.$request.mchUserDeptTree().then((res2) => {
                if (res2.code == 0) {
                  this.tree[0].label = res2.data.label;
                  this.tree[0].id = res2.data.id;
                  this.tree[0].children = res2.data.children;
                  this.deptTitels = "";
                  this.curDept.id = "";
                  this.curDept.label = "";
                  this.dialogDeptFormVisible = false;
                }
              })
              this.$message({
                message: "成功",
                type: "success",
              });
              this.importUserVisible = false;
            }
          });
      }
    },
    // 上传成功
    successHandle(response, file, fileList) {
      if (response.code === 0) {
        this.fileList.push(response.data.fileId);
      }
    },
    // 弹窗关闭时
    closeHandle() {
      this.fileList = []
      this.$emit('refreshDataList')
    },
    closeDialog() {
      this.showDeptDialog = false
    },
    changeFun(val) {
      this.selectedRows = val;
    },
    changeSwitch(event, data, index) {
      this.$request
        .mchUserChangeStatus(data)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
          }
        });
    },
    clearSelectDeptChildren(root) {
      root.forEach(e => {
        if (!e.children || e.children.length == 0) {
          delete e['children'];
          return;
        } else {
          this.clearSelectDeptChildren(e.children);
        }
      })
    },
    loadAddUserDeptTree() {
      this.$request.mchUserDeptTree({})
        .then((res) => {
          this.addUserDeptTree = [];
          this.addUserDeptTree.push(res.data);
          this.clearSelectDeptChildren(this.addUserDeptTree);
        });
    },
    addMchUser() {
      this.$util.clearObj(this.curDataForm);
      let that = this.curDataForm;
      that.fieldList = [];
      this.loadAddUserDeptTree();
      if (this.curDept.id && this.curDept.id != '') {
        that.deptId = parseInt(this.curDept.id);
      }

      if (this.mchConf.userNoRename == "") {
        this.$request
          .baseMchConfConf({})
          .then((res) => {
            this.mchConf = res.data;
            that.userStatus = "on";
            this.mchConf.mchBillFieldDTOList.forEach(function (item, index, arr) {
              var obj = {};
              obj.fieldId = item.fieldId;
              obj.fieldName = item.fieldName;
              obj.value = "";
              obj.required = item.required;
              that.fieldList.push(obj);
            })
          });
      } else {
        that.userStatus = "on";
        this.mchConf.mchBillFieldDTOList.forEach(function (item, index, arr) {
          var obj = {};
          obj.fieldId = item.fieldId;
          obj.fieldName = item.fieldName;
          obj.required = item.required;
          obj.value = "";
          that.userStatus = "on";
          that.fieldList.push(obj);
        })
      }
      this.dialogFormVisible = true;
    },
    clickImport(type) {
      if (type == "1") {
        this.importTitle = "导入用户";
      } else {
        this.importTitle = "导入部门";
      }
      this.fileList = [];
      this.importUserVisible = true;
      this.dialogImpoertType = type;
    },
    updateMchUser(row) {
      this.$util.clearObj(this.curDataForm);
      let that = this.curDataForm;
      that.fieldList = [];
      this.loadAddUserDeptTree();
      if (this.mchConf.userNoRename == "") {
        this.$request.baseMchConfConf({})
          .then((res) => {
            this.mchConf = res.data;
            that.userStatus = "on";
            this.mchConf.mchBillFieldDTOList.forEach(function (item, index, arr) {
              var obj = {};
              obj.fieldId = item.fieldId;
              obj.fieldName = item.fieldName;
              obj.required = item.required;
              obj.value = "";
              that.fieldList.push(obj);
            })
          });
      } else {
        that.userStatus = "on";
        this.mchConf.mchBillFieldDTOList.forEach(function (item, index, arr) {
          var obj = {};
          obj.fieldId = item.fieldId;
          obj.fieldName = item.fieldName;
          obj.required = item.required;
          obj.value = "";
          that.userStatus = "on";
          that.fieldList.push(obj);
        })
      }
      this.$request.mchUserInfo(row)
        .then((res) => {
          this.curDataForm = res.data;
          this.dialogFormVisible = true;
          console.log(this.curDataForm.fieldList)
        });
    },
    infoMchUser(row) {
      this.$util.clearObj(this.curDataForm);
      let that = this.curDataForm;
      that.fieldList = [];
      if (this.mchConf.userNoRename == "") {
        this.$request.baseMchConfConf({
            mchId: row.mchId
          })
          .then((res) => {
            this.mchConf = res.data;
            that.userStatus = "on";
            this.mchConf.mchBillFieldDTOList.forEach(function (item, index, arr) {
              var obj = {};
              obj.fieldId = item.fieldId;
              obj.fieldName = item.fieldName;
              obj.value = "";
              that.fieldList.push(obj);
            })
          });
      } else {
        that.userStatus = "on";
        this.mchConf.mchBillFieldDTOList.forEach(function (item, index, arr) {
          var obj = {};
          obj.fieldId = item.fieldId;
          obj.fieldName = item.fieldName;
          obj.value = "";
          that.userStatus = "on";
          that.fieldList.push(obj);
        })
      }
      this.$request.mchUserInfo(row)
        .then((res) => {
          this.curDataForm = res.data;
          this.dialogFormInfoVisible = true;
        });
    },
    deleteMchUser(item) {
      this.deleteMchUserItem.mchUserIdList = [];
      this.deleteMchUserItem.mchUserIdList.push(item.mchUserId);
      this.dialogDeleteMchUserVisible = true;
    },
    clickBatchDelete() {
      if (this.selectedRows.length == 0) {
        this.$message({
          message: "请选择数据",
          type: "error",
        });
        return;
      }
      var arr2 = [];
      this.selectedRows.forEach(function (item, index, arr) {
        arr2.push(item.mchUserId);
      })
      this.deleteMchUserItem.mchUserIdList = arr2;
      this.dialogDeleteMchUserVisible = true;
    },
    clickBatchMove() {
      if (this.selectedRows.length == 0) {
        this.$message({
          message: "请选择数据",
          type: "error",
        });
        return;
      }
      var arr2 = [];
      this.selectedRows.forEach(function (item, index, arr) {
        arr2.push(item.mchUserId);
      })
      this.moveMchUserItem.mchUserIdList = arr2;
      this.loadAddUserDeptTree();
      this.dialogMoveMchUserVisible = true;
    },
    submitMoveMchUser() {
      this.$request.mchUserMoveDeptId(this.moveMchUserItem).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "成功",
            type: "success",
          });
          this.merchantUserList();
          this.dialogMoveMchUserVisible = false;
        }
      });
    },
    createExportMchUser() {
      var that = this;
      this.$request.merchantUserExportMchUser({
        limit: this.queryInfo.pageSize,
        deptId: this.curDept.id,
        ...this.queryForm,
      }).then((res) => {
        if (res.code == 0) {
          window.open(res.data.url);
        }
      });
    },
    deleteMchUserSure() {
      this.$request.mchUserDelete(this.deleteMchUserItem)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "成功",
              type: "success",
            });
            this.dialogDeleteMchUserVisible = false;
            this.merchantUserList();
          }
        });
    },
    submit() {
      if (this.curDataForm.mchUserId) {
        this.$request.mchUserUpdate(this.curDataForm)
          .then((res) => {
            if (res.code == 0) {
              this.dialogFormVisible = false;
              this.$message({
                message: "成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.$util.clearObj(this.curDataForm);
              this.merchantUserList();
            }
          });
      } else {
        this.$request.mchUserSave(this.curDataForm)
          .then((res) => {
            if (res.code == 0) {
              this.dialogFormVisible = false;
              this.$message({
                message: "成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.$util.clearObj(this.curDataForm);
              this.merchantUserList();
            }
          });
      }
    },
    deleteSure() {
      this.dialogDeleteVisible = false;
      this.$request.mchUserDeptDelete(this.deleteDeptItem).then((res) => {
        if (res.code == 0) {
          this.dialogDeleteVisible = false;
          this.$request.mchUserDeptTree().then((res2) => {
            if (res2.code == 0) {
              this.tree[0].label = res2.data.label;
              this.tree[0].id = res2.data.id;
              this.tree[0].children = res2.data.children;
            }
          })
        }
      });
    },
    //提交部门信息
    submitDept(form) {
      this.deptForm.deptName = form.deptName;
      if (this.deptForm.deptId) {
        this.$request.mchUserDeptUpdate(this.deptForm).then((res) => {
          if (res.code == 0) {
            this.showDeptDialog = false;
            this.$util.clearObj(this.deptForm);
            this.$request.mchUserDeptTree().then((res2) => {
              if (res2.code == 0) {
                this.tree[0].label = res2.data.label;
                this.tree[0].id = res2.data.id;
                this.tree[0].children = res2.data.children;
                this.deptTitels = "";
                this.curDept.id = "";
                this.curDept.label = "";
              }
            })
          }
        });
      } else {
        this.$request.mchUserDeptSave(this.deptForm).then((res) => {
          if (res.code == 0) {
            this.showDeptDialog = false;
            this.$util.clearObj(this.deptForm);
            this.$request.mchUserDeptTree().then((res2) => {
              if (res2.code == 0) {
                this.tree[0].label = res2.data.label;
                this.tree[0].id = res2.data.id;
                this.tree[0].children = res2.data.children;
                this.deptTitels = "";
                this.curDept.id = "";
                this.curDept.label = "";
              }
            })
          }
        });
      }
    },
    handleDept(event, data) {
      let deptId = data.data.id
      if ("addDept" == event) {
        this.showDeptDialog = true
        this.deptForm.parentId = deptId;
        this.deptForm.deptName = ""
      } else if ("editDept" == event) {
        this.showDeptDialog = true
        this.deptForm.deptId = data.data.id
        this.deptForm.deptName = data.data.label
      } else {
        this.dialogDeleteVisible = true;
        this.deleteDeptItem.deptId = deptId;
      }
    },
    editDept2() {
      if (!this.curDept.id) {
        this.$message({
          message: "请选择左侧部门",
          type: "error",
        });
        return;
      }
      this.$util.clearObj(this.mchDeptForm);
      this.dialogDeptFormVisible = true;
      this.mchDeptForm.deptName = this.curDept.label;
      this.mchDeptForm.deptId = this.curDept.id;
    },
    addDept2() {
      if (!this.curDept.id) {
        this.$message({
          message: "请选择左侧部门",
          type: "error",
        });
        return;
      }
      this.$util.clearObj(this.mchDeptForm);
      this.dialogDeptFormVisible = true;
      this.mchDeptForm.parentId = this.curDept.id;
    },
    submitDept2() {
      if (this.mchDeptForm.deptId) {
        this.$request.mchUserDeptUpdate(this.mchDeptForm).then((res) => {
          if (res.code == 0) {
            this.showDeptDialog = false;
            this.$util.clearObj(this.mchDeptForm);
            this.$request.mchUserDeptTree().then((res2) => {
              if (res2.code == 0) {
                this.tree[0].label = res2.data.label;
                this.tree[0].id = res2.data.id;
                this.tree[0].children = res2.data.children;
                this.deptTitels = "";
                this.curDept.id = "";
                this.curDept.label = "";
                this.dialogDeptFormVisible = false;
              }
            })
          }
        });
      } else {
        this.$request.mchUserDeptSave(this.mchDeptForm).then((res) => {
          if (res.code == 0) {
            this.showDeptDialog = false;
            this.$util.clearObj(this.deptForm);
            this.$request.mchUserDeptTree().then((res2) => {
              if (res2.code == 0) {
                this.tree[0].label = res2.data.label;
                this.tree[0].id = res2.data.id;
                this.tree[0].children = res2.data.children;
                this.deptTitels = "";
                this.curDept.id = "";
                this.curDept.label = "";
                this.dialogDeptFormVisible = false;
              }
            })
          }
        });
      }
    },
    merchantUserList(page) {
      var that = this;
      this.$request.merchantUserList({
        limit: this.queryInfo.pageSize,
        page: page || this.queryInfo.currentPage,
        ...this.queryForm,
      }).then((res) => {
        if (res.code == 0) {
          this.deptTitels = "";
          this.curDept.id = "";
          this.curDept.label = "";
          that.tableData = res.data.userPage.records;
          this.queryInfo.total = res.data.userPage.total;
          this.queryInfo.currentPage = res.data.userPage.current;
          if (res.data.deptTree) {
            // 树数据
            that.tree = [{
              label: "",
              id: "",
              deptPath: "",
              children: [],
            }, ];
            that.tree[0].label = res.data.deptTree.label;
            that.tree[0].id = res.data.deptTree.id;
            that.tree[0].children = res.data.deptTree.children;
            that.tree[0].deptPath = res.data.deptTree.deptPath;
          } else {
            that.tree = [];
          }

        }
      });
    },
    merchantUserList2(page) {
      var that = this;
      this.$request.merchantUserList2({
        limit: this.queryInfo.pageSize,
        page: page || this.queryInfo.currentPage,
        deptId: this.curDept.id,
        ...this.queryForm,
      }).then((res) => {
        if (res.code == 0) {
          that.tableData = res.data.records;
          this.queryInfo.total = res.data.total;
          this.queryInfo.currentPage = res.data.current;
        }
      });
    },
    reduceQueryFormTime(days) {
        if(days == 7){
            this.choose7 = true;
            this.choose30 = false;
            this.choose180 = false;
        }else if (days == 30){
            this.choose7 = false;
            this.choose30 = true;
            this.choose180 = false;
        }else if (days == 180){
            this.choose7 = false;
            this.choose30 = false;
            this.choose180 = true;
        }
      var date = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      let formatDate1 = this.$util.formatDate(date);
      date.setDate(date.getDate() - (days + 1));
      date = new Date(new Date(date.toLocaleDateString()).getTime());
      let formatDate2 = this.$util.formatDate(date);
      this.$set(this.queryForm2, "createTime", [formatDate2, formatDate1]);
    },
    deptCurrentChange(res) {
      this.deptTitels = res.deptPath.slice(1);
      this.curDept.id = res.id;
      this.curDept.label = res.label;
      this.merchantUserList2(1);
    },

    rowClass({
      row,
      rowIndex
    }) {
      return "background:rgba(242, 242, 242, 1);font-size: 13px;";
    },
    queryMchSelect(queryName) {
      if (queryName !== '') {
        this.$request.deptGetOrgOrMchList({
          workType: "2",
          queryName: queryName
        }).then((res) => {
          if (res.code == 0) {
            this.mchIdSelctList = res.data;
          }
        });
      }
    },
    queryOrgSelect(queryName) {
      this.$request.deptGetOrgOrMchList({
        workType: "1",
        queryName: queryName
      }).then((res) => {
        if (res.code == 0) {
          this.orgIdSelctList = res.data;
        }
      });
    },
    resetClick() {
      this.$util.clearObj(this.queryForm);
    },
    loadData(page) {
      this.$request.orgMchUserList({
          limit: this.queryInfo.pageSize,
          page: page || this.queryInfo.currentPage,
          ...this.queryForm2,
        })
        .then((res) => {
          if (res.code == 0) {
            this.orgMchUserRecords = res.data.records;
            this.queryInfo.total = res.data.total;
            this.queryInfo.currentPage = res.data.current;
          }
        });
    },
    orgMchUserExport() {
      this.$request.orgMchUserExport(this.queryForm2)
        .then((res) => {
          if (res.code == 0) {
            window.open(res.data.url);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
  .box-card {
    min-height: 800px;
  }
.header {
  display: flex;
  align-items: center;
  padding: 10px 10px 8px;

  .el-input {
    height: 32px;
    margin-right: 15px;
  }
}
.dialog-footer {
  /deep/.el-button {
    width: 60px;
    height: 32px;
    border-radius: 4px;
    padding: 0;
  }
  /deep/.el-button--primary {
    background-color: #4A7DFA;
    border-color: #4A7DFA;
  }
}
.el-tree {
  padding: 10px;
  flex: 1;
  font-size: 14px;

  .el-icon-more {
    color: #909399;
  }
}

.custom-tree-node {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header {
  padding: 2px 0px;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }

  .right {
    display: flex;
    align-items: center;
  }
}

.header2 {
  margin: 15px 0 10px 10px;
}
.flexEnd{
  display: flex;
  justify-content: flex-end;
}

.el-table-bg {
  margin: 6px 10px 10px 10px;
}

.footer {
  position: fixed;
  bottom: 20px;
  right: 15px;

  .el-pagination {}
}

.pagefooter {
  position: absolute;
  right: 0px;
  margin-top: 20px;
  margin-bottom: 124px;
  //display: block;
}

.del .tip .tip-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.el-table th,
.el-table tr {
  font-size: 13px;
}

/deep/.el-table__header tr,
/deep/.el-table__header th {
  padding: 0;
  height: 40px;
}

/deep/.el-main {
  overflow: hidden !important;
}

.input-box {
  padding: 10px 10px 10px 15px;
  box-sizing: border-box;

  .el-col-6 {
    width: 25%;
    white-space: nowrap;
    margin-bottom: 12px;

    .label {
      font-size: 14px;
      float: left;
      line-height: 32px;
    }

    .title-input {
      width: 73%;
      float: right;
    }
  }

  .el-col-8 {
    .el-range-editor.el-input__inner {
      width: 77.5%;
      float: right;
      margin-right: 10px;
    }

    .label {
      font-size: 14px;
      float: left;
      line-height: 32px;
    }
  }

  .el-col-4 {
    .defaultBtn {
      width: 67px;
      height: 32px;
      border: none;
      outline: none;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #606266;
      cursor: pointer;
      float: right;

      &:active {
        background: #4065e0;
        color: #ffffff;
      }

      &:hover {
        background: #4065e0;
        color: #ffffff;
      }
    }

    .label {
      font-size: 14px;
      color: #909399;
      font-family: MicrosoftYaHei;
      white-space: nowrap;
      margin-right: 10px;
    }
  }
}

/deep/.el-table td,
.el-table th {
  text-align: center;
}

.accountStatus {
  display: flex;

  /deep/ .el-form-item__content {
    margin-left: 10px !important;
  }

  // 文件上传的弹框样式开始
  /deep/.el-dialog__title {
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #303133;
  }

  .txt {
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #303133;

    .downTxt {
      color: #4A7DFA;
    }
  }

  .progress {
    .fileName {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #303133;
      margin-bottom: 10px;
      display: inline-block;
    }

    /deep/.el-progress {
      margin-bottom: 12px;
    }
  }

  // 文件上传的弹框样式结束
}

.m_body {
  /deep/.el-card__body {
    padding: 0 12px;
    margin-top: 15px;

  }

  /deep/.el-tree {
    padding-left: 0;
  }
}

.departmentWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  .department {
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    display: inline-block;
    margin-right:10px;
    text-align: center;
  }
}
.choose-days {
  width: 67px;
  height: 32px;
  margin-top: 3px;
  margin-left: 9px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.choose-days-selected {
  background: #4065E0;
  color: #FFFFFF;
}
.search-form {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #909399;
  line-height: 14px;
}
.search-input  /deep/.el-input__inner {
  height: 32px!important;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
}
.vue-treeselect {
  width: 200px;
  line-height: 32px;
}
.el-range-editor.el-input__inner {
  display: inline-flex;
  align-items: center;
  padding: 0px 10px;
}
.search-button {
  height: 52px;
  text-align: center;
  line-height: 48px;
  border-bottom: 1px solid #DCDFE6;
}

.op-botton{
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: right;
}
/deep/  .el-table th, .el-table tr {
  background-color: #F5F5F5;
  color: #909399;
}
/deep/ .el-table td {
  padding: 0px 0;
  height: 50px;
}
/deep/ .el-table th {
  padding: 0px 0;
  height: 50px;
}
.del .tip .tip-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
</style>
