<template>
    <el-card style="width: 100%;min-width: 1300px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
            <div style="height: 47px;">
                <el-form-item label="创建时间" labelWidth="100px">
                    <el-date-picker
                            v-model="queryForm.createTime"
                            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%;height: 32px"
                    >
                    </el-date-picker>
                </el-form-item>
                <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
                <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
                <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
            </div>
            <div  style="height: 47px">
                <el-form-item label="渠道类型" class="search-form-item" labelWidth="100px">
                    <el-select v-model="queryForm.orgType" placeholder="渠道类型" clearable class="search-input">
                        <el-option label="总渠道" value="1"></el-option>
                        <el-option label="子渠道" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="渠道名称" labelWidth="100px">
                    <el-input v-model="queryForm.orgName" placeholder="渠道名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="渠道编号" labelWidth="100px">
                    <el-input v-model="queryForm.orgNo" placeholder="渠道编号" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="渠道状态" labelWidth="100px">
                    <el-select v-model="queryForm.orgStatus" clearable placeholder="渠道状态" class="search-input">
                        <el-option label="激活" value="on"></el-option>
                        <el-option label="禁用" value="off"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div  style="height: 47px" >
                <el-form-item label="上级渠道编号" class="search-input" labelWidth="100px">
                    <el-input v-model="queryForm.superOrgNo" placeholder="上级渠道编号" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="到期状态" labelWidth="100px">
                    <el-select v-model="queryForm.expired" clearable placeholder="到期状态" class="search-input">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已到期" value="1"></el-option>
                        <el-option label="未到期" value="0"></el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div class="op-botton">
            <el-button type="primary" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick()">新增</el-button>
            <el-button size="small" style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;" @click="createExport">导出</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                    :data="records"
                    border
                    :stripe="true">
                <el-table-column
                        prop="orgName"
                        label="渠道名称"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="orgNo"
                        show-overflow-tooltip
                        label="渠道编号">
                </el-table-column>
                <el-table-column
                        prop="orgType"
                        show-overflow-tooltip
                        label="渠道类型">
                    <template slot-scope="scope">
                        <div>{{ scope.row.orgType == 1 ? "总渠道" : "子渠道" }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orgStatus"
                        show-overflow-tooltip
                        label="状态">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.orgStatus"
                                active-value="on"
                                inactive-value="off"
                                active-color="#4A7DFA"
                                inactive-color="#E4E7ED"
                                @change=changeSwitch($event,scope.row,scope.$index)>
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="superBaseOrgDTO.orgName"
                        show-overflow-tooltip
                        label="上级渠道">
                </el-table-column>
                <el-table-column
                        prop="superBaseOrgDTO.orgNo"
                        show-overflow-tooltip
                        label="上级渠道编号">
                </el-table-column>
                <el-table-column
                        prop="expireDate"
                        show-overflow-tooltip
                        label="到期时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.expired == 0">{{scope.row.expireDate}}</span>
                            <span v-else style="color: red">{{scope.row.expireDate}}</span>
                        </template>
                </el-table-column>
                <el-table-column
                        prop="bigMchStr"
                        show-overflow-tooltip
                        label="大商户模式">
                </el-table-column>
                <el-table-column
                        prop="createUserName"
                        show-overflow-tooltip
                        label="创建人">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="操作"
                        align="right"
                        width="200"
                        fixed="right">
                    <template slot-scope="scope" >
                        <div class="controls">
                            <el-button
                                    size="small"
                                    type="text"
                                    @click="editClick(scope.row)"
                            >编辑
                            </el-button
                            >
                            <el-button
                                    size="small"
                                    type="text"
                                    @click="deleteClick(scope.row)"
                            >删除
                            </el-button
                            >
                            <el-button size="small" type="text"
                                       v-clipboard:copy="scope.row.payUrl"
                                       v-clipboard:success="copySuccess"
                                       v-clipboard:error="copyError"
                            >缴费链接</el-button>
                            <el-button
                                    size="small"
                                    type="text"
                                    @click="xuqiClick(scope.row)"
                            >续期</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
        </div>
        <!-- 编辑弹框内容-->
        <el-dialog title="新增/编辑" :visible.sync="dialogFormVisible">
            <el-form :model="curDataForm" status-icon :rules="rules" ref="curDataForm">
                <el-form-item label="渠道类型" :label-width="formLabelWidth" prop="orgType" required  v-show="orgTypeVisible" >
                    <el-select style="width: 100%" v-model.trim="curDataForm.orgType" v-bind:disabled="orgTypeDisabled"
                               required="true" placeholder="请选择" @change="changeOrgList(curDataForm.orgType)">
                        <el-option
                                v-for="item in orgTypeList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属网关" :label-width="formLabelWidth" prop="gatewayType" v-show="gatewayTypeVisible" >
                    <el-select style="width: 100%" v-model.trim="curDataForm.gatewayType" placeholder="请选择">
                        <el-option
                                v-for="item in gatewayTypeList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上级渠道" :label-width="formLabelWidth" required v-show="parentIdVisible">
                    <el-select style="width: 100%" v-model.trim="curDataForm.parentId" placeholder="请选择" >
                        <el-option
                                v-for="item in orgItem"
                                :key="item.orgId"
                                :label="item.orgName"
                                :value="item.orgId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="渠道名称" :label-width="formLabelWidth" prop="orgName" required>
                    <el-input
                            v-model.trim="curDataForm.orgName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="渠道编号" :label-width="formLabelWidth" required  prop="orgNo">
                    <el-input
                            v-model.trim="curDataForm.orgNo"
                            autocomplete="off"
                            v-bind:disabled="orgNoDisabled"
                    ></el-input>
                </el-form-item>
                <el-form-item label="登录名称" :label-width="formLabelWidth" required  v-show="loginNameVisible">
                    <el-input
                            v-model.trim="curDataForm.loginName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" >
                    <el-input
                            v-model.trim="curDataForm.remark"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="渠道大商户" :label-width="formLabelWidth" required v-show="parentIdVisible" >
                    <el-radio v-model="curDataForm.bigMch" label="YES" v-bind:disabled="curDataForm.orgId">是</el-radio>
                    <el-radio v-model="curDataForm.bigMch" label="NO" v-bind:disabled="curDataForm.orgId">否</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit('curDataForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 续期弹框内容-->
        <el-dialog title="续期" :visible.sync="xuqiFormVisible">
            <el-form>
                <el-form-item label="渠道名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="curDataForm.orgName"
                            autocomplete="off"
                            :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="续期(月)" :label-width="formLabelWidth" >
                    <el-input v-model.trim="curDataForm.valiPeriod" on-bl label="续期(月)" type="number" @blur="getExpireDate()"></el-input>
                </el-form-item>
                <el-form-item label="到期时间" :label-width="formLabelWidth" >
                    <el-input
                            v-model.trim="curDataForm.expireDate"
                            autocomplete="off"
                            :disabled="true"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="xuqiFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="xuqiSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 删除内容-->
        <el-dialog
                class="del"
                title="删除"
                :visible.sync="dialogDeleteVisible"
                width="30%"
                center
        >
            <div class="tip">
                <img
                        class="tip-icon"
                        src="../../../assets/images/wra-icon.png"
                        alt=""
                />
                <span class="txt">删除后所管商户将会被纳入上级渠道管理，请确认！</span>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
        </el-dialog>
    </el-card>
</template>

<script>
    import Clipboard from "clipboard";
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    export default {
        name: "org", // 渠道管理
        data() {
            var checkRequired = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('必填项不能为空'));
                }
            };
            return {
                limit: "2",
                page: "1",
                choose7: false,
                choose30: false,
                choose180: false,
                startTime:"",
                endTime:"",
                records: [],
                curDataForm: {
                },
                orgItem:[],
                orgTypeList:[{"value":1,"name":"总渠道"},{"value":2,"name":"子渠道"}],
                gatewayTypeList:[
                    {"value":"hulu", "name":"葫芦"},
                    {"value":"jl", "name":"嘉联"},
                    {"value":"hsty", "name":"汇商通盈"}
                ],
                queryForm: {
                    name: "", // 项目名称
                    createUserName: "", // 创建人
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                dialogCode: false, // 重置密码弹框
                dialogFormVisible: false, // 编辑弹框
                xuqiFormVisible: false,// 续期弹框
                dialogDeleteVisible: false, // 删除弹框
                formLabelWidth: "120px",
                loginNameVisible:true,
                orgNoDisabled:false,
                orgTypeVisible:true,
                gatewayTypeVisible:false,
                parentIdVisible:true,
                orgTypeDisabled:false,
                rules: {
                    orgType: [
                        { required: true, message: '请选择渠道类型', trigger: 'blur' },
                    ],
                    orgName: [
                        { required: true, message: '请输入渠道名称', trigger: 'blur' },
                    ],
                    orgNo: [
                        { required: true, message: '请输入渠道编号', trigger: 'blur' },
                    ],
                },
                userType:"",
            };
        },
        created() {
            this.loadData();
            this.loadOrgItem();
            let curUser = localStorage.getItem('curUserInfo123');
            curUser = JSON.parse(curUser)
            this.userType = curUser.userType;
        },
        computed: {},
        methods: {
            loadData(page) {
                this.$request
                    .bashOrgList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            getExpireDate() {
                this.$request
                    .getExpireDate({
                        valiPeriod: this.curDataForm.valiPeriod,
                        mchId: this.curDataForm.orgId,
                        expireType: "org"
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.$set(this.curDataForm,'expireDate',res.data.expireDate)
                        }
                    });
            },
            copySuccess(){
                this.$message({message:"已复制至剪贴板", type: "success",});
            },
            copyError(){
                this.$message({message:"失败", type: "error",});
            },
            loadOrgItem(){
                this.$request
                    .getOrgListByLoginUser({
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.orgItem = res.data;
                        }
                    });
            },

            createExport() {
                this.$request
                    .bashOrgExport({
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            changeOrgList(orgType) {
                if(orgType == 1){
                    this.parentIdVisible = false;
                    this.gatewayTypeVisible = true;
                }else{
                    this.parentIdVisible = true;
                    this.gatewayTypeVisible = false;
                }
            },
            reduceQueryFormTime(days){
                if(days == 7){
                    this.choose7 = true;
                    this.choose30 = false;
                    this.choose180 = false;
                }else if (days == 30){
                    this.choose7 = false;
                    this.choose30 = true;
                    this.choose180 = false;
                }else if (days == 180){
                    this.choose7 = false;
                    this.choose30 = false;
                    this.choose180 = true;
                }
                var date = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
                let formatDate1 = this.$util.formatDate(date);
                date.setDate(date.getDate() - (days+1));
                date = new Date(new Date(date.toLocaleDateString()).getTime());
                let formatDate2 = this.$util.formatDate(date);
                this.$set(this.queryForm, "createTime", [formatDate2, formatDate1]);
            },
            changeSwitch(event, data, index){
                var reqData = {};
                reqData.orgId=data.orgId;
                reqData.orgStatus=data.orgStatus;
                this.$request
                    .bashOrgUpdate(reqData)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message:"成功",
                                type: "success",
                            });
                        }
                    });
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            createClick() {
                this.loginNameVisible=true;
                this.orgNoDisabled = false;
                this.$util.clearObj(this.curDataForm);
                this.dialogFormVisible = true;
                if(this.userType == 'ORG'){
                    this.curDataForm.orgType=2;
                    this.orgTypeDisabled = true;
                }else{
                    this.orgTypeDisabled = false;
                }

            },
            editClick(item) {
                this.loginNameVisible=false;
                this.orgNoDisabled = true;
                this.orgTypeDisabled = true;
                this.$request
                    .bashOrgInfo(item)
                    .then((res) => {
                        if (res.code == 0) {
                            this.curDataForm = res.data;
                            if(this.curDataForm.orgType == 1){
                                this.gatewayTypeVisible = true;
                                this.parentIdVisible = false;
                            }else{
                                this.parentIdVisible = true;
                                this.gatewayTypeVisible = false;
                            }
                        }
                    });
                this.dialogFormVisible = true;
            },
            xuqiClick(item) {
                this.$request.bashOrgInfo(item).then((res) => {
                    if (res.code == 0) {
                        this.curDataForm = res.data;
                        this.xuqiFormVisible = true;
                    }
                });
            },
            deleteClick(item) {
                this.deleteItem = item;
                this.dialogDeleteVisible = true;
            },
            deleteSure() {
                this.$request.bashOrgDelete(this.deleteItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            submit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return false;
                    }else{
                        if (!this.curDataForm.orgId) {
                            let curDataForm = {...this.curDataForm};
                            this.$request.bashOrgSave(curDataForm).then((res) => {
                                if (res.code == 0) {
                                    this.$alert(res.data, {
                                        confirmButtonText: '确定',
                                        callback: action => {
                                        }
                                    });
                                    this.dialogFormVisible = false;
                                    this.$util.clearObj(this.curDataForm);
                                    this.loadData();
                                    this.loadOrgItem();
                                }
                            });
                        } else {
                            this.$request
                                .bashOrgUpdate({
                                    ...this.curDataForm,
                                })
                                .then((res) => {
                                    if (res.code == 0) {
                                        this.$message({
                                            message: "成功",
                                            type: "success",
                                        });
                                        this.dialogFormVisible = false;
                                        this.loadData();
                                        this.loadOrgItem();
                                        this.$util.clearObj(this.curDataForm);
                                    }
                                });
                        }
                    }
                });
            },
            xuqiSubmit() {
                this.$request
                    .bashOrgXuqi({
                        ...this.curDataForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.xuqiFormVisible = false;
                            this.loadData();
                            this.$util.clearObj(this.curDataForm);
                        }
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
    .choose-days {
        width: 67px;
        height: 32px;
        margin-top: 3px;
        margin-left: 9px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 32px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
    .search-input  /deep/.el-input__inner {
        height: 32px!important;
        width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    .vue-treeselect {
        width: 200px;
        line-height: 32px;
    }
    .el-range-editor.el-input__inner {
        display: inline-flex;
        align-items: center;
        padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

    .op-botton{
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: right;
    }
    /deep/  .el-table th, .el-table tr {
        background-color: #F5F5F5;
        color: #909399;
    }
    /deep/ .el-table td {
        padding: 0px 0;
        height: 50px;
    }
    /deep/ .el-table th {
        padding: 0px 0;
        height: 50px;
    }
    .del .tip .tip-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
</style>
