<template>
    <div >
        <div>
            <el-form>
                <el-form-item label="渠道名称" :label-width="formLabelWidth" >
                    <span>{{orgConf.orgName}}</span>
                </el-form-item>
                <el-form-item label="缴费链接" :label-width="formLabelWidth" >
                    <span>{{orgConf.payUrl}}</span>
                    <span  class="copyPayUrl"
                                                             v-clipboard:copy="orgConf.payUrl"
                                                             v-clipboard:success="copySuccess"
                                                             v-clipboard:error="copyError">复制链接</span>
                </el-form-item>
                <el-form-item label="缴费二维码" :label-width="formLabelWidth" >
                    <vue-qr ref="Qrcode" :text="orgConf.payUrl" download="downloadFilename" :margin="0" :size="110"></vue-qr>
                        <div class="down">
                            <img class="down-icon" src="../../../assets/images/download.png" alt />
                            <p class="downTxt" @click="downQrCode">下载二维码</p>
                        </div>

                </el-form-item>
                <el-form-item label="渠道密钥" :label-width="formLabelWidth" >
                    <span  class="copyPayUrl" @click="keySetFormClick" plain size="mini">{{keySettingDesc}}</span>
                </el-form-item>
                <el-form-item label="公众号设置" :label-width="formLabelWidth" required>
                    <span  class="copyPayUrl" @click="wechatSetFormClick" plain size="mini">{{wechatSettingDesc}}</span>
                    <span  class="copyPayUrl" @click="clearOrgWechatConf" plain size="mini" v-show="wechatSettingDesc=='查看'">清空</span>
                </el-form-item>
                <el-form-item label="logo图标" :label-width="formLabelWidth" required>
                    <span  class="copyPayUrl" @click="logoFormClick" plain size="mini">设置</span>
                </el-form-item>
            </el-form>
        </div>
        <!-- 设置/查看密钥弹框-->
        <el-dialog title="logo设置" :visible.sync="dialogLogoVisible">
            <el-form :model="logoForm">
                <el-form-item label="logo图标" :label-width="formLabelWidth" required>
                    <el-upload
                            class="avatar-uploader"
                            action="/charge/admin/file/upload"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            :before-upload="handlePreview">
                        <div class="avatar">
                            <img v-if="logoForm.logoUrl" :src="logoForm.logoUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <!--<span class="el-upload-list__item-actions">-->
                                 <!--<i class="el-icon-delete" style="cursor: pointer"></i>-->
                            <!--</span>-->
                        </div>
                    </el-upload>
                    <el-button type="primary" @click="deleteLogoUrl">删除</el-button>
                </el-form-item>
                <el-form-item label="名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model="logoForm.logoName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogLogoVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveLogoForm">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 设置/查看密钥弹框-->
        <el-dialog title="设置密钥" :visible.sync="dialogSetVisible">
            <el-form :model="setForm">
                <el-form-item label="签名类型" :label-width="formLabelWidth" required v-show="setForm.gatewayType == 'hsty'">
                    <el-select v-model="setForm.signType"  placeholder="请选择">
                        <el-option
                                v-for="item in signTypeList"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                        ></el-option>
                    </el-select> 
                </el-form-item>
                <el-form-item label="md5密钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'MD5'">
                    <el-input v-model="setForm.md5Key" type="textarea" autocomplete="off" placeholder="请输入md5密钥"></el-input>
                </el-form-item>
                <el-form-item label="RSA私钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'RSA' || setForm.gatewayType != 'hsty'">
                    <el-input v-model="setForm.outPriKey" type="textarea" autocomplete="off" placeholder="请输入商户RSA私钥"></el-input>
                </el-form-item>
                <el-form-item label="RSA公钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'RSA' || setForm.gatewayType != 'hsty'">
                    <el-input v-model="setForm.outPubKey" type="textarea" autocomplete="off" placeholder="请输入商户RSA公钥"></el-input>
                </el-form-item>
                <el-form-item label="平台公钥" :label-width="formLabelWidth" required v-show="setForm.signType == 'RSA' || setForm.gatewayType != 'hsty'">
                    <el-input v-model="setForm.platPubKey" type="textarea" autocomplete="off" placeholder="请输入平台公钥"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSetVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSetForm">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 编辑弹框内容-->
        <el-dialog title="配置公众号" :visible.sync="dialogFormVisibleWechat">
            <el-form>
                <el-form-item label="公众号名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model="orgWechat.wechatName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppId" :label-width="formLabelWidth" required>
                    <el-input
                            v-model="orgWechat.appId"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppSecret" :label-width="formLabelWidth" required>
                    <el-input
                            v-model="orgWechat.appSecret"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="公众号文件" :label-width="formLabelWidth" required>
                    <el-upload
                            class="upload-demo"
                            action="/charge/admin/file/upload/file"
                            :file-list="orgWechat.fileList"
                            :on-success="handleTxtSuccess"
                            :on-remove="handleTxtRemove"
                            :on-exceed="onExceed"
                            :beforeUpload="beforeAvatarUpload"
                            limit="1"
                    >
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传txt文件，且不超过10kb</div>
                    </el-upload>
                </el-form-item>
                <el-form-item
                        class="accountStatus"
                        label="消息推送"
                        :label-width="formLabelWidth"
                        required
                >
                    <el-radio v-model="orgWechat.status" label="on"
                    >启用
                    </el-radio
                    >
                    <el-radio v-model="orgWechat.status" label="off"
                    >禁用
                    </el-radio
                    >
                </el-form-item>
            </el-form>
            <el-button
                    icon="el-icon-plus"
                    plain
                    type="primary"
                    size="mini"
                    @click="createRowClick()"
            >添加</el-button>
            <el-table :data="orgWechat.templateList" border style="width: 100%">
                <el-table-column prop="index" label="序号" width="180"></el-table-column>
                <el-table-column prop="messageId" label="消息模板ID" width="180">
                    <template slot-scope="scope">
                        <el-input
                                v-model="scope.row.messageId"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="标题">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.type" placeholder="请选择">
                            <el-option label="账单提醒" value="bill"></el-option>
                            <el-option label="支付结果通知" value="paynotice"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="status" width="80" label="状态">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.status"
                                active-value="on"
                                inactive-value="off"
                                active-color="#4A7DFA"
                                inactive-color="#E4E7ED"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="right" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                type="danger"
                                plain
                                size="small"
                                @click="deleteRowClick(scope.row)"
                        >删除
                        </el-button >
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleWechat = false">取 消</el-button>
                <el-button type="primary" @click="submitWechat">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 删除内容-->
        <el-dialog
                class="del"
                title="确认清空？"
                :visible.sync="clearOrgWechatConfVisiable"
                width="30%"
                center
        >
            <div class="tip">
                <img
                        class="tip-icon"
                        src="../../../assets/images/wra-icon.png"
                        alt=""
                />
                <span class="txt">确定清空将无法恢复，请确认！</span>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="clearOrgWechatConfVisiable = false">取 消</el-button>
        <el-button type="primary" @click="clearOrgWechatConfSure">确 定</el-button>
      </span>
        </el-dialog>
    </div>

</template>

<script>
    import VueQr from 'vue-qr';
    import Clipboard from "clipboard";
    export default {
        components:{
            VueQr
        },
        name: "account", // 账户管理
        data() {
            return {
                clearOrgWechatConfVisiable:false,
                dialogSetVisible:false,
                dialogFormVisibleWechat:false,
                dialogLogoVisible:false,
                formLabelWidth:"120px",
                keySettingDesc:"",
                wechatSettingDesc:"",
                logoForm:{
                    logoUrl:"",
                    logoName:""
                },
                orgConf:{
                    orgName:"",
                    payUrl:"",
                    logoUrl:""
                },
                signTypeList:[
                    {code:"MD5", name:"MD5"},
                    {code:"RSA", name:"RSA"}
                ],
                fileId:"",
                index : 0,
                orgWechat:{
                    templateList:[],
                    fileList:[],
                        fileId:""
                },
                setForm: {
                    signType: "RSA",
                    outPriKey: "", // 支付通道
                    outPubKey: "", // 加密类型
                    platPubKey: "", // MD5密钥
                    gatewayType: "jl" // MD5密钥
                },
            }

        },
        created() {
            this.loadConf();
            this.$request.baseMchConfGetKeyInfo({})
                .then((res) => {
                    if(res.data.outPriKey){
                        this.keySettingDesc="查看";
                    }else{
                        this.keySettingDesc="设置";
                    }
                });
            this.$request.wechatInfo({})
                .then((res) => {
                    if(res.data.appId){
                        this.wechatSettingDesc="查看";
                    }else{
                        this.wechatSettingDesc="设置";
                    }
                });
        },
        mounted() {
        },
        computed: {},
        methods: {
            loadConf() {
                this.$request.orgConfConf({})
                    .then((res) => {
                        if (res.code == 0) {
                            this.orgConf = res.data;
                            this.creatQrCode(this.orgConf.payUrl);
                        }
                    });
            },
            clearOrgWechatConf(){
                this.clearOrgWechatConfVisiable = true;
            },
            clearOrgWechatConfSure(){
                this.$request.clearOrgWechatConf({})
                    .then((res) => {
                        if (res.code == 0) {
                            this.clearOrgWechatConfVisiable = false;
                            this.wechatSettingDesc="设置";
                        }
                    });
            },
            deleteLogoUrl(){
              this.logoForm.logoUrl = "";
            },
            onExceed(){
                this.$message({message:"上传超过最大限制", type: "error",});
            },
            handleTxtSuccess(res){
                this.orgWechat.fileId = res.data.fileId;
            },
            handleTxtRemove(res){
                this.orgWechat.fileId = "";
            },
            createRowClick (){
                this.index = this.index+1;
                var row = {};
                row.index = this.index;
                row.messageId = "";
                row.type = "";
                row.status = "off";
                this.orgWechat.templateList.push(row);
            },
            deleteRowClick(row){
                var arr = [];
                this.orgWechat.templateList.forEach(e =>{
                        if(row.index != e.index){
                            arr.push(e);
                        }
                });
                this.orgWechat.templateList=arr;
            },
            beforeAvatarUpload(file){
                var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
                const extension = testmsg === 'txt'
                const isLt10K = file.size / 1024 < 10
                if(!extension) {
                    this.$message({
                        message: '上传文件只能是txt格式!',
                        type: 'error'
                    });
                    return false;
                }
                if(!isLt10K) {
                    this.$message({
                        message: '上传文件大小不能超过 10KB!',
                        type: 'error'
                    });
                    return false;
                }
            },
            submitWechat(){
                this.$request.orgConfWechatSave(this.orgWechat)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({message:"成功", type: "success",});
                            this.dialogFormVisibleWechat = false;
                        }
                    });
            },
            copySuccess(){
                this.$message({message:"成功", type: "success",});
            },
            copyError(){
                this.$message({message:"失败", type: "error",});
            },
            downQrCode(){
                const iconUrl = this.$refs.Qrcode.$el.src
                let a = document.createElement('a')
                let event = new MouseEvent('click')
                a.download = this.orgConf.orgName+"-缴费二维码";
                a.href = iconUrl
                a.dispatchEvent(event)
            },
            creatQrCode(text) {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text:text,
                    width: 100,
                    height: 100,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            keySetFormClick() {
                this.dialogSetVisible = true;
                this.$request.baseMchConfGetKeyInfo({})
                    .then((res) => {
                        this.setForm = res.data;
                    });
            },
            saveSetForm(){
                this.$request.baseMchConfSaveOrUpdateOrgMchKey(this.setForm)
                    .then((res) => {
                        if(res.code == 0){
                            this.$message({message:"成功",type: "success",});
                            this.dialogSetVisible = false;
                        }
                    });
            },
            wechatSetFormClick() {
                this.$request.wechatInfo({})
                    .then((res) => {
                        if(res.code == 0){
                            this.orgWechat = res.data;
                            this.dialogFormVisibleWechat = true;
                        }
                    });
            },
            logoFormClick() {
                this.$request.logoInfo({})
                    .then((res) => {
                        if(res.code == 0){
                            this.logoForm.logoUrl = res.data.logoUrl;
                            this.logoForm.logoName = res.data.logoName;
                            this.dialogLogoVisible = true;
                        }
                    });
            },
            saveLogoForm() {
                this.$request.updateLogoInfo(this.logoForm)
                    .then((res) => {
                        if(res.code == 0){
                            this.$message({message:"成功",type: "success",});
                            this.dialogLogoVisible = false;
                        }
                    });
            },
            handleSuccess(res) {
                this.logoForm.logoUrl = res.data.url;
//                this.$request.orgConfSaveLogoUrl(this.orgConf)
//                    .then((res) => {
//                    });
            },
            handlePreview (file) {
                if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
                    this.$message.error('只支持jpg、png格式的图片！')
                    return false
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "~@/assets/style/element";
    .qrcode{
        display: inline-block;
        img {
            width: 132px;
            height: 132px;
            background-color: #fff; //设置白色背景色
            padding: 6px; // 利用padding的特性，挤出白边
            box-sizing: border-box;
        }
    }
    .el-form-item__label {
        text-align: right;
        vertical-align: middle;
        float: left;
        font-size: 14px;
        line-height: 40px;
        padding: 0 20px 0 0;
        box-sizing: border-box;
        font-family: MicrosoftYaHei;
        color: #606266;
    }
    .el-form-item {
        margin-bottom: 0px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .copyPayUrl{
        margin-left: 10px;
        font-family: MicrosoftYaHei;
        color: #4065E0;
        cursor:pointer;
    }
    .down {
        .down-icon {
            height: 18px;
            position: relative;
            top: 2px;
        }
        .downTxt {
            font-size: 15px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #4a7dfa;
            line-height: 20px;
            margin-top: 5px;
            display: inline-block;
            cursor: pointer;
        }
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
        position: relative;
    }
    .el-upload-list__item-actions {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: default;
        text-align: center;
        color: #fff;
        opacity: 0;
        font-size: 20px;
        background-color: rgba(0,0,0,.5);
        transition: opacity .3s;
        text-align: center;
        vertical-align: middle;
    }
     .el-upload-list__item-actions:after {
        display: inline-block;
        content: "";
        height: 100%;
        vertical-align: middle;
    }
    .el-upload-list__item-actions:hover {
        opacity: 1;
    }
</style>
