//import Vue from 'vue'

import store from './store'
import '@/style.less';
//import ElementUI from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.css';
import  * as echarts from 'echarts';

Vue.prototype.$echarts = echarts  
//Vue.use(ElementUI);

Vue.config.productionTip = false

import util from '@/utils/index.js'
Vue.prototype.$util = util
import request from '@/utils/request.js'

import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
Vue.use(VueClipboard)

Vue.prototype.$request = request

window.app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
