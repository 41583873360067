<template>
  <div>
    <div class="add">
      <el-button type="primary" @click="drawer = true" size="small">test抽屉</el-button>
    </div>
    <el-drawer class="drawer-wrap" :visible.sync="drawer" :with-header="false">
      <el-row>
        <el-col :span="24">
          <div class="drawer-title__box">创意账单/详情</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="drawer-item__btn">
            <el-button icon="el-icon-plus" size="small" type="primary" @click="addItem">添加项目</el-button>
            <transition name="active">
              <el-button
                icon="el-icon-delete"
                size="small"
                type="danger"
                @click="deleteTableItem(item, index)"
              >删除项目</el-button>
            </transition>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="tableFroms">
          <el-scrollbar style="height:100%">
            <el-form :rules="formRules" :model="tableForm" label-width="110px" size="mini">
              <!-- 固定项目 -->
              <el-form-item label="账单名称" prop="billName">
                <el-input v-model="tableForm.billName" placeholder="请输入账单名称"></el-input>
              </el-form-item>
              <el-form-item label="缴费项目" prop="paymentItems">
                <el-select
                  v-model="tableForm.paymentItems"
                  clearable
                  placeholder="请选择缴费项目"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="缴费金额(元)" prop="paymentAmount">
                <el-input v-model="tableForm.paymentAmount" placeholder="请输入缴费金额(元)"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks">
                <el-input type="textarea" v-model="tableForm.remarks"></el-input>
              </el-form-item>
            </el-form>
            <!-- 动态增加项目 -->
            <transition-group name="active">
              <div v-for="(item, index) in tableForm.dynamicItem" :key="index">
                <el-form :rules="formRules" :model="tableForm" label-width="110px" size="mini">
                  <el-form-item label="缴费项目" prop="paymentItems">
                    <el-select
                      v-model="tableForm.paymentItems"
                      clearable
                      placeholder="请选择缴费项目"
                      style="width:100%"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="缴费金额(元)" prop="paymentAmount">
                    <el-input v-model="tableForm.paymentAmount" placeholder="请输入缴费金额(元)"></el-input>
                  </el-form-item>
                  <el-form-item label="备注" prop="remarks">
                    <el-input type="textarea" v-model="tableForm.remarks"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </transition-group>
            <el-form :rules="formRules" :model="tableForm" label-width="110px" size="mini">
              <el-form-item label="部门" prop="department">
                <el-tree
                  :data="data"
                  show-checkbox
                  node-key="id"
                  :default-expanded-keys="[2, 3]"
                  :default-checked-keys="[5]"
                  :props="defaultProps"
                ></el-tree>
              </el-form-item>
              <el-form-item label="部分缴费" prop="aymentBox">
                <el-radio v-model="aymentRadio" label="1" style="padding-left:15px">全缴</el-radio>
                <el-radio v-model="aymentRadio" label="2">选缴</el-radio>
              </el-form-item>
              <el-form-item label="账单状态" prop="billingStatus">
                <el-radio v-model="billingRadio" label="1" style="padding-left:15px">激活</el-radio>
                <el-radio v-model="billingtRadio" label="2">禁用</el-radio>
              </el-form-item>
              <el-form-item label="账单有效期" prop="billingTimes">
                <el-date-picker
                  v-model="billingTimesValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width:100%"
                ></el-date-picker>
              </el-form-item>
            </el-form>
            <el-alert title="温馨提示：开始时间账单会自动激活..." type="warning" show-icon></el-alert>
          </el-scrollbar>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="drawer-item__btn form-submission">
            <el-button type="primary" size="small">确定</el-button>
            <el-button type="info" size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      billingTimesValue: "",
      billingRadio: "1",
      aymentRadio: "1",
      data: [
        {
          id: 1,
          label: "小羊有限公司",
          children: [
            {
              id: 2,
              label: "商业BU",
              children: [
                {
                  id: 5,
                  label: "研发中心",
                },
                {
                  id: 6,
                  label: "产品中心",
                  children: [
                    { id: 7, label: "基础产品组" },
                    { id: 8, label: "行业产品组" },
                  ],
                },
              ],
            },
            {
              id: 3,
              label: "金融BU",
              children: [],
            },
            {
              id: 4,
              label: "海外BU",
              children: [],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      tableValue: "",
      options: [
        { value: "选项1", label: "学费" },
        { value: "选项2", label: "书本费" },
      ],
      drawer: false, //抽屉开关
      tableForm: {
        billName: "",
        paymentAmount: "",
        paymentItems: "",
        remarks: "",
        department: "",
        aymentBox: "",
        billingStatus: "",
        billingTimes: "",
        dynamicItem: [],
      },
      formRules: {
        billName: [
          { required: true, message: "请输入账单名称", trigger: "blur" },
        ],
        paymentAmount: [
          { required: true, message: "请输入缴费金额", trigger: "blur" },
        ],
        paymentItems: [{ required: true, message: "请选择缴费项目" }],
        department: [{ required: true }],
        aymentBox: [{ required: true }],
        billingStatus: [{ required: true }],
        billingTimes: [
          { required: true, message: "请选择时间", trigger: "blur" },
        ],
        remarks: [{ required: false }],
      },
    };
  },
  methods: {
    addItem() {
      this.tableForm.dynamicItem.push({
        name: "",
        phone: "",
      });
    },
    deleteTableItem(item, index) {
      this.tableForm.dynamicItem.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-wrap {
  /deep/.el-drawer__body {
    padding: 10px;
  }
  .el-row {
    &:first-child {
      height: 45px !important;
    }
    &:nth-child(2),
    &:nth-child(3) {
      border: none !important;
      height: initial;

      /deep/.el-scrollbar__wrap {
        overflow-x: hidden;
      }
      .tableFroms {
        height: calc(100vh - 155px);
        overflow: hidden;
      }
    }
    &:nth-child(4) {
      border-top: 1px solid #ddd;
      .form-submission {
        position: relative;
        bottom: -7px;
      }
    }
  }
  /deep/.el-alert__closebtn {
    margin-left: 20px;
    position: relative;
    top: 0;
    right: 0;
  }
  /deep/.el-alert--warning {
    height: 30px;
    position: relative;
    top: -15px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0);
  }
  .drawer-item__btn {
    float: right;
    margin-bottom: 20px;
  }
  .drawer-title__box {
    border-left: 4px solid #4a7dfa;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px !important;
    padding-left: 10px;
    margin-top: 6px;
  }
  .active-enter,
  .active-leave-to {
    opacity: 0;
  }
  .active-enter-active,
  .active-leave-active {
    transition: all 0.2s ease;
  }
}
</style>