<template>
  <el-dialog title="新增/编辑部门" :visible.sync="visible" width="500px">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="40x" style="width: 80%" class="demo-ruleForm">
      <el-form-item label="部门名称" prop="deptName" >
        <el-input v-model.trim="form.deptName" autocomplete="off" ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    form:{
      deptId: "",
      deptName: ""
    },
    visible: {
      type: Boolean,
      default: function (){
        return false
      }
    }
  },
  data(){
    return {
      rules:{
        deptName: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    closeDialog(){
      this.$emit("closeDialog")
    },
    submit(form) {
      this.$refs[form].validate(valid => {
        if(valid){
          this.$emit("submit",this.form)
        }else{
          return false;
        }
      });
    }
  }
}
</script>