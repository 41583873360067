<template>
<div class="menu">
  <el-container>
    <el-aside width="180px">
      <el-menu ref="menu" :default-openeds="[titleIndexs[0]]" :default-active="'0'" text-color="white" background-color="#304156" @select="elMenuSelect" @open="elMenuOpen" @close="elMenuClose" :unique-opened="true" style="height:100%">
        <component :is="item.children && item.children.length > 0  ? `el-submenu` : `el-menu-item`" :index="index.toString()" v-for="(item, index) in menuList" :key="index">
          <template slot="title"><i class="el-icon-s-home"></i>{{ item.rbacMenu.menuName }}</template>
          <el-menu-item :index="`${index.toString()}-${index2.toString()}`" v-for="(item2, index2) in item.children" :key="index2">{{ item2.rbacMenu.menuName }}</el-menu-item>
        </component>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header
          style="text-align: right; font-size: 12px; background-color: white"
        >
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>{{
              menuList[titleIndexs[0]].rbacMenu.menuName
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="titleIndexs.length > 1">{{
              menuList[titleIndexs[0]].children[titleIndexs[1]].rbacMenu
                .menuName
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
      <!-- <el-header style="text-align: right; font-size: 12px; background-color: #edf0f4">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <span class="index" @click="goIndex">
            首页
          </span>
          <span class="menu_tab" :class="{active:activeIndex == item.menuName}" v-for="(item,index) in tabList" :key="index" @click="selectMenu(item)"> <span class="circel"></span>{{item.menuName}} <i class="el-icon-error" @click.stop="deleteTab(index)"></i></span>
        </el-breadcrumb>
      </el-header> -->
      <el-main>
        <!-- <el-table :data="tableData">
            <el-table-column prop="date" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table> -->
        <!-- <keep-alive> -->
          <router-view />
        <!-- </keep-alive> -->

      </el-main>
    </el-container>
  </el-container>
</div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations
} from "vuex";
export default {
  components: {},
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
     
    };
    return {
      tableData: Array(20).fill(item),
      tabList: [],
       activeIndex:0
    };
  },
  computed: {
    ...mapState({
      elMenuSelectValue: (state) => state.elMenuSelectValue,
      menus: (state) => state.menus,
    }),
    ...mapGetters({
      // menuList: "menuList",
    }),
    titleIndexs() {
      return this.elMenuSelectValue.split("-");
    },
    menuList() {
      let menus = [{
        rbacMenu: {
          menuName: "首页",
          url: "/home",
        },
      }, ];
      menus = menus.concat(this.menus)
      return menus;
    },
  },
  created() {
    this.$request.menuRoles()
  },
  methods: {
    ...mapMutations({
      setElMenuSelectValue: "setElMenuSelectValue",
    }),
    selectMenu(item) {
      this.activeIndex  = item.menuName
      this.setElMenuSelectValue(item.menuParam);
      let path = `/`;
      if (this.titleIndexs.length > 1) {
        path = `${
          this.menuList[this.titleIndexs[0]].children[this.titleIndexs[1]]
            .rbacMenu.url
        }`;
      } else {
        path = `${this.menuList[this.titleIndexs[0]].rbacMenu.url}`;
      }
      if (path && path != 'undefined') {
        path = path.replaceAll("'", '')
        if (this.$route.path != path) {
          this.$router.push(path);
        }
      } else {
        this.$message.error("路由不存在");
      }
      this.$refs.menu.activeIndex = item.menuParam
      this.$refs.menu.open(item.menuParam.split('-')[0])
    },
    deleteTab(index) {
      this.tabList.splice(index, 1)
    },
    elMenuSelect(res, xxx) {
      console.log(res, xxx)
      var arr = res.split('-')
      if (arr.length == 2) {
        var menuName = this.menuList[arr[0]].children[arr[1]].rbacMenu.menuName
        console.log("elMenuSelect", res, menuName);
        var flag = true
        this.tabList.forEach(element => {
          if (element.menuName == this.menuList[arr[0]].children[arr[1]].rbacMenu.menuName) {
            flag = false
          }
        });
        if (flag) {
          this.tabList.push({
            menuName,
            menuParam: res
          })
          this.activeIndex = menuName
        }
      }

      this.setElMenuSelectValue(res);
      let path = `/`;
      if (this.titleIndexs.length > 1) {
        path = `${
          this.menuList[this.titleIndexs[0]].children[this.titleIndexs[1]]
            .rbacMenu.url
        }`;
      } else {
        path = `${this.menuList[this.titleIndexs[0]].rbacMenu.url}`;
      }
      if (path && path != 'undefined') {
        path = path.replaceAll("'", '')
        if (this.$route.path != path) {
          this.$router.push(path);
        }
      } else {
        this.$message.error("路由不存在");
      }
    },
    elMenuOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    elMenuClose(res) {
      // console.log("elMenuClose", res);
    },
    goIndex(){
      this.$router.push('/home');
    }
  },
};
</script>

<style lang="less" scoped>
.menu {
  height: 100%;

  .el-aside {
    color: white;

    // background-color: #304156;
    .el-menu {
      // background-color: transparent;
      background-color: #4065E0 !important;

      .el-menu-item {
        min-width: 0;
        background-color: #3652BA !important;

        i {
          color: #ffffff;
        }
      }

      .is-active {
        color: #00FFCE;
      }

      .el-submenu {
        /deep/.el-submenu__title {
          background-color: #4065E0 !important;

          i {
            color: #ffffff;
          }
        }
      }
    }
  }

  .el-container {
    height: 100%;
    background: #f5f7f9;

    .el-header {
      background-color: #b3c0d1;
      color: #333;
      line-height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;

      .index {
        width: 48px;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        margin-right: 10px;
        cursor: pointer;
        text-align: center;
      }

      .menu_tab {
        display: inline-block;
        padding: 5px 20px;
        background-color: #4065E0;
        color: #fff;
        border-radius: 5px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        &.active{
          color: #00FFCE;
          .el-icon-error{
            color: #fff;
          }
          .circel{
            background: #00FFCE;
          }
        }
        .circel {
          display: inline-block;
          width: 4px;
          height: 4px;
          background: #fff;
          border-radius: 50%;
          margin-bottom: 3px;
          margin-right: 5px;
          
        }

        i {
          position: absolute;
          top: 5px;
          right: 0px;
          color: unset;
        }
      }
    }

    .el-main {
      padding: 10px;
      // background-color: #fff;
      background: #f0f2f7;
    }
  }
}
</style>
