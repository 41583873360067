<template>
    <el-card style="width: 100%;min-width: 1300px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="90px" >
            <div  style="height: 47px">
                <el-form-item label="媒体位" >
                    <el-select v-model="queryForm.put" placeholder="请选择"  class="search-input" clearable>
                        <el-option label="渠道首页" value="1"></el-option>
                        <el-option label="商户首页" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Banner名称">
                    <el-input v-model="queryForm.bannerName" placeholder="Banner名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="创建人">
                    <el-input v-model="queryForm.createUserName" placeholder="创建人" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="状态" >
                    <el-select v-model="queryForm.bannerStatus" placeholder="状态"  class="search-input" clearable>
                        <el-option label="激活" value="on"></el-option>
                        <el-option label="禁用" value="off"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div  style="height: 47px">
                <el-form-item label="渠道名称" >
                    <el-input v-model="queryForm.orgName" placeholder="渠道名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="渠道编号">
                    <el-input v-model="queryForm.orgNo" placeholder="渠道编号" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="商户名称">
                    <el-input v-model="queryForm.mchName" placeholder="商户名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="商户编号">
                    <el-input v-model="queryForm.mchNo" placeholder="商户编号" class="search-input"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div class="op-botton">
            <el-button type="primary" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick()">新增</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                    :data="records"
                    border
                    :stripe="true">
                <el-table-column
                        prop="bannerName"
                        label="BANNER名称"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="imgUrl"
                        show-overflow-tooltip
                        label="图片">
                    <template scope="scope">
                        <img :src="scope.row.imgUrl" width="40" height="40" class="head_pic"/>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="toUrl"
                        show-overflow-tooltip
                        label="跳转连接">
                </el-table-column>
                <el-table-column
                        prop="putStr"
                        show-overflow-tooltip
                        label="媒体位">
                </el-table-column>
                <el-table-column
                        prop="workName"
                        show-overflow-tooltip
                        label="渠道或商户名称">
                </el-table-column>
                <el-table-column
                        prop="weight"
                        show-overflow-tooltip
                        label="权重">
                </el-table-column>
                <el-table-column
                        prop="bannerStatus"
                        show-overflow-tooltip
                        label="状态">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.bannerStatus"
                                active-value="on"
                                inactive-value="off"
                                active-color="#4A7DFA"
                                inactive-color="#E4E7ED"
                                @change=changeSwitch($event,scope.row,scope.$index)>
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createUserName"
                        show-overflow-tooltip
                        label="创建人">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        show-overflow-tooltip
                        label="备注">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="操作"
                        align="right"
                        width="200"
                        fixed="right">
                    <template slot-scope="scope" >
                        <div class="controls">
                            <el-button
                                    type="primary"
                                    plain
                                    size="small"
                                    @click="editClick(scope.row)"
                                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
                            >编辑
                            </el-button
                            >
                            <el-button
                                    type="danger"
                                    plain
                                    size="small"
                                    @click="deleteClick(scope.row)"
                                    style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"
                            >删除
                            </el-button
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
        </div>
        <el-dialog title="新增" :visible.sync="dialogFormVisible">
            <el-form>
                <el-form-item label="媒体位" :label-width="formLabelWidth" required>
                    <el-select style="width: 100%" v-model="curDataForm.put" placeholder="请选择" @change="changePut(curDataForm.put)">
                        <el-option label="渠道首页" value="1"></el-option>
                        <el-option label="商户首页" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择商户" :label-width="formLabelWidth" required>
                    <el-select  style="width: 100%" v-model="curDataForm.workIds" filterable  multiple clearable placeholder="请选择">
                        <el-option
                                v-for="item in orgOrMchList"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Banner名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="curDataForm.bannerName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Banner权重" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="curDataForm.weight"
                            autocomplete="off"
                            placeholder="请输入数字，数字越大banner图展示越靠前"
                    ></el-input>
                </el-form-item>
                <el-form-item label="跳转连接" :label-width="formLabelWidth"  >
                    <el-input
                            v-model.trim="curDataForm.toUrl"
                            autocomplete="off"
                            placeholder="无需跳转可不填"
                    ></el-input>
                </el-form-item>
                <el-form-item label="上传banner图" :label-width="formLabelWidth" required>
                    <el-upload
                            class="avatar-uploader"
                            action="/charge/admin/file/upload"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            :before-upload="handlePreview">
                        <img v-if="imgUrl" :src="imgUrl" class="avatar" style="width: 100px;height: 100px">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" >
                    <el-input
                            v-model.trim="curDataForm.remark"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 编辑弹框内容-->
        <el-dialog title="编辑" :visible.sync="dialogEditFormVisible">
            <el-form>
                <el-form-item label="媒体位" :label-width="formLabelWidth" required>
                    <el-select style="width: 100%" v-model="editDataForm.put" placeholder="请选择" @change="changePut2(editDataForm.put)">
                        <el-option label="渠道首页" value="1"></el-option>
                        <el-option label="商户首页" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择商户" :label-width="formLabelWidth" required>
                    <el-select style="width: 100%" v-model="editDataForm.workId" clearable placeholder="请选择">
                        <el-option
                                v-for="item in orgOrMchList"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Banner名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="editDataForm.bannerName"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Banner权重" :label-width="formLabelWidth" required>
                    <el-input
                            placeholder="请输入数字，数字越大banner图展示越靠前"
                            v-model.trim="editDataForm.weight"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="跳转连接" :label-width="formLabelWidth"  >
                    <el-input
                            v-model.trim="editDataForm.toUrl"
                            autocomplete="off"
                            placeholder="无需跳转可不填"
                    ></el-input>
                </el-form-item>
                <el-form-item label="上传banner图" :label-width="formLabelWidth" required>
                    <el-upload
                            class="avatar-uploader"
                            action="/charge/admin/file/upload"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            :before-upload="handlePreview">
                        <img v-if="imgUrl" :src="imgUrl" class="avatar" style="width: 100px;height: 100px">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" >
                    <el-input
                            v-model.trim="editDataForm.remark"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 删除内容-->
        <el-dialog
                class="del"
                title="删除"
                :visible.sync="dialogDeleteVisible"
                width="30%"
                center
        >
            <div class="tip">
                <img
                        class="tip-icon"
                        src="../../../assets/images/wra-icon.png"
                        alt=""
                />
                <span class="txt">确定删除将无法恢复，请确认！</span>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
        </el-dialog>
    </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    export default {
        name: "mch", // 商户管理
        data() {
            return {
                limit: "2",
                page: "1",
                startTime:"",
                endTime:"",
                records: [],
                curDataForm: {
                },
                editDataForm: {

                },
                orgOrMchList:[],
                queryForm: {
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, // 删除弹框
                formLabelWidth: "120px",
                dialogEditFormVisible: false,
                imgUrl: "",
            };
        },
        created() {
            this.loadData();
        },
        computed: {},
        methods: {
            loadData(page) {
                this.$request
                    .bannerList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            createExport() {
                this.$request
                    .bashMchExport({
                        ...this.queryForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            window.open(res.data.url);
                        }
                    });
            },
            handleSuccess(res) {
                this.imgUrl = res.data.url;
            },
            handlePreview (file) {
                if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
                    this.$message.error('只支持jpg、png格式的图片！')
                    return false
                }
            },
            changePut(put) {
                this.$request
                    .deptGetOrgOrMchList({workType:put})
                    .then((res) => {
                        if (res.code == 0) {
                            this.orgOrMchList = res.data;
                            this.curDataForm.workIds=[];
                        }
                    });
            },
            changePut2(put) {
                this.$request
                    .deptGetOrgOrMchList({workType:put})
                    .then((res) => {
                        if (res.code == 0) {
                            this.orgOrMchList = res.data;
                            this.editDataForm.workId=null;
                        }
                    });
            },
            reduceQueryFormTime(days){
                var date = new Date();
                let formatDate1 = this.$util.formatDate(date);
                date.setDate(date.getDate()-days);
                let formatDate2 = this.$util.formatDate(date);
                this.$set(this.queryForm, "createTime", [formatDate2,formatDate1])
            },
            changeSwitch(event, data, index){
                this.$request
                    .bannerUpdate(data)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message:"成功",
                                type: "success",
                            });
                        }
                    });
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            createClick() {
                this.$util.clearObj(this.curDataForm);
                this.imgUrl=null;
                this.orgOrMchList=[];
                this.dialogFormVisible = true;
            },
            editClick(item) {
                this.$request.bannerInfo(item).then((res) => {
                    if (res.code == 0) {
                        this.$request
                            .deptGetOrgOrMchList({workType:res.data.put})
                            .then((res2) => {
                                if (res2.code == 0) {
                                    this.orgOrMchList = res2.data;
                                }
                            });
                        this.editDataForm = res.data;
                        this.imgUrl=res.data.imgUrl;
                        this.editDataForm.put = res.data.put+"";
                        this.dialogEditFormVisible = true;
                    }
                });
            },
            deleteClick(item) {
                this.deleteItem = item;
                this.dialogDeleteVisible = true;
            },
            deleteSure() {
                this.$request.bannerDelete(this.deleteItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.queryForm);
            },
            editSubmit(){
                if(!this.imgUrl){
                    this.$message({ message: "请上传图片", type: "error",});
                    return;
                }
                let curDataForm = {...this.curDataForm};
                this.editDataForm.imgUrl = this.imgUrl;
                this.$request
                    .bannerUpdate({
                        ...this.editDataForm,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogEditFormVisible = false;
                            this.loadData();
                            this.$util.clearObj(this.editDataForm);
                        }
                    });
            },
            submit() {
                if(!this.imgUrl){
                    this.$message({ message: "请上传图片", type: "error",});
                    return;
                }
                let curDataForm = {...this.curDataForm};
                curDataForm.imgUrl = this.imgUrl;
                if (!this.curDataForm.bannerId) {
                    let curDataForm = {...this.curDataForm};
                    curDataForm.imgUrl = this.imgUrl;
                    this.$request.bannerSave(curDataForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.$util.clearObj(this.curDataForm);
                            this.loadData();
                        }
                    });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .choose-days {
        width: 67px;
        height: 32px;
        margin-top: 3px;
        margin-left: 9px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 32px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
    .search-input  /deep/.el-input__inner {
        height: 32px!important;
        width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    .vue-treeselect {
        width: 200px;
        line-height: 32px;
    }
    .el-range-editor.el-input__inner {
        display: inline-flex;
        align-items: center;
        padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

    .op-botton{
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: right;
    }
    /deep/  .el-table th, .el-table tr {
        background-color: #F5F5F5;
        color: #909399;
    }
    /deep/ .el-table td {
        padding: 0px 0;
        height: 50px;
    }
    /deep/ .el-table th {
        padding: 0px 0;
        height: 50px;
    }
    .del .tip .tip-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
</style>
