import axios from 'axios';
import store from '@/store'
import {
    BaseURL
} from '@/utils/config.js'
import { Loading } from 'element-ui';

axios.defaults.timeout = 60000;
// axios.defaults.withCredentials = false 
// 请求拦截器
axios.interceptors.request.use(
    config => {
        if (config.url.indexOf(BaseURL.url) === 0) {
            config.headers = {
                ...config.headers,
                token: store.state.token || '',
            }
        }
        return config;
    },
    error => {
        return Promise.error(error);
    })
// 响应拦截器
axios.interceptors.response.use(
    response => {
        let data = response.data
        if (data.code == 9999) {
            store.commit('setToken', "");
            localStorage.clear();
            location.replace('#/login')
        }
        return Promise.resolve(data);
    },
    error => {
        return Promise.reject(error.response);
    });

let loadingInstance = null;
let LoadingCount = 0

function http(res) {
    let vm = store._vm
    let showLoading = res.showLoading === undefined ? false : res.showLoading
    let showSuccess = res.showSuccess === undefined ? false : res.showSuccess
    let showFail = res.showFail === undefined ? true : res.showFail
    let isAll = res.isAll === undefined ? false : res.isAll
    if (showLoading && !LoadingCount++) {
        if(!isAll){
            loadingInstance = Loading.service({
                target: document.querySelector('.el-main')
            })
        }else {
            loadingInstance = Loading.service({
                target: document.querySelector('body')
            })  
        }
    }
    return new Promise((resolve, reject) => {
        axios(res).then(res => {
            if (showLoading && --LoadingCount == 0) loadingInstance.close();
            if (res.code == 0) {
                if (showSuccess) vm.$message({
                    message: res.msg || '网络请求成功',
                    type: 'success'
                });
            } else {
                if (res.code != 9999) {
                    if (showFail) vm.$message.error(res.msg || '网络错误');
                }
            }
            return resolve(res);
        }).catch((error) => {
            console.log(error)
            if (showLoading && --LoadingCount == 0) loadingInstance.close();
            vm.$message.error('用户数据请求出错');
            return reject(error);
        })
    })
}

export default http