<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { Config } from "@/utils/config.js";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState({
      state: (state) => state,
    }),
  },
  watch: {
    state: {
      deep: true,
      handler(state) {
        // console.log('localStorage updated');
        let data = {};
        state.saveKeys.map((key) => {
          data[key] = state[key];
        });
        localStorage[Config.cachekey] = JSON.stringify(data);
      },
    },
    $route(e, e2) {
      // console.log('$route', e, e2);
      if (e.path == "/login" || e.path == "/reg"|| e.path == "/about") {
         this.$request.publicKey();
      } else {
        this.$request.rbacuserDetail();
        this.$request.expireAlert({}).then((res) => {
          if (res.code == 0 && res.data && res.data != '') {

             this.$alert('您的账号将于'+res.data+'过期，请及时续费', '过期提醒', {
              confirmButtonText: '确定'
            });
          }
        });
      }
    },
  },
  created() {
    this.loadData();
    this.$router.onReady(() => {
     
      let path = this.$route.path;
      if (path == "/login" || path == "/reg" || path == "/about") {
        this.$request.publicKey();
      } else {
        this.$request.rbacuserDetail();
        this.$request.expireAlert({}).then((res) => {
          if (res.code == 0 && res.data && res.data != '') {

             this.$alert('您的账号将于'+res.data+'过期，请及时续费', '过期提醒', {
              confirmButtonText: '确定'
            });
          }
        });
      }
    });
  },
  methods: {
    ...mapMutations({
      setState: "setState",
    }),
    async loadData() {
      try {
        const data = JSON.parse(localStorage[Config.cachekey]);
        if (data) this.setState(data);
      } catch (e) {
        console.log("初始化数据读取错误");
      }
    },
  },
};
</script>

<style lang="less">
</style>
