<template>
    <el-card style="width: 100%;min-width: 1200px">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
            <div style="height: 47px;">
                <el-form-item label="创建时间" >
                    <el-date-picker
                            v-model="form.createTime"
                            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%;height: 32px"
                    >
                    </el-date-picker>
                </el-form-item>
                <div class="choose-days" :class="{'choose-days-selected':choose7}" @click="reduceQueryFormTime(7)">近7天</div>
                <div class="choose-days" :class="{'choose-days-selected':choose30}" @click="reduceQueryFormTime(30)">近1月</div>
                <div class="choose-days" :class="{'choose-days-selected':choose180}" @click="reduceQueryFormTime(180)">近半年</div>
            </div>
            <div  style="height: 47px">
                <el-form-item label="项目名称" >
                    <el-input v-model="form.name" placeholder="项目名称" class="search-input"></el-input>
                </el-form-item>
                <el-form-item label="创建人">
                    <el-input v-model="form.createUserName" placeholder="创建人" class="search-input"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div class="search-button">
            <div style="margin: 0 auto;height: 48px;width: 200px">
                <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
                >查询
                </el-button
                >
                <el-button size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div class="op-botton">
            <el-button type="primary" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick()">新增</el-button>
        </div>
        <div style="width: 100%;margin-top: 0px;">
            <el-table
                    :data="records"
                    border
                    :stripe="true">
                <el-table-column
                        prop="itemId"
                        label="项目id"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="项目名称"
                        show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="itemWith"
                        label="收费依据"
                        show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="cursor: pointer;color: #4A7DFA" @click="downLoadItemWith(scope.row.itemWithUrl)">{{scope.row.itemWith}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="itemStatus"
                        show-overflow-tooltip
                        label="状态">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.itemStatus"
                                active-value="on"
                                inactive-value="off"
                                active-color="#4A7DFA"
                                inactive-color="#E4E7ED"
                                @change=changeSwitch($event,scope.row,scope.$index)>
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createUserName"
                        show-overflow-tooltip
                        label="创建人">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="操作"
                        align="right"
                        width="330"
                        fixed="right">
                    <template slot-scope="scope" >
                        <div class="controls">
                            <el-button
                                    type="primary"
                                    plain
                                    size="small"
                                    @click="editClick(scope.row)"
                                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
                            >编辑
                            </el-button
                            >
                            <el-button
                                    type="danger"
                                    plain
                                    size="small"
                                    @click="deleteClick(scope.row)"
                                    style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"
                            >删除
                            </el-button
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block" style="margin-top: 15px;text-align: right">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.currentPage"
                    :page-sizes="queryInfo.pageSizes"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryInfo.total"
            >
            </el-pagination>
        </div>
        <!-- 编辑弹框内容-->
        <el-dialog title="新增/编辑" :visible.sync="dialogFormVisible">
            <el-form>
                <el-form-item label="项目名称" :label-width="formLabelWidth" required>
                    <el-input
                            v-model.trim="curDataForm.name"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="收费依据" :label-width="formLabelWidth">
                    <el-upload
                            class="avatar-uploader"
                            action="/charge/admin/file/upload"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            :before-upload="handlePreview">
                        <span v-if="uploadFlag" class="avatar">{{curDataForm.itemWith}}</span>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 删除内容-->
        <el-dialog
                class="del"
                title="删除账号"
                :visible.sync="dialogDeleteVisible"
                width="30%"
                center
        >
            <div class="tip">
                <img
                        class="tip-icon"
                        src="../../../assets/images/wra-icon.png"
                        alt=""
                />
                <span class="txt">确定删除将无法恢复，请确认！</span>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
        </el-dialog>
    </el-card>
</template>

<script>
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    export default {
        name: "account", // 账户管理
        data() {
            return {
                limit: "2",
                page: "1",
                startTime:"",
                endTime:"",
                records: [],
                choose7: false,
                choose30: false,
                choose180: false,
                curDataForm: {
                },
                form: {
                    name: "", // 项目名称
                    createUserName: "", // 创建人
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                dialogCode: false, // 重置密码弹框
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, // 删除弹框
                formLabelWidth: "120px",
                uploadFlag:false,

            };
        },
        created() {
            this.loadData();
        },
        computed: {},
        methods: {
            loadData(page) {
                this.$request
                    .mchbillitemList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.form,
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            downLoadItemWith(url){
                window.open(url);
            },
            handleSuccess(res) {
                this.uploadFlag=true;
                this.curDataForm.itemWith = res.data.name;
                this.curDataForm.itemWithUrl = res.data.url;
            },
            handlePreview (file) {
                /*if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
                 this.$message.error('只支持jpg、png格式的图片！')
                 return false
                 }*/
            },
            reduceQueryFormTime(days){
                if(days == 7){
                    this.choose7 = true;
                    this.choose30 = false;
                    this.choose180 = false;
                }else if (days == 30){
                    this.choose7 = false;
                    this.choose30 = true;
                    this.choose180 = false;
                }else if (days == 180){
                    this.choose7 = false;
                    this.choose30 = false;
                    this.choose180 = true;
                }
                var date = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
                let formatDate1 = this.$util.formatDate(date);
                date.setDate(date.getDate() - (days+1));
                date = new Date(new Date(date.toLocaleDateString()).getTime());
                let formatDate2 = this.$util.formatDate(date);
                this.$set(this.form, "createTime", [formatDate2, formatDate1]);
            },
            changeSwitch(event, data, index){
                var reqData = {};
                reqData.itemId=data.itemId;
                reqData.itemStatus=data.itemStatus;
                this.$request
                    .mchbillitemUpdate(reqData)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message:"成功",
                                type: "success",
                            });
                        }
                    });
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            createClick() {
                this.$util.clearObj(this.curDataForm);
                this.uploadFlag=false;
                this.dialogFormVisible = true;
            },
            editClick(item) {
                this.curDataForm = this.$util.cloneObj(item);
                if(this.curDataForm.itemWith != ''){
                    this.uploadFlag=true;
                }else{
                    this.uploadFlag=false;
                }
                this.dialogFormVisible = true;
            },
            deleteClick(item) {
                this.deleteItem = item;
                this.dialogDeleteVisible = true;
            },
            deleteSure() {
                this.$request.mchbillitemDelete(this.deleteItem).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.form);
            },
            submit() {
                if (this.curDataForm.itemId) {
                    let curDataForm = {...this.curDataForm};
                    this.$request.mchbillitemUpdate(curDataForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.$util.clearObj(this.curDataForm);
                            this.loadData();
                        }
                    });
                } else {
                    this.$request
                        .mchbillitemSave({
                            ...this.curDataForm,
                        })
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message({
                                    message: "成功",
                                    type: "success",
                                });
                                this.dialogFormVisible = false;
                                this.loadData();
                                this.$util.clearObj(this.curDataForm);
                            }
                        });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .choose-days {
        width: 67px;
        height: 32px;
        margin-top: 3px;
        margin-left: 9px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 32px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .choose-days-selected {
        background: #4065E0;
        color: #FFFFFF;
    }
    .search-form {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #909399;
        line-height: 14px;
    }
    .search-input  /deep/.el-input__inner {
        height: 32px!important;
        width: 200px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    .vue-treeselect {
        width: 200px;
        line-height: 32px;
    }
    .el-range-editor.el-input__inner {
        display: inline-flex;
        align-items: center;
        padding: 0px 10px;
    }
    .search-button {
        height: 52px;
        text-align: center;
        line-height: 48px;
        border-bottom: 1px solid #DCDFE6;
    }

    .op-botton{
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: right;
    }
    /deep/  .el-table th, .el-table tr {
        background-color: #F5F5F5;
        color: #909399;
    }
    /deep/ .el-table td {
        padding: 0px 0;
        height: 50px;
    }
    /deep/ .el-table th {
        padding: 0px 0;
        height: 50px;
    }
    .del .tip .tip-icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
</style>
