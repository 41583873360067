<template>
  <el-card style="width: 100%;min-width: 1200px">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline search-form"  label-position="right" label-width="80px" >
      <div  style="height: 47px">
        <el-form-item label="角色名称" class="search-form-item">
          <el-input v-model="form.roleName" placeholder="角色名称" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input v-model="form.createUserName" placeholder="创建人" class="search-input"></el-input>
        </el-form-item>
        <el-form-item label="所属商户">
          <el-select
                  size="small"
                  class="title-input"
                  v-model="form.mchId"
                  filterable
                  clearable
                  remote
                  reserve-keyword
                  placeholder="请输入商户名称或编号"
                  :remote-method="queryMchSelect"
          >
            <el-option
                    v-for="item in mchIdSelctList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="search-button">
      <div style="margin: 0 auto;height: 48px;width: 200px">
        <el-button type="primary" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;" size="small" @click="loadData(1)"
        >查询
        </el-button
        >
        <el-button size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
    <div class="op-botton">
      <el-button type="primary" size="small" style="background-color: #4A7DFA;border-color: #4A7DFA;width: 60px;height: 32px;"  @click="createClick">新增</el-button>
    </div>
    <div style="width: 100%;margin-top: 0px;">
      <el-table
              :data="records"
              border
              :stripe="true">
        <el-table-column  type="selection" fixed="left" width="55">
        </el-table-column>
        <el-table-column
                prop="roleName"
                label="角色名称"
                show-overflow-tooltip>
        </el-table-column>
        <el-table-column
                prop="remark"
                show-overflow-tooltip
                label="备注">
        </el-table-column>
        <el-table-column
                prop="createUserName"
                show-overflow-tooltip
                label="创建人">
        </el-table-column>
        <el-table-column
                prop="createTime"
                show-overflow-tooltip
                label="创建时间">
        </el-table-column>
        <el-table-column
                prop="address"
                label="操作"
                align="right"
                width="330"
                fixed="right">
          <template slot-scope="scope">
            <el-button
                    type="primary"
                    plain
                    size="small"
                    @click="editClick(scope.row)"
                    style="background: #ECF5FF; border: 1px solid #B3D8FF; color:#409EFF;"
            >编辑</el-button
            >
            <el-button
                    type="danger"
                    plain
                    size="small"
                    @click="deleteClick(scope.row)"
                    style="background: #FEF0F0; border: 1px solid #FBC4C4; color:#F56C6C;"
            >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block" style="margin-top: 15px;text-align: right">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.currentPage"
              :page-sizes="queryInfo.pageSizes"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="queryInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框内容-->
    <el-dialog title="新增/编辑角色" :visible.sync="dialogFormVisible">
      <el-form :model="currentRole">
        <el-form-item label="角色名称" :label-width="formLabelWidth" required>
          <el-input
                  v-model.trim="currentRole.roleName"
                  autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色权限" :label-width="formLabelWidth" required>
          <el-tree
                  :data="elTreeData"
                  show-checkbox
                  :check-strictly="true"
                  :default-checked-keys="currentRole.menuIds"
                  node-key="id"
                  :props="defaultProps"
                  ref="tree"
                  @check-change="handleCheckChange"
          >
          </el-tree>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="currentRole.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除内容-->
    <el-dialog class="del"
               title="提示"
               :visible.sync="dialogDeleteVisible"
               width="30%"
    >
      <div class="tip">
        <img class="tip-icon" src="../../../assets/images/wra-icon.png" alt="">
        <span class="txt">确定删除将无法恢复，请确认！</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from "vuex";
    import ElCard from "../../../../node_modules/element-ui/packages/card/src/main";
    export default {
        name: "role", // 角色管理
        data() {
            return {
                limit: "10",
                page: "1",
                records: [],
                elTreeData: [],
                elTreeData2: [],
                menuIds: [],
                mchIdSelctList: [],
                currentRole: {
                    menuIds: [],
                    roleName: "",
                    remark: "",
                    roleId: "",
                },
                deleteRole: {},
                form: {
                    roleName: "", // 角色名称
                    createUserName: "", // 创建者
                    mchNo: "", // 商户编号
                    mchName: "", // 商户名称
                },
                // 分页器
                queryInfo: {
                    total: 0, // 总数
                    pageSize: 10, // 每页显示的条数
                    pageSizes: [10, 20, 50, 100], // 每页显示条数的选择设置
                    currentPage: 1, // 当前页数
                },
                dialogFormVisible: false, // 编辑弹框
                dialogDeleteVisible: false, // 删除弹框
                searchMchVisible: false, // 删除弹框
                formLabelWidth: "120px",
                count: 1,
                defaultProps: {
                    children: "children",
                    label: "label",
                },
            };
        },
        computed: {
            ...mapState({
                menus: (state) => state.menus,
            }),
        },
        created() {
            let userType = localStorage.getItem('curUserType');
            if(userType != null){
                if(userType == 'MCH'){
                    this.searchMchVisible = false;
                }else {
                    this.searchMchVisible = true;
                }
            }
            this.loadData();
            this.loadTree();
        },
        methods: {
            loadData(page) {
                this.$request
                    .rbacroleList({
                        limit: this.queryInfo.pageSize,
                        page: page || this.queryInfo.currentPage,
                        ...this.form
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.records = res.data.records;
                            this.queryInfo.total = res.data.total;
                            this.queryInfo.currentPage = res.data.current;
                        }
                    });
            },
            queryMchSelect(queryName){
                if (queryName !== '') {
                    this.$request.deptGetOrgOrMchList({
                        workType:"2",
                        queryName:queryName
                    }).then((res) => {
                        if (res.code == 0) {
                            this.mchIdSelctList = res.data;
                        }
                    });
                }
            },
            handleSizeChange(val) {
                //   console.log(`每页 ${val} 条`);
                this.queryInfo.pageSize = val;
                this.loadData();
            },
            handleCurrentChange(val) {
                //   console.log(`当前页: ${val}`);
                this.loadData(val);
            },
            // 树形控件相关方法
            handleCheckChange(a, b, c) {
                console.log(1)
                this.findchildren(a,b);
            },
            findchildren(current,b){
                if(this.menuIds.length == 0){
                    this.$refs.tree.setChecked(current.id,b,b);
                    let d=current.children;
                    if(d && d.length>0){
                        d.forEach(a =>{
                            this.findchildren(a, b);
                        })
                    }
                }else{
                  /* if(this.menuIds.indexOf(current.id) > -1){
                   this.$refs.tree.setChecked(current.id,b,b);
                   }
                   let d=current.children;
                   if(d && d.length>0){
                   d.forEach(a =>{
                   this.findchildren(a, b);
                   })
                   }*/
                    //this.menuIds=[];
                }

            },
            handleNodeClick(data) {
            },
            deleteClick(item) {
                this.deleteRole = item;
                this.dialogDeleteVisible = true;
            },
            deleteSure() {
                this.$request.rbacroleDelete(this.deleteRole.roleId).then((res) => {
                    if (res.code == 0) {
                        this.dialogDeleteVisible = false;
                        this.$message({
                            message: "成功",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            },
            loadTree(){
                this.$request.rbacroleFindCheckMenuTree({}).then((res) => {
                    if (res.code == 0) this.elTreeData2 = res.data;
                });
            },
            createClick() {
                this.menuIds = [];
                this.$util.clearObj(this.currentRole);
                this.elTreeData =  this.$util.cloneObj(this.elTreeData2);
                this.dialogFormVisible = true;
            },
            editClick(item) {
                this.menuIds = [];
                this.$util.clearObj(this.currentRole);
                this.elTreeData =  this.$util.cloneObj(this.elTreeData2);
                this.$request.rbacroleInfo(item).then((res) => {
                    if (res.code == 0) {
                        this.currentRole = res.data;
                        this.menuIds = res.data.menuIds;
                        this.dialogFormVisible = true;
                        setTimeout(function(){ this.menuIds =  [] }, 500);
                    } else {
                        this.$message.error("查询用户信息出错,userId=" + item.userId);
                    }
                });
            },
            resetClick() {
                this.$util.clearObj(this.form);
            },
            submit() {
                if (this.currentRole.roleId) {
                    let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
                    this.currentRole.menuIds = res;
                    this.$request.rbacroleUpdate(this.currentRole).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.loadData();
                        } else if (res.code == 400) {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
                    this.currentRole.menuIds = res;
                    this.$request.rbacroleSave(this.currentRole).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: "成功",
                                type: "success",
                            });
                            this.dialogFormVisible = false;
                            this.loadData();
                        }
                    });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
  .choose-days {
    width: 67px;
    height: 32px;
    margin-top: 3px;
    margin-left: 9px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  .choose-days-selected {
    background: #4065E0;
    color: #FFFFFF;
  }
  .search-form {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 14px;
  }
  .search-input  /deep/.el-input__inner {
    height: 32px!important;
    width: 200px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
  }
  .vue-treeselect {
    width: 200px;
    line-height: 32px;
  }
  .el-range-editor.el-input__inner {
    display: inline-flex;
    align-items: center;
    padding: 0px 10px;
  }
  .search-button {
    height: 52px;
    text-align: center;
    line-height: 48px;
    border-bottom: 1px solid #DCDFE6;
  }

  .op-botton{
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: right;
  }
  /deep/  .el-table th, .el-table tr {
    background-color: #F5F5F5;
    color: #909399;
  }
  /deep/ .el-table td {
    padding: 0px 0;
    height: 50px;
  }
  /deep/ .el-table th {
    padding: 0px 0;
    height: 50px;
  }
  .del .tip .tip-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
</style>
